import React from 'react';

import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import getRouteFor, { Route } from '../../lib/utils/router';
import LoginPage from '../LoginPage';
import { connect } from 'react-redux';
import { State } from '../../lib/reducers';
import { FirstTimePopoverDialog } from '../PopoverDialog';


const styles = (theme: Theme) =>
  createStyles({
    dialogActions: {
      paddingTop: theme.spacing(3),
    },
    joinButton: {
      width: '100%',
    },
    input: {
      paddingTop: theme.spacing(3),
    },
    loginMessage: {
      textAlign: 'center',
      paddingTop: theme.spacing(3),
    },
    codeInputField: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
  });


const messages = defineMessages({
  joinUsingInvite: { id: 'joinUsingInvite' },
  insertMeetingCodeHere: { id: 'insertMeetingCodeHere' },
  joinButton: { id: 'joinButton' },
  loginHaveAnAccount: { id: 'loginHaveAnAccount' },
  loginFirstTimeMessage: { id: 'loginFirstTimeMessage' }
});

type MappedProps = {
  loginEnabled: boolean;
}

const mapStateToProps = (state: State): MappedProps => ({
  loginEnabled: state.appconfig.login_enabled,
});

type Props = {} & MappedProps;

type LocalState = {
  meetingId: string | null;
}


type ExtendedProps = Props & WithStyles<typeof styles> & {intl: IntlShape} & RouteComponentProps;


class EnterMeeting extends React.Component<ExtendedProps, LocalState> {

  constructor(props: ExtendedProps) {
    super(props);
    this.state = {
      meetingId: null
    };
  }

  gotoLogin = () => {
    this.props.history.push(getRouteFor(Route.Login));
  }

  gotoMeeting = () => {
    if (this.state.meetingId) {
      const path = getRouteFor(Route.Meeting, { slug: this.state.meetingId });
      this.props.history.push(path);
    }
  }

  handleMeetingIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      meetingId: ev.target.value,
    });
  }

  handleMeetingIdKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      this.gotoMeeting();
    }
  }

  render() {
    const { classes, intl, loginEnabled } = this.props;

    return (
      <LoginPage>
        {intl.formatMessage(messages.joinUsingInvite)}


        <TextField
          inputProps={{ autoCapitalize: 'off', className: classes.codeInputField }}
          autoFocus={true}
          fullWidth
          className={classes.input}
          placeholder={intl.formatMessage(messages.insertMeetingCodeHere)}
          onChange={this.handleMeetingIdChange}
          onKeyUp={this.handleMeetingIdKeyUp}
        />

        <div className={classes.dialogActions}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.joinButton}
            onClick={this.gotoMeeting}
            disabled={!this.state.meetingId}
          >
            {intl.formatMessage(messages.joinButton)}
          </Button>

        </div>

        {loginEnabled &&
          <div className={classes.loginMessage}>
            <FirstTimePopoverDialog
              configKey="EnterMeeting"
              message={intl.formatMessage(messages.loginFirstTimeMessage)}
            >
              <Link
                component="button"
                variant="body2"
                onClick={this.gotoLogin}
              >
                {intl.formatMessage(messages.loginHaveAnAccount)}
              </Link>
            </FirstTimePopoverDialog>

          </div>
        }
      </LoginPage>
    );
  }
}


export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps)(EnterMeeting))));
