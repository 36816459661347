import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Grid, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import WaitingRoomContent from './WaitingRoom/WaitingRoomContent';
import AVSettingsAndPreview from './WaitingRoom/AVSettingsAndPreview';


const messages = defineMessages({
  waitingRoomPrivacyBody: { id: 'waitingRoomPrivacyBody' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.background.paper,
      alignItems: 'center'
    },
    grid: {
      height: '100%',
      alignItems: 'center'
    },
    content: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 16, 0, 0)
      },
      [theme.breakpoints.only('sm')]: {
        padding: theme.spacing(0, 4, 0, 0)
      },
    },
    privacy: {
      padding: theme.spacing(0.5),
      color: theme.palette.common.black,
      opacity: 0.6,
      fontSize: 10,
      fontStyle: 'italic'
    }
  })
);


function WaitingRoom(_props: Props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const canvasRef = React.useRef<HTMLCanvasElement | null>();
  const videoRef = React.useRef<HTMLVideoElement | null>();

  const takeBase64Snapshot = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const video = videoRef.current;
      const context = canvas.getContext('2d');
      if (video && context) {
        const ratio = video.videoWidth / video.videoHeight;
        const width = 180 * ratio;
        canvas.width = 320;
        canvas.height = 180;
        context.drawImage(video, (320 - width) / 2, 0, width, 180);
        const snapshot = canvas.toDataURL('image/jpeg', 0.75);
        return snapshot;
      }
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Grid container direction='row' justify='center' className={classes.grid}>
        <Grid item xs={12} sm={7} md={8}>
          <AVSettingsAndPreview videoRef={videoRef} canvasRef={canvasRef} />
        </Grid>
        <Grid item xs={12} sm={5} md={4} className={classes.content}>
          <WaitingRoomContent takeSnapshot={takeBase64Snapshot} />
        </Grid>
      </Grid>
      <Typography align='center' className={classes.privacy}>
        {formatMessage(messages.waitingRoomPrivacyBody)}
      </Typography>
    </div>
  );
}

type Props = {}

export default WaitingRoom;
