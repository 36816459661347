export const TOGGLE_DEVICE_SETTINGS = 'SETTINGS::TOGGLE_DEVICES';
export const VIDEO_DEVICE_SETTINGS = 'SETTINGS::VIDEO_DEVICE';
export const VIDEO_QUALITY_SETTINGS = 'SETTINGS::VIDEO_QUALITY';
export const AUDIOIN_DEVICE_SETTINGS = 'SETTINGS::AUDIOIN_DEVICE';
export const AUDIOOUT_DEVICE_SETTINGS = 'SETTINGS::AUDIOOUT_DEVICE';
export const VIDEO_ENABLED = 'VIDEO_ENABLED';
export const AUDIO_JOIN_MUTED = 'AUDIO_JOIN_MUTED';
export const SET_IS_ELECTRON = 'SET_IS_ELECTRON';

function mediaConfigUpdate(config, which) {
  return {
    type: which,
    payload: {
      config: config,
    }
  };
}

function _saveVideoConfig(dispatch, config, localStorage) {
  _persistVideoConfig(config, localStorage);
  dispatch(mediaConfigUpdate(config, VIDEO_DEVICE_SETTINGS));
}

function _saveVideoEnabled(dispatch, enabled, localStorage) {
  _persistVideoEnabled(enabled, localStorage);
  dispatch(mediaConfigUpdate(enabled, VIDEO_ENABLED));
}

function _saveAudioJoinMuted(dispatch, enabled, localStorage) {
  _persistAudioJoinMuted(enabled, localStorage);
  dispatch(mediaConfigUpdate(enabled, AUDIO_JOIN_MUTED));
}

function _saveVideoQuality(dispatch, config, localStorage) {
  _persistVideoQualityConfig(config, localStorage);
  dispatch(mediaConfigUpdate(config, VIDEO_QUALITY_SETTINGS));
}

function _saveAudioInConfig(dispatch, config, localStorage) {
  _persistAudioInConfig(config, localStorage);
  dispatch(mediaConfigUpdate(config, AUDIOIN_DEVICE_SETTINGS));
}

function _saveAudioOutConfig(dispatch, config, localStorage) {
  _persistAudioOutConfig(config, localStorage);
  dispatch(mediaConfigUpdate(config, AUDIOOUT_DEVICE_SETTINGS));
}

function _persistVideoConfig(config, storage) {
  const oldconfig = storage.loadMediaConfig();
  const newconfig = Object.assign(oldconfig, { videoDevice: config });
  storage.saveMediaConfig(newconfig);
}

function _persistVideoEnabled(enabled, storage) {
  const oldconfig = storage.loadMediaConfig();
  const newconfig = Object.assign(oldconfig, { videoEnabled: enabled });
  storage.saveMediaConfig(newconfig);
}

function _persistAudioJoinMuted(enabled, storage) {
  const oldconfig = storage.loadMediaConfig();
  const newconfig = Object.assign(oldconfig, { audioJoinMuted: enabled });
  storage.saveMediaConfig(newconfig);
}

function _persistAudioInConfig(config, storage) {
  const oldconfig = storage.loadMediaConfig();
  const newconfig = Object.assign(oldconfig, { audioInDevice: config });
  storage.saveMediaConfig(newconfig);
}

function _persistAudioOutConfig(config, storage) {
  const oldconfig = storage.loadMediaConfig();
  const newconfig = Object.assign(oldconfig, { audioOutDevice: config });
  storage.saveMediaConfig(newconfig);
}

function _persistVideoQualityConfig(config, storage) {
  const oldconfig = storage.loadMediaConfig();
  const newconfig = Object.assign(oldconfig, { videoQuality: config });
  storage.saveMediaConfig(newconfig);
}

export function toggleDeviceSettings() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_DEVICE_SETTINGS,
      payload: {}
    });
  };
}

export function saveVideoEnabled(enabled, configStorage) {
  return (dispatch) => {
    return _saveVideoEnabled(dispatch, enabled, configStorage);
  };
}

export function saveAudioJoinMuted(enabled, configStorage) {
  return (dispatch) => {
    return _saveAudioJoinMuted(dispatch, enabled, configStorage);
  };
}

export function saveVideoConfig(config, configStorage) {
  return (dispatch) => {
    return _saveVideoConfig(dispatch, config, configStorage);
  };
}

export function saveVideoQuality(config, configStorage) {
  return (dispatch) => {
    return _saveVideoQuality(dispatch, config, configStorage);
  };
}

export function saveAudioInConfig(config, configStorage) {
  return (dispatch) => {
    return _saveAudioInConfig(dispatch, config, configStorage);
  };
}

export function saveAudioOutConfig(config, configStorage) {
  return (dispatch) => {
    return _saveAudioOutConfig(dispatch, config, configStorage);
  };
}

export function setIsElectron(isElectron) {
  return {
    type: SET_IS_ELECTRON,
    payload: {
      isElectron: isElectron,
    }
  };
}
