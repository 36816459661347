import { fetchQuery, IEnvironment } from 'relay-runtime';
import { graphql } from 'babel-plugin-relay/macro';
import {
  searchContactQueryResponse,
  SearchFilter,
  searchContactQueryVariables
} from './__generated__/searchContactQuery.graphql';

// export type SessionDetails = sessionDetailsQuery['response']['loggedUser']['contacts'];

const searchContactQuery = graphql`
query searchContactQuery(
  $first: Int
  $filters: [SearchFilter]
) {
  loggedUser {
    contacts(first: $first, filters: $filters) {
	    edges {
        node {
            email,
          }
        }
      }
    }
  }
`;


type searchContacResponse = searchContactQueryResponse | null

async function searchContacts(environment: IEnvironment, filter: string): Promise<searchContacResponse> {
  const filters: SearchFilter = {
    key: 'email',
    match: 'CONTAINS',
    value: filter
  };
  const variables: searchContactQueryVariables = {
    first: 5,
    filters: [filters]
  };
  try {
    const data = await fetchQuery(environment, searchContactQuery, variables);
    return data as searchContacResponse;
  }
  catch (_e) {
    return null;
  }
}


function getContacts(response: searchContacResponse): string[] | null {
  if (response && response.loggedUser.contacts && response.loggedUser.contacts.edges) {
    const emails = response.loggedUser.contacts.edges
      .map((node) => (node && node.node && node.node.email) ? node.node.email : null)
      .filter((u): u is string => !!u);
    return emails;
  }
  return null;
}


export { searchContacts, getContacts };
