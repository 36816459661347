import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { IconMaximize, IconMinimize } from '../../IconSet';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const messages = defineMessages({
  enterFullScreen: { id: 'enterFullScreenButton' },
  exitFullScreen: { id: 'exitFullScreenMobile' },
});


function MobileFullScreen(_props: {}) {
  const { formatMessage } = useIntl();
  const doc = window.document;
  const docEl = doc.documentElement;

  /*
   * Tested and working on Chrome Mobile, Firefox Mobile and Edge Mobile
   */
  const toggleFullScreen = () => {
    const requestFullScreen = docEl.requestFullscreen;
    const cancelFullScreen = doc.exitFullscreen;

    if (!doc.fullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  };

  const getIcon = () => {
    const size = 32;
    return doc.fullscreenElement 
      ? <IconMinimize size={size} /> 
      : <IconMaximize size={size} />;
  };

  const itemText = doc.fullscreenElement
    ? messages.exitFullScreen
    : messages.enterFullScreen;

  return (
    <React.Fragment>
      <MenuItem onClick={toggleFullScreen}>
        <ListItemIcon>
          {getIcon()}
        </ListItemIcon>
        <ListItemText primary={formatMessage(itemText)} />
      </MenuItem>
    </React.Fragment>
  );
}


export default MobileFullScreen;
