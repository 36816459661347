import React from 'react';

import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { addObservedScreen, removeObservedScreen } from '../../../lib/actions/room';

import { State } from '../../../lib/reducers';
import { isLessonLayout, amModerator } from '../../../lib/reduxSelectors/room';
import { hasScreen, hasStream } from '../../../lib/reduxSelectors/presentationLayout';

import { IconUser, IconShareScreen } from '../../IconSet';

import useStyles from '../../../style/ControlButton';


const messages = defineMessages({
  streamSwitchShowStreamTooltip: { id: 'streamSwitchShowStreamTooltip' },
  streamSwitchShowScreenTooltip: { id: 'streamSwitchShowScreenTooltip' },
});


function StreamSwitch(props: ExtendedProps) {
  const {
    isLessonLayout,
    uid,
    amIModerator,
    haveScreen,
    haveStream,
    // iconColor = colors.normal,
    iconSize = 20,
    mini,
  } = props;

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const classes = useStyles();
  const cls = clsx(classes.controlButton, mini ? classes.controlButtonMini : null);

  const isScreen = uid.endsWith('_screen');

  const onClick = React.useCallback(
    () => {
      if (isScreen) {
        dispatch(removeObservedScreen(uid.replace(/_screen$/, '')));
      }
      else {
        dispatch(addObservedScreen(uid));
      }
    }, [isScreen, uid, dispatch]
  );

  let icon = null;
  let tooltip = '';
  if (isLessonLayout && amIModerator) {
    if (isScreen && haveStream) {
      icon = <IconUser size={iconSize} />;
      tooltip = formatMessage(messages.streamSwitchShowStreamTooltip);
    }
    else if (!isScreen && haveScreen) {
      icon = <IconShareScreen size={iconSize} />;
      tooltip = formatMessage(messages.streamSwitchShowScreenTooltip);
    }
  }
  if (icon) {
    return (
      <Tooltip placement="top" title={tooltip} >
        <div>
          <IconButton
            className={cls}
            size='small'
            onClick={onClick}
          >
            {icon}
          </IconButton>
        </div>
      </Tooltip>
    );
  }
  else {
    return null;
  }
}


type Props = {
  uid: string;
  //iconColor?: string;
  iconSize?: number;
  //cName?: string;
  mini?: boolean;
}


type MappedProps = {
  isLessonLayout: boolean;
  amIModerator: boolean;
  haveScreen: boolean;
  haveStream: boolean;
}


type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, { uid }: Props): MappedProps => {
  const userId = uid.replace(/_screen$/, '');
  return {
    isLessonLayout: isLessonLayout(state),
    amIModerator: amModerator(state),
    haveScreen: hasScreen(state, userId),
    haveStream: hasStream(state, userId),
  };
};


export default connect(mapStateToProps)(StreamSwitch);
