import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { mapStateToProps, MappedProps } from '../Meeting/VideoToolbar/mapState';
import { IconModerator } from '../IconSet';
import { controlButtonSize } from '../../style/ControlButton';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';


const messages = defineMessages({
  presentationIconTooltip: { id: 'presentationIconTooltip' },
});

const useStyles = makeStyles(
  (theme: Theme) => createStyles(
    {
      moderator: {
        marginLeft: theme.spacing(1),
        width: controlButtonSize,
        height: controlButtonSize,
        cursor: 'default',
        "&:hover": {
          backgroundColor: 'transparent'
        }
      },
      divider: {
        height: '50%',
        margin: theme.spacing(1),
      }
    }
  )
);


function UserInfo(props: ExtendedProps) {
  const { isModerator } = props;

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const getModeratorActions = () => {
    const msg = formatMessage(messages.presentationIconTooltip);
    return (
      <React.Fragment>
        <Tooltip placement="top" title={msg}>
          <IconButton className={classes.moderator} size='small' disableFocusRipple={true} disableRipple={true}>
            <IconModerator size={24} />
          </IconButton>
        </Tooltip>
        <Divider orientation='vertical' className={classes.divider} />
      </React.Fragment >
    );
  };

  return (
    <React.Fragment>
      {isModerator ? getModeratorActions() : null}
    </React.Fragment>
  );
}

type Props = {
  uid: string;
}

type ExtendedProps = Props & MappedProps

export default connect(mapStateToProps)(UserInfo);
