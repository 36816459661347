import { ExtendedMeeting } from '../../components/RoomInformation';
import { State } from '../reducers';

export function getPin(state: State, room?: ExtendedMeeting): string | null {
  // Personal Room Pin when we are not in meeting and room is undefined
  const personalRoomPublicPin = state.session.roomNumber;
  let pin = room ? room.pin : personalRoomPublicPin;

  if (room) {
    const ownRoom = (room.organizer || {}).username === state.session.username;
    if (room.room_type === 'personal' && ownRoom && personalRoomPublicPin != null) {
      // use pin from session, as it is updated in real time (e.g. when
      // user regenerates pins)
      pin = personalRoomPublicPin;
    }
  }
  return pin;
}
