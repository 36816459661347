import React  from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useIntl, defineMessages } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { IconReload } from '../IconSet';
import LocalStorage from '../../localStorage';
import { getLogger } from '../../lib/logger';
import prepareWebRtcProvider from '../../rtc';
import { RtcDevices, getVideoQualities } from '../../lib/api/rtcDevices';
import {
  saveVideoConfig,
  saveAudioInConfig,
  saveAudioOutConfig,
  saveVideoQuality,
} from '../../lib/actions/settings';
import { State } from '../../lib/reducers';


const messages = defineMessages({
  noDeviceAvailable: { id: 'noDeviceAvailable' },
  rescanDevices: { id: 'rescanDevices' },
});


type Devices = {
  videoinput: MediaDeviceInfo[];
  audioinput: MediaDeviceInfo[];
  audiooutput: MediaDeviceInfo[];
  videoquality: string[];
}


type VideoQuality = ReturnType<typeof getVideoQualities>[0]


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsContainer: {
      width: '100%',
    },
    settingsElement: {
      marginBottom: theme.spacing(1),
    },
    refreshButton: {
      alignSelf: 'center',
      marginBottom: theme.spacing(1),
    },
  })
);


function getRtc() {
  const logger = getLogger('AVSettings');
  const webrtc = prepareWebRtcProvider();
  return new RtcDevices(webrtc, logger);
}


function orEmptyItem(arr: React.ReactNode[], msg: string) {
  if (arr.length > 0) {
    return arr;
  }
  return (
    <MenuItem key={0} value={''}>{msg}</MenuItem>
  );
}


function discoverDevices() {
  const rtc = getRtc();
  return rtc.discoverDevices();
}


function groupDevicesByKind(devs: MediaDeviceInfo[]): Devices {
  const videoIns: Devices['videoinput'] = [];
  const audioIns: Devices['audioinput'] = [];
  const audioOuts: Devices['audiooutput'] = [];
  devs.forEach((d) => {
    if (d.kind === 'videoinput') {
      videoIns.push(d);
    }
    else if (d.kind === 'audioinput') {
      audioIns.push(d);
    }
    else if (d.kind === 'audiooutput') {
      audioOuts.push(d);
    }
  });

  return {
    videoinput: videoIns,
    audioinput: audioIns,
    audiooutput: audioOuts,
    videoquality: [],
  };
}


function filterVideoQualities(
  videoQualities: ReturnType<typeof getVideoQualities>,
  roomOptions: ExtendedProps['roomOptions']
) {

  let filt = (_q: VideoQuality) => true;
  if (roomOptions.stream_quality === 'low') {
    filt = ({ value }) => value !== 'hd' && value !== 'fhd';
  }
  else if (roomOptions.stream_quality === 'high') {
    filt = ({ value }) => value !== 'nhd' && value !== 'vga' && value !== '180p';
  }
  return videoQualities.filter(filt);
}


function DeviceSelection(props: ExtendedProps) {
  const { withVideo, roomOptions } = props;

  const { formatMessage } = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const videoQualities = filterVideoQualities(getVideoQualities(), roomOptions);

  const [devices, setDevices] = React.useState<Devices>(
    {
      videoinput: [],
      audioinput: [],
      audiooutput: [],
      videoquality: [],
    }
  );

  const defaultVideoQuality = { value: '', label: '' } as VideoQuality;

  const ifAvailable = (dev: null | MediaDeviceInfo) => {
    // helper fun to avoid rendering devices that are no more available
    const dflt = { deviceId: '' } as MediaDeviceInfo;
    if (!dev) {
      return dflt;
    }
    const findById = (d: MediaDeviceInfo) => d.deviceId === dev.deviceId;
    return devices[dev.kind].find(findById) ? dev : dflt;
  };

  const currentVideoInDevice = ifAvailable(props.currentVideoInputDev);
  const currentAudioInDevice = ifAvailable(props.currentAudioInDev);
  const currentAudioOutDevice = ifAvailable(props.currentAudioOutDev);
  const currentVideoQuality = props.currentVideoQual || defaultVideoQuality;

  const scanDevices = React.useCallback(
    () => {
      discoverDevices().then(
        (devs: ReturnType<typeof discoverDevices>) => {
          setDevices(groupDevicesByKind(devs));
        }
      );
    }
    , [setDevices]
  );

  React.useEffect(
    () => scanDevices()
    , [scanDevices]
  );

  const handleVideoQualityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const qualityId = event.target.value;
    const quality = videoQualities.find((el) => el.value === qualityId);
    if (quality) {
      dispatch(saveVideoQuality(quality, new LocalStorage()));
    }
  };

  const handleVideoInChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const devId = event.target.value;
    const device = devices.videoinput.find((el) => el.deviceId === devId);
    if (device) {
      dispatch(saveVideoConfig(device, new LocalStorage()));
    }
  };

  const handleAudioOutChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const devId = event.target.value;
    const device = devices.audiooutput.find((el) => el.deviceId === devId);
    if (device) {
      dispatch(saveAudioOutConfig(device, new LocalStorage()));
    }
  };

  const handleAudioInChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const devId = event.target.value;
    const device = devices.audioinput.find((el) => el.deviceId === devId);
    if (device) {
      dispatch(saveAudioInConfig(device, new LocalStorage()));
    }
  };

  const noDevices = formatMessage(messages.noDeviceAvailable);

  return (
    <Box
      className={classes.settingsContainer}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <FormControl className={classes.settingsElement}>
        <InputLabel>Audio input</InputLabel>
        <Select
          value={currentAudioInDevice.deviceId}
          onChange={handleAudioInChange}
        >
          {
            orEmptyItem(
              devices.audioinput.map((d, idx) =>
                <MenuItem key={idx} value={d.deviceId}>{d.label}</MenuItem>
              )
              , noDevices
            )
          }
        </Select>
      </FormControl>
      <FormControl className={classes.settingsElement}>
        <InputLabel>Audio output</InputLabel>
        <Select
          value={currentAudioOutDevice.deviceId}
          onChange={handleAudioOutChange}
        >
          {
            orEmptyItem(
              devices.audiooutput.map((d, idx) =>
                <MenuItem key={idx} value={d.deviceId}>{d.label}</MenuItem>
              )
              , noDevices
            )
          }
        </Select>
      </FormControl>
      <FormControl className={classes.settingsElement}>
        <InputLabel>Video input</InputLabel>
        <Select
          disabled={!withVideo}
          value={currentVideoInDevice.deviceId}
          onChange={handleVideoInChange}
        >
          {
            orEmptyItem(
              devices.videoinput.map((d, idx) =>
                <MenuItem key={idx} value={d.deviceId}>{d.label}</MenuItem>
              )
              , noDevices
            )
          }
        </Select>
      </FormControl>
      <FormControl className={classes.settingsElement}>
        <InputLabel>Video quality</InputLabel>
        <Select
          disabled={!withVideo}
          value={currentVideoQuality.value}
          onChange={handleVideoQualityChange}
        >
          {
            orEmptyItem(
              videoQualities.map((q, idx) =>
                <MenuItem key={idx} value={q.value}>{q.label}</MenuItem>
              )
              , noDevices
            )
          }
        </Select>
      </FormControl>
      <div className={classes.refreshButton}>
        <Tooltip title={formatMessage(messages.rescanDevices)}>
          <IconButton onClick={scanDevices}>
            <IconReload size={20} />
          </IconButton>
        </Tooltip>
      </div>
    </Box>
  );
}



function mapStateToProps(state: State) {
  return {
    currentVideoInputDev: state.settings.videoDevice,
    currentAudioInDev: state.settings.audioInDevice,
    currentAudioOutDev: state.settings.audioOutDevice,
    currentVideoQual: state.settings.videoQuality,
    roomOptions: state.appconfig.room_options,
  };
}


type Props = {
  withVideo: boolean;
};


type MappedProps = {
  currentVideoInputDev: State['settings']['videoDevice'];
  currentAudioInDev: State['settings']['audioInDev'];
  currentAudioOutDev: State['settings']['audioOutDev'];
  currentVideoQual: State['settings']['videoQuality'];
  roomOptions: State['appconfig']['room_options'];
}


type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(DeviceSelection);
