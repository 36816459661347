import { commitMutation } from 'react-relay';
import { IEnvironment } from 'relay-runtime';
import { graphql } from 'babel-plugin-relay/macro';

import { renewMyPinsMutation } from './__generated__/renewMyPinsMutation.graphql';


const mutation = graphql`
mutation renewMyPinsMutation {
  renewMyPins {
    pins {
      roomNumber
      roomOwnerNumber
    }
    errors {
      key
      message
      reason
    }
  }
}
`;


export function renewMyPins(environment: IEnvironment) {
  return new Promise((resolve, reject) => {
    commitMutation<renewMyPinsMutation>(
      environment,
      {
        mutation,
        variables: {},
        onCompleted: ({ renewMyPins }, otherErrors) => {
          if (otherErrors) {
            return reject(otherErrors);
          } else if (renewMyPins && renewMyPins.errors) {
            return reject(renewMyPins && renewMyPins.errors);
          }
          else if (renewMyPins && renewMyPins.pins) {
            return resolve(renewMyPins.pins);
          }
          else {
            reject([]);
          }
        },
        onError: err => {
          reject(err);
        }
      }
    );
  });
}



