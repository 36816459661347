import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { AlternateEmailOutlined } from '@material-ui/icons';
import ClosableDialog from '../ClosableDialog';

import getRouteFor, { Route } from '../../lib/utils/router';
import { IconUser, IconEmail, IconLocation, IconLocked } from '../IconSet';
import RoomInfo from '../RoomInfo';
import RoomLockPreferenceSwitch from './RoomLockPreferenceSwitch';


const messages = defineMessages({
  title: { id: 'userInformation' },
  email: { id: 'email' },
  username: { id: 'username' },
  name: { id: 'name' },
  personalRoom: { id: 'personalRoom' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      paddingBottom: theme.spacing(2),
    },
    container: {
      padding: theme.spacing(1),
    },
    avatar: {
      width: "50%",
      height: "auto",
      paddingBottom: "50%",
      marginTop: '20px',
    },
    icons: {
      textAlign: 'center',
      alignSelf: 'center',
    },
    userInfoEntryTitle: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1em',
    },
    userInfoEntryContent: {
      color: 'rgba(0, 0, 0, 0.90)',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },
    linkContent: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    userInfoIconContainer: {
      display: 'flex',
    },
    userInfoLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);


type MappedProps = {
  email: string;
  username: string;
  displayName: string;
}


type Props = {
  onClose: () => void;
} & MappedProps;


type ExtendedProps = Props & { intl: IntlShape };


function UserProfile(props: ExtendedProps) {
  const classes = useStyles();
  const { intl } = props;

  const personalRoomRawUrl = getRouteFor(
    Route.Meeting,
    { slug: props.username },
    undefined,
    window.location.origin
  );

  return (
    <ClosableDialog
      open={true}
      onClose={props.onClose}
      title={props.intl.formatMessage(messages.title)}
    >
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconUser size={24} />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {intl.formatMessage(messages.name)}
                <div className={classes.userInfoEntryContent}>
                  {props.displayName}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconEmail size={24} />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {intl.formatMessage(messages.email)}
                <div className={classes.userInfoEntryContent}>
                  {props.email}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <AlternateEmailOutlined />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {intl.formatMessage(messages.username)}
                <div className={classes.userInfoEntryContent}>
                  {props.username}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconLocation size={24} />
            </Grid>
            <Grid item xs={10}>
              <RoomInfo
                roomUrl={personalRoomRawUrl}
                roomTitle={messages.personalRoom}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconLocked size={24} />
            </Grid>
            <Grid item xs={10}>
              <RoomLockPreferenceSwitch />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </ClosableDialog>
  );
}


type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session: any;
}



const mapStateToProps = (state: State): MappedProps => ({
  email: state.session.email,
  username: state.session.username,
  displayName: state.session.displayName,
});


export default connect(mapStateToProps)(injectIntl(UserProfile));
