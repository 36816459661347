import { RtcStats, streamUsage } from 'rtcstats';
import { User, Config, StreamDirection, StreamEvent, StreamUsage, WebrtcFunction } from './types';


export default class Voismart {
  private rtcstats: null | RtcStats = null;

  start(appId: string, appSecret: string, _userId: User, parsedUa: object | undefined, { endpoint }: Config) {

    this.rtcstats = new RtcStats(appId, appSecret, endpoint, parsedUa);
  }

  addStream(
    pc: RTCPeerConnection,
    conferenceId: string,
    localUserId: string,
    remoteUserId: string,
    usage: StreamUsage, _direction: StreamDirection) {
    if (!this.rtcstats) {
      return;
    }
    const mappedUsage = this.remapUsage(usage);
    return this.rtcstats.addStream(pc, conferenceId, localUserId, mappedUsage);
  }

  sendStreamEvent(_pc: RTCPeerConnection, _event: StreamEvent, _conferenceID: string, _eventData: object) {
    return;
  }

  sendConferenceMetadata(conferenceId: string, metadata: object) {
    if (!this.rtcstats) {
      return;
    }
    return this.rtcstats.sendConferenceMetadata(conferenceId, metadata);
  }

  sendUserMetadata(conferenceId: string, userId: string, metadata: object) {
    if (!this.rtcstats) {
      return;
    }
    return this.rtcstats.sendUserMetadata(conferenceId, userId, metadata);
  }

  reportError(conferenceId: string, userId: string, failedFunction: WebrtcFunction) {
    if (!this.rtcstats) {
      return;
    }
    return this.rtcstats.reportError(conferenceId, userId, failedFunction);
  }

  private remapUsage(u: StreamUsage) {
    if (["audio", "video", "screen"].includes(u)) {
      return u as streamUsage;
    }
    else if (u === "multiplex") {
      return "mplex" as streamUsage;
    }
    else {
      return "video" as streamUsage;
    }
  }

}
