import React from 'react';

import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { IconHandUp, IconHandUpDisabled } from '../IconSet';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import messages from './buttonsMessages';

import { State as RoomState } from '../../lib/reducers/room';
import { State as WebsocketState } from '../../lib/reducers/websocket';
import { toggleRaiseHand } from '../../lib/actions/room';
import { iconColors as colors } from '../../colors';


function RaiseHandButton(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const toggleHand = React.useCallback(
    () => dispatch(toggleRaiseHand())
    , [dispatch]
  );

  const label = props.isHandRaised ? formatMessage(messages.lowerHand) : formatMessage(messages.raiseHand);
  const selected = props.isHandRaised ? classes.buttonsSelected : null;
  const cls = classNames(classes.buttons, selected);

  return (
    <ToolbarButton
      icon={props.isHandRaised
        ? <IconHandUpDisabled color={colors.contrast} size={28} />
        : <IconHandUp size={28} />
      }
      text={label}
      buttonProps={{
        className: cls,
        onClick: toggleHand,
        disabled: props.isRequestingRaiseHand
      }}
    />
  );
}


type State = {
  room: RoomState;
  websocket: WebsocketState;
}


type MappedProps = {
  isHandRaised: boolean;
  isRequestingRaiseHand: boolean;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const uid = state.websocket.uid;
  let isHandRaised = false;
  let isRequestingRaiseHand = false;

  if (uid) {
    const user = state.room.roster[uid];
    isHandRaised = (user || { isHandRaised: false }).raisedHand;
    isRequestingRaiseHand = (user || { isRequestingRaiseHand: false }).isRequestingRaiseHand;
  }
  return {
    isHandRaised,
    isRequestingRaiseHand
  };
};


export default connect(mapStateToProps)(RaiseHandButton);
