import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { newEvent, ERROR } from '../../lib/notifications';


class MyRecordingPlayer extends Component {

  onError = () => {
    newEvent(ERROR, 'reportUnablePlayRecording', 'reportUnablePlayRecording', "Play recording Error");
    this.props.onCloseClick();
  }

  render() {
    return (
      <Dialog
        open={true}
        onClose={this.props.onCloseClick}
        maxWidth='md'
      >
        <DialogContent>
          <video
            controls
            controlsList="nodownload"
            width='100%'
            height='100%'
            preload='auto'
            src={this.props.recordingUrl}
            onError={this.onError}
          />

        </DialogContent>
      </Dialog>
    );
  }
}

MyRecordingPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  recordingUrl: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};


export default withStyles(null)(MyRecordingPlayer);
