import React from 'react';

import Roster from '../../Roster';
import { ChatPanel } from '../../Chat';
import RoomInformation from '../../RoomInformation';
import { PaneType, SelectedPane } from '..';


type PaneProps = {
  selection: SelectedPane;
  size: string;
}


function PaneComponent(props: PaneProps) {
  const { selection, size } = props;

  if (selection === PaneType.Roster) {
    return (<Roster size={size} />);
  }
  else if (selection === PaneType.Chat) {
    return (<ChatPanel />);
  }
  else if (selection === PaneType.Info) {
    return (<RoomInformation />);
  }
  else {
    return null;
  }
}


export default PaneComponent;
