import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuLayout from '../MenuLayout';
import { ChatIcon } from '../Chat';
import RosterIcon from '../Roster/RosterIcon';
import LockRoom from '../LockRoom';

import { State } from '../../lib/reducers';
import { Meeting } from '../../lib/reducers/websocket';
import {
  isAudioOnlyLayout,
  isThereAnyVideo,
  amModerator
} from '../../lib/reduxSelectors/room';
import { disableRoster } from '../../lib/reduxSelectors/roster';
import { PaneType, SelectedPane } from './';
import LockedJoinRequestsButton from '../LockedJoinRequestsButton';


const messages = defineMessages({
  chatIconButtonTooltip: { id: 'chatIconButtonTooltip' },
  attendees: { id: 'attendees' },
});


type Props = {
  paneOpened: boolean;
  selectedPane: SelectedPane;
  openPane: (type: PaneType, open: boolean, pane: SelectedPane) => void;
}

type ExtendedProps = Props & MappedProps


function MeetingBar(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const { paneOpened, selectedPane, openPane, meetingType, disableRoster } = props;

  const cannotChangeLayout = (
    !props.isThereAnyVideo
    || (props.isAudioOnlyLayout && !props.amModerator)
    || meetingType !== "regular"
  );

  const onRosterClick = React.useCallback(
    () => openPane(PaneType.Roster, paneOpened, selectedPane)
    , [paneOpened, selectedPane, openPane]
  );

  const onChatClick = React.useCallback(
    () => openPane(PaneType.Chat, paneOpened, selectedPane)
    , [paneOpened, selectedPane, openPane]
  );

  return (
    <List>
      <ListItem
        disabled={disableRoster}
        button
        onClick={onRosterClick}
      >
        <Tooltip
          placement="left"
          title={formatMessage(messages.attendees)}
        >
          <ListItemIcon>
            <RosterIcon
              rosterPanelOpen={paneOpened && selectedPane === PaneType.Roster}
            />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
      <ListItem button onClick={onChatClick}>
        <Tooltip
          placement="left"
          title={formatMessage(messages.chatIconButtonTooltip)}
        >
          <ListItemIcon>
            <ChatIcon
              chatPanelOpen={paneOpened && (selectedPane === PaneType.Chat)}
            />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
      <MenuLayout disabled={cannotChangeLayout} />
      <ListItem button>
        <ListItemIcon>
          <LockRoom />
        </ListItemIcon>
      </ListItem>
      <LockedJoinRequestsButton />
    </List>
  );
}

type MappedProps = {
  isThereAnyVideo: boolean;
  isAudioOnlyLayout: boolean;
  amModerator: boolean;
  meetingType: Meeting['type'];
  disableRoster: boolean;
}


const mapStateToProps = (state: State): MappedProps => {
  let meetingType: Meeting['type'] = "regular";
  if (state.websocket.meeting_details) {
    meetingType = state.websocket.meeting_details.type;
  }
  return {
    isThereAnyVideo: isThereAnyVideo(state),
    isAudioOnlyLayout: isAudioOnlyLayout(state),
    amModerator: amModerator(state),
    meetingType,
    disableRoster: disableRoster(state),
  };
};


export default connect(mapStateToProps)(MeetingBar);
