import uuid from 'uuid';
import { Socket, /*SocketConnectOption,*/ Channel } from 'phoenix';
import { isElectron } from 'react-device-detect';

import { RtcDevices } from './rtcDevices';
import { traceWSCloseEvent } from '../sentry';
import { sendStreamEvent, statsAddStream } from '../callstats';
import { INFO, newEvent } from '../notifications';
import { onPresenceUpdate, PresenceDispatchFunction } from './presence';
import { traceError as sentryTraceError } from '../sentry';
import { getLogger, LoggerInterface } from '../logger';
import {
  Error as RoomError,
  Publisher,
  WebRTCPublisher,
  StreamOptions,
  VideoQualityOptions,
  Params,
  StreamType,
  ScreenSourceType,
  RoomLayout,
  RoomLayoutConfig,
} from '../redux_types';
import { Meeting } from '../reducers/websocket';
import { State } from '../reducers';


// extend MediaStreamTrack with contentHint attribute since it is not still in
// the ts definition
// the attribute is optional since not all browser supports it
interface MediaStreamTrackWithContentHint extends MediaStreamTrack {
  contentHint?: '' | 'speech' | 'music' | 'text' | 'motion' | 'detail';
}


type ServerMessage =
  LocalVideoEvent
  | RemoteVideoEvent
  | LocalAudioEvent
  | ScreenEvent
  | SipVideoEvent
  | ConferenceTimeEvent
  | RpcMessage

// FIXME: add proper structure and type to all server messages
// this will help error handling and to avoid out of sync problem
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ServerMsg = { [key: string]: any }
type LocalVideoEvent = ServerMsg
type RemoteVideoEvent = ServerMsg
type LocalAudioEvent = ServerMsg
type ScreenEvent = ServerMsg
type SipVideoEvent = ServerMsg
type ConferenceTimeEvent = ServerMsg
type RpcMessage = ServerMsg

export type JoinError = {
  code: number;
  type: string;
  reason: string;
}

export type JoinedMessage = {
  uid: string;
  room_uid: string;
  meeting_details: Meeting;
  room_roles: string[];
  layout: { layout: RoomLayout; layoutConfig: RoomLayoutConfig };
  record_status: {
    enabled: boolean;
    is_recorder: boolean;
    is_recording: boolean;
    livestreaming_enabled: boolean;
    is_livestreaming: boolean;
  };
  deskshare_status: { enabled: boolean };
  deskcontrol_status: { enabled: boolean };
  dialout_status: { enabled: boolean };
  locked: boolean;
  can_publish_video: boolean;
  can_publish_audio: boolean;
  owner_is_audio_only: boolean;
}


type JanusCandidate = {
  completed?: boolean;
  candidate: RTCIceCandidate['candidate'];
  sdpMid: RTCIceCandidate['sdpMid'];
  sdpMLineIndex: RTCIceCandidate['sdpMLineIndex'];
  feed_id?: number;
  type?: StreamType;
};


class RestartablePC {
  public pc: RTCPeerConnection;
  public onFinalFailure = () => { };
  public onRestartNeeded = () => { };
  public wasConnected = false;

  private description = "";
  private logger: LoggerInterface;
  private failedSince = 0;
  private attWindowedReconnects = 0;
  private maxRetries: number;
  private maxRetriesWindow: number;

  constructor(
    pc: RTCPeerConnection,
    { maxRetries, maxRetriesWindow }: {
      maxRetries: number;
      maxRetriesWindow: number;
    },
    description = ""
  ) {
    this.logger = getLogger('RestartablePC');
    this.pc = pc;
    this.setPc(pc);
    this.maxRetries = maxRetries;
    this.maxRetriesWindow = maxRetriesWindow;
    this.description = description;
  }

  setPc(pc: RTCPeerConnection) {
    this.pc = pc;
    this.maybeSetConnected();
    this.pc.oniceconnectionstatechange = (ev) => this.onIceStateChange(ev);
    this.pc.onconnectionstatechange = (ev) => this.onConnectionStateChange(ev);
  }

  onRestart() {
    this.updateReconnectionAttempts();
    return this.onRestartNeeded();
  }

  private isConnected() {
    return this.pc.iceConnectionState === 'connected';
  }

  private onIceStateChange(_ev: Event) {
    this.logger.info(`${this.description} ICE state change:`, this.pc.iceConnectionState);
  }

  private onConnectionStateChange(_ev: Event) {
    this.logger.info(`${this.description} connection state change:`, this.pc.connectionState);
    this.maybeSetConnected();
    if (this.hasFailed()) {
      this.logger.warn(`${this.description} connection failed`);
      if (this.maxRetriesExhausted()) {
        this.onFinalFailure();
      }
      else {
        this.onRestart();
      }
    }
  }

  maybeSetConnected() {
    const isNowOutsideWindow = () => Date.now() - this.failedSince >= this.maxRetriesWindow;
    if (this.hasFailed() && (this.failedSince === 0 || isNowOutsideWindow())) {
      this.failedSince = Date.now();
      this.attWindowedReconnects = 0;
    }
    else if (this.isConnected()) {
      this.wasConnected = true;
      if (isNowOutsideWindow()) {
        this.failedSince = 0;
        this.attWindowedReconnects = 0;
      }
    }
  }

  private updateReconnectionAttempts() {
    const now = Date.now();
    if (now - this.failedSince < this.maxRetriesWindow) {
      this.attWindowedReconnects += 1;
    }
  }

  private hasFailed() {
    return (
      this.pc.connectionState === 'failed'
      && ['disconnected', 'failed'].includes(this.pc.iceConnectionState)
    );
  }

  private maxRetriesExhausted() {
    return this.attWindowedReconnects > this.maxRetries;
  }
}


class VideoRoom {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rtc: any  // FIXME: remove after porting rtc.js to ts
  private signalingServer: () => Api
  private name: string
  private logger: LoggerInterface
  private acquiringVideo = false // whether we are acquiring local video or not
  private publishingVideo = false // whether we should publish video or not
  private initiallyMuted = false // whether we'd like to join initally muted
  private ownStream: null | MediaStream = null
  private screenStream: null | MediaStream = null
  private screenSourceType: null | ScreenSourceType = null
  private audioPC: null | RestartablePC = null
  private videoPC: null | RestartablePC = null
  private screenPC: null | RestartablePC = null
  private remoteVideoPCs: { [feedId: string]: RestartablePC } = {}
  private screenFeedId: null | number = null
  private _userIds: { [feedId: string]: { id: string; type: StreamType } } = {}
  private stunServers: RTCIceServer[] = [{ urls: 'stun:stun.l.google.com:19302' }]
  private iceReconnectRetries: number;
  private iceReconnectRetriesWindow: number;
  private eventSubscription: string
  private userId: null | string = null
  private roomUID: null | string = null

  // callbacks
  public onScreenStop: () => void = () => { }
  public onAddAudioStream = (_stream: MediaStream) => { };
  public onRemoveAudioStream = () => { };
  public onAddRemoteVideoStream = (_stream: MediaStream, _userId: string, _streamType: StreamType) => { };
  public onRemoveRemoteVideoStream = (_userId: string, _streamType: StreamType) => { };
  public onToggleVideoMute = (_userId: string, _streamType: StreamType, _muted: boolean) => { };
  public onError = (_err: RoomError, _recoverable: boolean) => { };
  public enableDesktopControl = (_uname: string, _enabled: boolean, _streamType: StreamType) => { };
  public onMeetingTimeEvents = (_type: 'duration' | 'expiring' | 'update', _data: ConferenceTimeEvent) => { };
  public onNewPublisher = (_p: Publisher) => { };
  public onUnpublish = (_id: number) => { };

  constructor(
    name: string,
    rtcProvider: VideoRoom['rtc'],
    signalingServer: () => Api,
    options: {
      stunServers?: RTCIceServer[];
      iceReconnectRetries?: number; // number of times we try reconnecting inside the time window
      iceReconnectRetriesWindow?: number; // time window in seconds to count ice retries
    } = {},
    logger: LoggerInterface
  ) {
    this.rtc = rtcProvider;
    this.signalingServer = signalingServer;
    this.name = name;
    this.logger = logger;
    this.screenStream = null;
    this.screenSourceType = null;
    this.stunServers = options.stunServers || [{ urls: 'stun:stun.l.google.com:19302' }];
    this.iceReconnectRetries = options.iceReconnectRetries || 3;
    this.iceReconnectRetriesWindow = options.iceReconnectRetriesWindow || 120 * 1000;
    this.eventSubscription = this.signalingServer().subscribe('event', (data: ServerMessage) => this._onEvent(data));

    // initialize handlers to keep track of them and to avoid typescript errors
    this.onAddAudioStream = (_stream) => { };
    this.onRemoveAudioStream = () => { };
    this.onAddRemoteVideoStream = (_stream, _userId, _streamType) => { };
    this.onRemoveRemoteVideoStream = (_userId, _streamType) => { };
    this.onToggleVideoMute = (_userId, _streamType, _muted) => { };
    this.onError = (_err, _recoverable) => { };
    this.enableDesktopControl = (_uname, _enabled, _streamType) => { };
    this.onMeetingTimeEvents = (_type, _data) => { };
    this.onNewPublisher = (_p) => { };
    this.onUnpublish = (_id) => { };
  }

  static getVideoTrackFromStream(stream: null | MediaStream) {
    if (stream) {
      return stream.getVideoTracks()[0];
    }
    return null;
  }

  static isStreamMuted(stream: null | MediaStream) {
    if (stream) {
      const track = stream.getVideoTracks()[0];
      return track && track.muted;
    }
    return false;
  }

  setUserId(uid: string) {
    this.userId = uid;
  }

  setRoomId(uid: string) {
    this.roomUID = uid;
  }

  getLocalStream(
    state: State,
    options: StreamOptions = { publishVideo: true, acquireVideo: true, muted: false },
    replaceAudio = false
  ) {
    this.acquiringVideo = options.acquireVideo === false ? false : true;
    this.publishingVideo = options.publishVideo === false ? false : true;
    this.initiallyMuted = options.muted === true ? true : false;
    const rtcDevices = new RtcDevices(this.rtc, this.logger);
    const audioDevice = this._getAudioDeviceFromState(state);
    const videoDevice = this._getVideoDeviceFromState(state);
    const videoQuality = this._getVideoQualityFromState(state);
    const devicesOptions = {
      fallbackToAudioOnly: true,
      video: this.acquiringVideo,
      qualityConstraint: options.streamQuality,
      frameRate: options.frameRate,
    };
    const p = rtcDevices.getLocalStreamByDevices(audioDevice, videoDevice, videoQuality, devicesOptions);
    return p.then((s: MediaStream) => this._onUserMediaStream(s, replaceAudio));
  }

  republishStream(
    state: State,
    options: StreamOptions = { publishVideo: true, acquireVideo: true, muted: false }
  ) {
    // stop current stream and republish it (usually with new options, from a
    // different device, etc.
    this.logger.info('republishing stream with options', options);
    return this._unpublishVideo().then(
      () => {
        this._tearDownVideo(this.ownStream);
        this._tearDownAudio(this.ownStream, true);
        return this.getLocalStream(state, options, true);
      }
    );
  }

  changeVideoQuality(options: VideoQualityOptions) {
    if (!this.ownStream) {
      return;
    }

    const track = this.ownStream.getVideoTracks()[0];
    if (!track) {
      return;
    }
    const rtcDevices = new RtcDevices(this.rtc, this.logger);
    const p = rtcDevices.applyTrackConstraints(track, options);
    return p.then(() => this.logger.info('successfully applied track constraints', options))
      .catch((e: Error) => this.logger.error('error applying video constraints:', e));
  }

  shareScreen(onScreenStop: VideoRoom['onScreenStop'], options = {}) {
    if (onScreenStop) {
      this.onScreenStop = onScreenStop;
    }

    return this.rtc.requestScreen(options).then((s: MediaStream) => this._onScreenMediaStream(s));
  }

  stopScreen() {
    this._unpublishScreen();
    if (this.screenStream) {
      this.screenStream.getTracks().forEach((t) => t.stop());
    }
    this.screenStream = null;
    this.screenSourceType = null;
    if (this.screenPC && this.screenPC.pc) {
      sendStreamEvent(this.screenPC.pc, "screenShareStop", this.getConferenceID());
      sendStreamEvent(this.screenPC.pc, "streamTerminated", this.getConferenceID());
      this.screenPC.pc.close();
    }
    this.screenPC = null;
    // N.B. do not reset this.screenFeedId because we only set it at join and
    // we do only one join even when publishing/unpublishing multiple times
  }

  kickUser(user: string) {
    return this.signalingServer().kickUser(user);
  }

  startDeskControl(user: string, desktopControlType: string) {
    return this.signalingServer().startDeskControl(user, desktopControlType);
  }

  startDrawing() {
    return this.signalingServer().startDrawing();
  }

  stopDrawing() {
    return this.signalingServer().stopDrawing();
  }

  startControllingDesktop() {
    return this.signalingServer().startControllingDesktop();
  }

  stopControllingDesktop() {
    return this.signalingServer().stopControllingDesktop();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mouseEvent(userId: string, displayName: string, mouseEventType: any, mouseEventData: any) {
    return this.signalingServer().mouseEvent(userId, displayName, mouseEventType, mouseEventData);
  }

  stopDeskControl(user: string) {
    return this.signalingServer().stopDeskControl(user);
  }

  inviteParticipants(emails: string[], params: Params) {
    return this.signalingServer().inviteParticipants(emails, params);
  }

  dialParticipant(destination: string) {
    return this.signalingServer().dialParticipant(destination);
  }

  applyLayout(layout: State['room']['layout'], layoutConfig: State['room']['layoutConfig']) {
    return this.signalingServer().applyLayout(layout, layoutConfig);
  }

  toggleRoomLock() {
    return this.signalingServer().toggleRoomLock();
  }

  startRecording(params: Params) {
    return this.signalingServer().startRecording(params);
  }

  stopRecording() {
    return this.signalingServer().stopRecording();
  }

  startLivestreaming(params: Params) {
    return this.signalingServer().startLivestreaming(params);
  }

  stopLivestreaming() {
    return this.signalingServer().stopLivestreaming();
  }

  changeUserRole(user: string, role: string) {
    return this.signalingServer().changeRole(user, role);
  }

  subscribeToVideo(publisher: Publisher) {
    return this._subscribeToPublisher(publisher);
  }

  onToggleOwnAudioMute(username: string, muted: boolean) {
    if (username === this.userId && this.audioPC) {
      sendStreamEvent(this.audioPC.pc, muted ? "audioMute" : "audioUnmute", this.getConferenceID());
    }
  }

  toggleVideoMute(user: string, muted: boolean) {
    const feedId = this._getFeedIdFromUserId(user);
    if (!feedId) {
      return Promise.reject("no such user");
    }
    return this.signalingServer().toggleVideoMute(feedId, muted);
  }

  togglePublishVideo(published: boolean) {
    if (published) {
      this._unpublishVideo();
    }
    else {
      this._republishVideo();
    }
  }

  endMeeting() {
    return this.signalingServer().endMeeting();
  }

  toggleRaiseHand() {
    return this.signalingServer().toggleRaiseHand();
  }

  lowerRaisedHand(user: string) {
    return this.signalingServer().lowerRaisedHand(user);
  }

  extendMeeting() {
    return this.signalingServer().extendMeeting();
  }

  acceptLockedJoinRequest(username: string, reqId: string) {
    return this.signalingServer().acceptLockedJoinRequest(username, reqId);
  }

  denyLockedJoinRequest(username: string, reqId: string) {
    return this.signalingServer().denyLockedJoinRequest(username, reqId);
  }

  _republishVideo() {
    this.logger.info('republishing video');
    if (this.ownStream && this.ownStream.getVideoTracks().length) {
      this._buildVideoPeerConnection(this.ownStream);
    }
    else {
      this.logger.warn('cannot republish video, no stream found');
    }
  }

  _unpublishVideo() {
    return this.signalingServer().unpublishVideo();
  }

  _unpublishScreen() {
    this.signalingServer().unpublishScreen();
  }

  _getFeedIdFromUserId(userId: string) {
    const type = userId.endsWith('_screen') ? ['remotescreenevent'] : ['remotevideoevent', 'sip_remotevideoevent'];
    userId = userId.replace(/_screen$/, '');
    const feedId = Object.keys(this._userIds).find(
      key => {
        const value = this._userIds[key];
        return (value.id === userId) && (type.includes(value.type));
      }
    );
    if (feedId) {
      return parseInt(feedId, 10);
    }
  }

  tearDown() {
    // WARNING: this could be called even if join never succeeded (i.e.
    //          websocket never connected, so take care when calling remote
    //          APIs)
    this.logger.info('tearing down room');
    this._tearDownVideo(this.ownStream);
    this._tearDownOtherVideos();
    this._tearDownAudio(this.ownStream, false);
    this.stopScreen();
    this._tearDownSubscriptions();
    this.ownStream = null;
    this.screenStream = null;
    this.screenSourceType = null;
    this._userIds = {};
  }

  _tearDownSubscriptions() {
    if (this.eventSubscription) {
      this.signalingServer().unsubscribe('event', this.eventSubscription);
    }
  }

  _onScreenMediaStream(screenStream: MediaStream | { stream: MediaStream; type: ScreenSourceType }) {
    // TODO: checking for 'stream' and 'type' attributes is very ugly, but this
    // is done because this function will receive input argument of different
    // type in the web and electron apps.
    // We should avoid this and set the screen type via redux in the action or
    // component, not here
    if (isElectron && 'stream' in screenStream && 'type' in screenStream) {
      const { stream, type } = screenStream;
      this.screenSourceType = type;
      this.screenStream = stream;
      this._buildScreenPeerConnection(stream);

      return screenStream;
    }

    if ('stream' in screenStream && 'type' in screenStream) {
      throw new Error('unexpected return type from getDisplayMedia');
    }

    this.screenSourceType = null;
    this.screenStream = screenStream;
    this._buildScreenPeerConnection(screenStream);

    return { stream: screenStream, type: null };
  }

  _getAudioDeviceFromState(state: State) {
    const settings = state.settings;
    if (settings && settings.audioInDevice) {
      return settings.audioInDevice.deviceId;
    }
  }

  _getVideoDeviceFromState(state: State) {
    const settings = state.settings;
    if (settings && settings.videoDevice) {
      return settings.videoDevice.deviceId;
    }
  }

  _getVideoQualityFromState(state: State) {
    const settings = state.settings;
    if (settings && settings.videoQuality) {
      return settings.videoQuality.value;
    }
  }

  _tearDownVideo(stream: null | MediaStream) {
    // local video
    if (stream) {
      stream.getVideoTracks().forEach((s) => s.stop());
    }
    this._closeLocalPeerConnection();
  }

  _tearDownOtherVideos() {
    // remote videos
    let rpc;
    Object.keys(this.remoteVideoPCs).forEach((k) => {
      rpc = this.remoteVideoPCs[k];
      if (rpc.pc) {
        sendStreamEvent(rpc.pc, "streamTerminated", this.getConferenceID());
        rpc.pc.close();
      }
    });
    this.remoteVideoPCs = {};
  }

  _closeLocalPeerConnection() {
    if (this.videoPC) {
      sendStreamEvent(this.videoPC.pc, "streamTerminated", this.getConferenceID());
      this.videoPC.pc.close();
    }
    this.videoPC = null;
  }

  _tearDownAudio(stream: null | MediaStream, keepPeerConnection = false) {
    if (stream) {
      stream.getAudioTracks().forEach((s) => s.stop());
    }
    if (!keepPeerConnection) {
      if (this.audioPC) {
        sendStreamEvent(this.audioPC.pc, "streamTerminated", this.getConferenceID());
        this.audioPC.pc.close();
      }
      this.audioPC = null;
    }
  }

  _onUserMediaStream(stream: MediaStream, replaceAudio = false) {
    this.logger.info('got user media, replaceAudio is', replaceAudio);
    const audioTrack = stream.getAudioTracks()[0];
    if (replaceAudio && this.audioPC && audioTrack) {
      const sender = this.audioPC.pc.getSenders().find(
        (s) => s.track && (s.track.kind === 'audio')
      );
      if (sender) {
        sender.replaceTrack(audioTrack);
      }
    }
    else {
      this._buildAudioPeerConnection(stream);
    }

    if (!this.publishingVideo) {
      this.logger.info('not publishing video');
    }
    else if (stream.getVideoTracks().length) {
      this.logger.info('stream got video tracks, build video peer connection');
      this._buildVideoPeerConnection(stream);
    }
    else if (this.acquiringVideo) {
      newEvent(INFO, 'noVideoDeviceFound', 'noVideoDeviceFound',
        "Cannot access local camera");
    }
    this.ownStream = stream;
    return stream;
  }

  _applyContentHint(track: MediaStreamTrackWithContentHint, type: MediaStreamTrackWithContentHint['contentHint']) {
    /* apply content hint to track, as defined in
     * https://w3c.github.io/mst-content-hint */
    if (track.contentHint || track.contentHint === '') {
      const trackInfo = `${track.kind ? track.kind : '-'}:${track.label ? track.label : '-'}`;
      this.logger.info(`Applying content hint "${type}" to track ${trackInfo}.`);
      track.contentHint = type; // eslint-disable @typescript-eslint/no-explicit-any
    }
    else {
      this.logger.warning('cannot apply content hint to track: no browser support');
    }
  }

  getConferenceID() {
    return `${this.roomUID}`;
  }

  getSfuRemoteUserID() {
    return `sfu-${this.roomUID}`;
  }

  _buildVideoPeerConnection(stream: MediaStream) {
    const pc = this._createRTCPeerConnection();
    if (this.roomUID && this.userId) {
      statsAddStream(pc, this.getConferenceID(), this.userId, this.getSfuRemoteUserID(), "video", "sendonly");
    }
    this.logger.log('pc video is', pc);
    stream.getVideoTracks().forEach((s: MediaStreamTrack) => {
      this._applyContentHint(s, 'motion');
      pc.addTrack(s, stream);
    });
    const opts = {
      'offerToReceiveAudio': false,
      'offerToReceiveVideo': true,
    };
    this.videoPC = new RestartablePC(
      pc,
      { maxRetries: this.iceReconnectRetries, maxRetriesWindow: this.iceReconnectRetriesWindow },
      'localvideo'
    );
    this.videoPC.onRestartNeeded = () => {
      this.onError({ errorCode: 1413, errorMessage: 'ICE failure publishing video' }, true);
      pc.createOffer({ ...opts, iceRestart: true })
        .then((d: RTCSessionDescriptionInit) => this._onOfferSuccess(pc, d, 'localvideoevent', true))
        .catch((e: Error) => this._onOfferFailure(e));
    };
    this.videoPC.onFinalFailure = () => {
      this.logger.error('ICE failed too many times for video publish. Giving up');
      this.onError({ errorCode: 1410, errorMessage: 'too many ICE failures publishing video' }, true);
      this.togglePublishVideo(true);
    };
    this._onPeerConnectionCallbacks(opts, pc, 'localvideoevent');
  }

  _buildAudioPeerConnection(stream: MediaStream) {
    let pc: RTCPeerConnection;
    if (this.audioPC) {
      // keep peer connection if we already have one
      pc = this.audioPC.pc;
    }
    else {
      pc = this._createRTCPeerConnection();
    }
    if (this.roomUID && this.userId) {
      statsAddStream(pc, this.getConferenceID(), this.userId, this.getSfuRemoteUserID(), "audio", "sendrecv");
    }
    this.logger.log('pc audio is', pc);
    stream.getAudioTracks().forEach((s) => {
      this._applyContentHint(s, 'speech');
      pc.addTrack(s, stream);
    });
    const opts = {
      'offerToReceiveAudio': true,
      'offerToReceiveVideo': false,
    };
    this.audioPC = new RestartablePC(
      pc,
      { maxRetries: this.iceReconnectRetries, maxRetriesWindow: this.iceReconnectRetriesWindow },
      'localaudio',
    );
    this.audioPC.onRestartNeeded = () => {
      this.onError({ errorCode: 1413, errorMessage: 'ICE failure publishing audio' }, true);
      pc.createOffer({ ...opts, iceRestart: true })
        .then((d: RTCSessionDescriptionInit) => this._onOfferSuccess(pc, d, 'localaudioevent', true))
        .catch((e: Error) => this._onOfferFailure(e));
    };
    this.audioPC.onFinalFailure = () => {
      this.onError({ errorCode: 1409, errorMessage: 'too many ICE failures publishing audio' }, false);
      this.logger.error('ICE failed too many times for audio publish. Giving up');
      this.tearDown();
    };
    this._onPeerConnectionCallbacks(opts, pc, 'localaudioevent');
  }

  _buildScreenPeerConnection(stream: MediaStream) {
    const onScreenEnded = () => {
      this.stopScreen();
      this.onScreenStop();
    };
    const pc = this._createRTCPeerConnection();
    if (this.roomUID && this.userId) {
      statsAddStream(pc, this.getConferenceID(), this.userId, this.getSfuRemoteUserID(), "screen", "sendonly");
      sendStreamEvent(pc, "screenShareStart", this.getConferenceID());
    }
    stream.getVideoTracks().forEach((s) => {
      this._applyContentHint(s, 'detail');
      s.onended = onScreenEnded;
      pc.addTrack(s, stream);
    });
    const opts = {
      'offerToReceiveAudio': false,
      'offerToReceiveVideo': true,
    };
    this.screenPC = new RestartablePC(
      pc,
      { maxRetries: this.iceReconnectRetries, maxRetriesWindow: this.iceReconnectRetriesWindow },
      'screen'
    );
    this.screenPC.onRestartNeeded = () => {
      this.onError({ errorCode: 1413, errorMessage: 'ICE failure publishing screen' }, true);
      pc.createOffer({ ...opts, iceRestart: true })
        .then((d: RTCSessionDescriptionInit) => this._onOfferSuccess(pc, d, 'screen', true))
        .catch((e: Error) => this._onOfferFailure(e));
    };
    this.screenPC.onFinalFailure = () => {
      this.logger.error('ICE failed too many times for screen publish. Giving up');
      this.onError({ errorCode: 1412, errorMessage: 'too many ICE failures publishing screen' }, true);
      onScreenEnded();
    };
    this._onPeerConnectionCallbacks(opts, pc, 'screen');
  }

  _onPeerConnectionCallbacks(opts: RTCOfferOptions, pc: RTCPeerConnection, streamType: StreamType) {
    this.logger.info('setting up pc callbacks for stream type', streamType);
    pc.onicecandidate = (ev) => this._onIceCandidate(pc, ev, streamType, undefined);
    pc.ontrack = (ev) => this._onAddStream(ev, streamType);
    pc.createOffer(opts).then(
      (d) => this._onOfferSuccess(pc, d, streamType)
    ).catch(
      (e) => this._onOfferFailure(e)
    );
  }

  _onIceCandidate(pc: RTCPeerConnection, ev: RTCPeerConnectionIceEvent, type: StreamType, feedId?: number) {
    if (this._candidatesGatheringDone(ev)) {
      this.signalingServer().doneGatheringTrickleCandidates(this.name, type, feedId);
    }
    else if (ev.candidate) { // this check should not be needed, but the compiler needs it
      const candidate: JanusCandidate = {
        "candidate": ev.candidate.candidate,
        "sdpMid": ev.candidate.sdpMid,
        "sdpMLineIndex": ev.candidate.sdpMLineIndex
      };
      this.signalingServer().sendTrickleCandidate(this.name, candidate, type, feedId);
    }
  }

  _candidatesGatheringDone(ev: RTCPeerConnectionIceEvent) {
    return !ev.candidate;
  }

  _onRemoveStream(ev: ServerMessage, streamType: StreamType) {
    if (streamType === 'remotevideoevent') {
      this._destroyRemotePeerConnection(ev.feed_id);
      const userId = this._userIds[ev.feed_id];
      delete this._userIds[ev.feed_id];
      if (userId) {
        this.onRemoveRemoteVideoStream(userId.id, userId.type);
      }
    }
    else if (streamType === 'localaudioevent') {
      // call this.onRemoveAudioStream
    }
    else {
      this.logger.warning(`unknown stream type "${streamType}"`);
    }
  }

  _onAddStream(ev: ServerMessage, streamType: StreamType, userId?: string) {
    this.logger.log('got remote stream', ev);
    if ((streamType === 'remotevideoevent')
      || (streamType === 'remotescreenevent')
      || (streamType === 'sip_remotevideoevent')
    ) {
      if (!userId) {
        this.logger.warning('trying to add a remote stream without a userId, ignoring it');
        return;
      }
      this.onAddRemoteVideoStream(ev.streams[0], userId, streamType);
    }
    else if (streamType === 'localaudioevent') {
      this.onAddAudioStream(ev.streams[0]);
    }
    else {
      this.logger.warning(`unknown stream type "${streamType}"`);
    }
  }

  _onOfferSuccess(
    pc: RTCPeerConnection,
    sessDescr: RTCSessionDescriptionInit,
    streamType: StreamType,
    update?: boolean
  ) {
    // TODO: consider adding bandwidth control to the local sdp (maybe only for
    //       screensharing?). See: https://stackoverflow.com/a/16868123
    this.logger.log('local sdp is ', sessDescr.sdp);
    pc.setLocalDescription(sessDescr).then(
      () => {
        const streamOptions: { muted: boolean; update?: boolean } = {
          muted: this.initiallyMuted,
        };
        if (update) {
          streamOptions.update = true;
        }
        this.signalingServer().sendSdp(
          this.name,
          sessDescr.type,
          sessDescr.sdp,
          streamType,
          streamOptions
        );
      }
    );
  }

  _onOfferFailure(err: Error) {
    // FIXME: handle sdp offer failure (quit room)
    this.logger.error('create offer failure: ', err);
  }

  _onEvent(data: ServerMessage) {
    if (data.type === 'localvideoevent') {
      this._onLocalvideoEvent(data);
    }
    else if (data.type === 'remotevideoevent') {
      this._onRemotevideoEvent(data);
    }
    else if (data.type === 'localaudioevent') {
      this._onLocalaudioEvent(data);
    }
    else if (data.type === 'screen') {
      this._onScreenEvent(data);
    } else if (data.type === 'sip_remotevideoevent') {
      this._onVideoSipEvent(data);
    } else if (data.type === 'conference_duration') {
      this._onMeetingTimeEvents('duration', data);
    } else if (data.type === 'conference_about_to_expire') {
      this._onMeetingTimeEvents('expiring', data);
    } else if (data.type === 'conference_update') {
      this._onMeetingTimeEvents('update', data);
    }
  }

  _onLocalvideoEvent(data: LocalVideoEvent) {
    if (data.data.configured === 'ok' && data.jsep && data.jsep.type === 'answer') {
      if (!this.videoPC) {
        this.logger.error('missing local video peer connection');
        return;
      }
      this._setRemoteSdp(this.videoPC.pc, data.jsep);
      if (this.userId) {
        this.onToggleVideoMute(this.userId, 'localvideoevent', false);
      }
      if (!data.data.video_codec) {
        this.logger.error('no video codec available', data);
        this.onError({ errorCode: 9000, errorMessage: 'no video codec available in jsep' }, true);
      }
    }
    else if (data.data.configured === 'ok') {
      // these are events like bitrate change, etc. Ignore
    }
    else if (data.data.publishers && (data.data.videoroom === 'event' || data.data.videoroom === 'joined')) {
      const publishers = data.data.publishers;
      publishers.forEach((p: Publisher) => {
        p.kind = 'webrtc';
        this._onNewPublisher(p);
      });
    }
    else if (data.data.unpublished === 'ok') {
      if (this.userId) {
        this.onToggleVideoMute(this.userId, 'localvideoevent', true);
      }
      this._closeLocalPeerConnection();
    }
    else if (data.data.hangup === 'ok') {
      this.logger.warning('received localvideo hangup with reason', data.data.reason);
      sentryTraceError('received localvideo hangup', { error: { type: 'hangup', reason: data.data.reason } });
      if (data.data.reason === 'ice_failed') {
        if (this.videoPC && this.videoPC.wasConnected) {
          this.onError({ errorCode: 1410, errorMessage: 'too many ICE failures publishing video' }, true);
        }
        else {
          this.onError({ errorCode: 1409, errorMessage: 'video ICE failed' }, false);
        }
      }
      // manually close the PC, since it is too late to send the unpublish
      // command to server
      if (this.userId) {
        this.onToggleVideoMute(this.userId, 'localvideoevent', true);
      }
      this._closeLocalPeerConnection();
    }
    else {
      this.logger.debug('unhandled localvideo event', data);
    }
  }

  _onScreenEvent(data: ScreenEvent) {
    if ((data.data.configured === 'ok') && data.jsep && (data.jsep.type === 'answer')) {
      if (!this.screenPC || !this.screenPC.pc) {
        this.logger.error('missing local screen peer connection');
        return;
      }
      this._setRemoteSdp(this.screenPC.pc, data.jsep);
    }
    else if (data.data.configured === 'ok') {
      // these are events like bitrate change, etc. Ignore
    }
    else if (data.data.videoroom === 'joined') {
      // set own screen feed id to avoid subscribing to it
      this.screenFeedId = data.data.id;
    }
    else if (data.data.hangup === 'ok') {
      this.logger.warning('received screen hangup with reason', data.data.reason);
      sentryTraceError('received screen hangup', { error: { type: 'hangup', reason: data.data.reason } });
      if (data.data.reason === 'ice_failed') {
        if (this.screenPC && this.screenPC.wasConnected) {
          this.onError({ errorCode: 1412, errorMessage: 'too many ICE failures publishing screen' }, true);
        }
        else {
          this.onError({ errorCode: 1409, errorMessage: 'screen ICE failed' }, false);
        }
      }
      this.stopScreen();
      this.onScreenStop();
    }
    else {
      this.logger.debug('unhandled screen event', data);
    }
  }

  _onLocalaudioEvent(data: LocalAudioEvent) {
    if ((data.jsep) && (data.data.result.event === 'accepted')) {
      if (!this.audioPC) {
        this.logger.error('missing audio peer connection');
        return;
      }
      this._setRemoteSdp(this.audioPC.pc, data.jsep);
    }
    else if (data.data.hangup === 'ok') {
      this.logger.warning('received localaudio hangup with reason', data.data.reason);
      sentryTraceError('received localaudio hangup', { error: { type: 'hangup', reason: data.data.reason } });
      if (data.data.reason === 'ice_failed') {
        if (this.audioPC && this.audioPC.wasConnected) {
          this.onError({ errorCode: 1409, errorMessage: 'too many ICE failures publishing audio' }, false);
        }
        else {
          this.onError({ errorCode: 1411, errorMessage: 'audio ICE failed' }, false);
        }
        this.tearDown();
      }
    }
    else {
      this.logger.debug('unhandled localaudio event', data);
    }
  }

  _destroyRemotePeerConnection(feedId: number) {
    this._closeRemotePeerConnection(feedId);
    delete this.remoteVideoPCs[feedId];
  }

  _closeRemotePeerConnection(feedId: number) {
    const rpc = this.remoteVideoPCs[feedId];
    if (rpc && rpc.pc && rpc.pc.close) {
      sendStreamEvent(rpc.pc, "streamTerminated", this.getConferenceID());
      rpc.pc.close();
    }
  }

  _getUserIdFromMessage(msg: ServerMessage) {
    const data = msg.data;
    const userId = data.display;
    if (!userId) {
      return null;
    }
    if (userId && userId.endsWith('_screen')) {
      return { type: 'remotescreenevent', id: userId.replace(/_screen$/, '') };
    }
    else {
      return { type: (msg.type || 'remotevideoevent'), id: userId };
    }
  }

  _createRTCPeerConnection() {
    const constraints = {
      optional: [
        { googScreencastMinBitrate: 300 },
        { googCpuOveruseDetection: true },
        { googCpuOveruseEncodeUsage: true },
        { googCpuUnderuseThreshold: 55 },
        { googCpuOveruseThreshold: 85 },
        { googDscp: true }
      ]
    };
    const peerconnConfig = {
      iceServers: this.stunServers
    };

    try {
      return new this.rtc.RTCPeerConnection(peerconnConfig, constraints);
    }
    catch (e) {
      this.logger.error('Cannot create peer connection with constraints, trying without.', e);
      return new this.rtc.RTCPeerConnection(peerconnConfig);
    }
  }

  _onRemotevideoEvent(data: RemoteVideoEvent) {
    if (data.jsep) {
      const feedId = data.feed_id;
      const userId = this._getUserIdFromMessage(data);
      if (!(feedId && userId)) {
        this.logger.error('cannot find feedId or userId in event:', data, data.feed_id, data.data.display);
        return;
      }
      this._userIds[feedId] = userId;
      let rpc = (this.remoteVideoPCs[feedId] || { pc: null }).pc;
      if (rpc) {
        // if we already have a PC this is a resubscribe due to ICE failures
        this._closeRemotePeerConnection(feedId);
      }
      try {
        rpc = this._createRTCPeerConnection();
      }
      catch (e) {
        this.logger.error('cannot create remote video peer connection', e);
        sentryTraceError('create peer connection failed on remote video event', { error: e });
        this.onError({ errorCode: 1408, errorMessage: `${e.name}: ${e.message}` }, false);
        return;
      }
      let restartableRPC = this.remoteVideoPCs[feedId];
      if (!restartableRPC) {
        restartableRPC = new RestartablePC(
          rpc,
          { maxRetries: this.iceReconnectRetries, maxRetriesWindow: this.iceReconnectRetriesWindow },
          `remotevideo-${userId.id}`
        );
        restartableRPC.onRestartNeeded = () => {
          this.onError({ errorCode: 1414, errorMessage: 'ICE failure receiving video' }, true);
          return this.signalingServer().subscribeToWebrtcFeed(feedId, this.name);
        };
        restartableRPC.onFinalFailure = () => {
          sendStreamEvent(rpc, "streamTerminated", this.getConferenceID());
          this.onError({ errorCode: 9000, errorMessage: 'too many ICE failures receving video' }, true);
          this.logger.error('ICE failed too many times for video subscriber. Giving up');
          this._onRemoveStream(data, 'remotevideoevent');
        };
      }
      else {
        restartableRPC.setPc(rpc);
      }
      this.remoteVideoPCs[feedId] = restartableRPC;
      if (this.roomUID && this.userId) {
        statsAddStream(rpc, this.getConferenceID(), this.userId, userId.id,
          userId.type === 'remotescreenevent' ? 'screen' : 'video', "receiveonly");
      }
      rpc.onicecandidate = (ev) => this._onIceCandidate(rpc, ev, 'remotevideoevent', feedId);
      rpc.ontrack = (e) => {
        this._onAddStream(e, userId.type, userId.id);
      };
      const opts = {
        'offerToReceiveAudio': false,
        'offerToReceiveVideo': true,
      };
      rpc.setRemoteDescription(data.jsep).then(
        () => {
          this.logger.log("remote rsdp set");
          return rpc.createAnswer(opts).then(
            (sessDescr) => {
              rpc.setLocalDescription(sessDescr);
              return this.signalingServer().sendStartMessage(this.name, sessDescr.type, sessDescr.sdp, feedId);
            }
          ).catch(
            (e) => {
              this.logger.error(`error on createAnswer for remote video by user ${userId.id}`, e.code, e);
              this._onRemoveStream(data, 'remotevideoevent');
              if (e.code && e.code === 5004) {
                sentryTraceError('create answer failed on remote video event',
                  { error: { errorCode: 5004, exception: e } });
                this.onError({ errorCode: 5004, errorMessage: e.message }, true);
                return;
              }
              sentryTraceError('create answer failed on remote video event',
                { error: { errorCode: 9000, exception: e } });
              this.onError({ errorCode: 9000, errorMessage: e.message }, true);
            }
          );
        }
      ).catch(
        (err) => {
          this.logger.error("remote rsdp set error", err);
          this.logger.error("jsep is", data.jsep);
          sentryTraceError('set remote description failed on remote video event',
            { error: { jsep: data.jsep, exception: err } });
          this.onError({ errorCode: err.code, errorMessage: err.message }, false);
          this._onRemoveStream(data, 'remotevideoevent');
        }
      );
    }
    else if (data.data.unpublished) {
      this._onRemoveStream(data, 'remotevideoevent');
      this._onUnpublish(data);
    }
    else if ((data.data.paused === 'ok' || data.data.started === 'ok')) {
      // paused="ok" is muted, started="ok" is unmuted
      const muted = data.data.paused === 'ok';
      const feedId = data.feed_id;
      const userId = this._userIds[feedId];
      if (!userId) {
        this.logger.error(`cannot find userId for feed "${feedId}". Event is`, data);
        return;
      }
      this.onToggleVideoMute(userId.id, userId.type, muted);
    }
    else if (data.data.hangup === 'ok') {
      this.logger.warning('received remotevideo hangup with reason', data.data.reason, data);
      sentryTraceError('received remotevideo hangup', { error: { type: 'hangup', reason: data.data.reason } });
      this.onError({ errorCode: 9000, errorMessage: 'remote video server hangup' }, true);
    }
    else {
      this.logger.debug('ignoring remote event', data);
    }
  }

  _onVideoSipEvent(data: SipVideoEvent) {
    this.logger.debug('got sip video event', data);
    if (data.jsep) {
      const feedId = data.feed_id;
      const userId = this._userIds[feedId];
      if (!(feedId && userId)) {
        this.logger.error('cannot find feedId or userId for event:', data, data.feed_id);
        return;
      }
      let rpc: RTCPeerConnection;
      try {
        rpc = this._createRTCPeerConnection();
      }
      catch (e) {
        this.logger.error('cannot create remote video peer connection', e);
        sentryTraceError('create peer connection failed on sip video event', { error: e });
        this.onError({ errorCode: 1408, errorMessage: `${e.name}: ${e.message}` }, false);
        return;
      }
      if (this.roomUID && this.userId) {
        statsAddStream(rpc, this.getConferenceID(), this.userId, userId.id,
          userId.type === 'remotescreenevent' ? 'screen' : 'video', "receiveonly");
      }
      this.remoteVideoPCs[feedId] = new RestartablePC(
        rpc,
        { maxRetries: this.iceReconnectRetries, maxRetriesWindow: this.iceReconnectRetriesWindow },
        `remotevideo-${userId.id}`
      );
      rpc.onicecandidate = (ev) => this._onIceCandidate(rpc, ev, 'sip_remotevideoevent', feedId);
      rpc.ontrack = (e) => {
        this._onAddStream(e, userId.type, userId.id);
      };
      const opts = {
        'offerToReceiveAudio': false,
        'offerToReceiveVideo': true,
      };
      rpc.setRemoteDescription(data.jsep).then(
        () => {
          this.logger.log("remote rsdp set");
          return rpc.createAnswer(opts).then(
            (sessDescr) => {
              rpc.setLocalDescription(sessDescr);
              return this.signalingServer().sendStartMessage(this.name, sessDescr.type, sessDescr.sdp, feedId);
            }
          ).catch(
            (e) => {
              this.logger.error(`error on createAnswer on user ${userId.id}`, e.code, e);
              this._destroyRemotePeerConnection(feedId);
              if (e.code && e.code === 5004) {
                sentryTraceError('create answer failed on sip video event',
                  { error: { errorCode: 5004, exception: e } });
                this.onError({ errorCode: 5004, errorMessage: e.message }, true);
                return;
              }
              sentryTraceError('create answer failed on sip video event',
                { error: { errorCode: 9000, exception: e } });
              this.onError({ errorCode: 9000, errorMessage: e.message }, true);
            }
          );
        }
      ).catch(
        (err) => {
          this.logger.error("remote rsdp set error", err);
          this.logger.error("jsep is", data.jsep);
          sentryTraceError('set remote description failed on sip video event',
            { error: { jsep: data.jsep, exception: err } });
          this.onError({ errorCode: err.code, errorMessage: err.message }, false);
        }
      );
    }
    else if (['pausing', 'started'].includes((data.data.result || {}).status)) {
      // despite the name, 'pausing' is muted, 'starting' is unmuted
      const muted = (data.data.result || {}).status === 'pausing';
      const feedId = data.feed_id;
      const userId = this._userIds[feedId];
      if (!userId) {
        this.logger.error(`cannot find userId for feed "${feedId}". Event is`, data);
        return;
      }
      this.onToggleVideoMute(userId.id, userId.type, muted);
    }
    else {
      const feedId = data.data.id;
      const userId = this._getUserIdFromMessage(data);
      if (feedId && userId) {
        this._userIds[feedId] = userId;
        const publisher: Publisher = {
          id: feedId,
          display: userId.id,
          kind: 'sip',
        };
        this._onNewPublisher(publisher);
      }
      else {
        this.logger.warning('got sip video event without feedId or userId', data);
      }
    }
  }

  _onMeetingTimeEvents(type: 'duration' | 'expiring' | 'update', data: ConferenceTimeEvent) {
    this.onMeetingTimeEvents(type, data);
  }

  _setRemoteSdp(pc: RTCPeerConnection, sdp: RTCSessionDescription) {
    this.logger.debug('remote sdp is', sdp.sdp);
    if (!pc) {
      this.logger.error('trying to set sdp with no peer connection. Maybe room is going down?');
      return;
    }
    pc.setRemoteDescription(sdp).then(
      () => this.logger.log("rsdp set")
    ).catch(
      (err) => this.logger.error("rsdp set error", err)
    );
  }

  _subscribeToPublisher(publisher: Publisher) {
    if (publisher.id === this.screenFeedId) {
      // avoid subscribing to own screen sharing feed
      return;
    }
    switch (publisher.kind) {
      case 'sip':
        return this.signalingServer().watchVideoSipFeed(publisher.id);
      case 'webrtc':
        return this.signalingServer().subscribeToPublisher(
          publisher.id, this.name, publisher.display, publisher.audio_codec,
          publisher.video_codec
        );
    }
  }

  _onNewPublisher(publisher: Publisher) {
    if (publisher.id === this.screenFeedId) {
      // avoid notifying about own screen sharing feed
      return;
    }
    if (this.onNewPublisher) {
      this.onNewPublisher(publisher);
    }
  }

  _onUnpublish(evdata: RemoteVideoEvent) {
    this.onUnpublish(evdata.feed_id);
  }

}


export type EventCallback = (m: ServerMessage) => void


class EventSub {
  private _callbacks: { [id: string]: EventCallback };
  private _events: { [evtype: string]: string[] };

  constructor() {
    this._callbacks = {};
    this._events = {};
  }

  subscribe(ev: string, f: EventCallback) {
    const callbackId = uuid.v4();
    this._callbacks[callbackId] = f;
    let registeredCallbacks = this._events[ev] || [];
    registeredCallbacks = [...registeredCallbacks, callbackId];
    this._events[ev] = registeredCallbacks;
    return callbackId;
  }

  unsubscribe(ev: string, callbackId: string) {
    delete this._callbacks[callbackId];
    let registeredCallbacks = this._events[ev] || [];
    registeredCallbacks = registeredCallbacks.filter((el) => el !== callbackId);
    this._events[ev] = registeredCallbacks;
  }

  dispatch(ev: string, payload: ServerMessage) {
    const callbackIds = this._events[ev] || [];
    callbackIds.forEach((callbackId) => {
      const f = this._callbacks[callbackId];
      if (f) {
        return f(payload);
      }
    });
  }
}


class Api {
  private transport: Transport;
  private channel: null | Channel;
  private logger: LoggerInterface;
  private eventSubscriber: EventSub;

  constructor(transport: Transport, logger: LoggerInterface) {
    this.transport = transport;
    this.channel = null;
    this.logger = logger;
    this.eventSubscriber = new EventSub();
  }

  subscribe(ev: string, f: EventCallback) {
    return this.eventSubscriber.subscribe(ev, f);
  }

  unsubscribe(ev: string, id: string) {
    return this.eventSubscriber.unsubscribe(ev, id);
  }

  _onEvent(ev: string, payload: ServerMessage) {
    this.eventSubscriber.dispatch(ev, payload);
    return payload;
  }

  joinVideoRoom(room: string, _opts: {}, updateRosterCb: PresenceDispatchFunction): Promise<JoinedMessage | JoinError> {
    this.channel = null;
    const channel = this.transport.channel(room);
    if (!channel) {
      // this should not be possible, but make the compiler happy
      const res: JoinError = { code: 5004, type: 'error', reason: 'disconnected' };
      return Promise.reject(res);
    }
    onPresenceUpdate(channel, updateRosterCb);
    const p: Promise<JoinError | JoinedMessage> = new Promise((resolve, reject) => {
      channel.onMessage = (ev, payload, _ref) => this._onEvent(ev, payload);
      channel.join()
        .receive("ok", (args) => {
          channel.push("joinVideoRoom", { room: room })
            .receive("ok", () => {
              this.channel = channel;
              const res: JoinedMessage & { channel: Channel } = { channel, ...args };
              resolve(res);
            })
            .receive("error", ({ reason, payload, code }) => {
              reject({ type: "error", reason: reason, payload: payload, errorCode: code });
            })
            .receive("timeout", () => reject({ code: 5004, type: "timeout", reason: null }));
        }).receive("error", ({ reason, payload, code }) => {
          channel.leave();
          reject({ type: "error", reason: reason, payload: payload, errorCode: code });
        }).receive("timeout", () => {
          channel.leave();
          reject({ code: 5004, type: "timeout", reason: null });
        });
    });
    return p;
  }

  unpublishVideo() {
    this.logger.log('unpublishing video');
    return this._pushMessage("unpublishVideo", {});
  }

  unpublishScreen() {
    this.logger.log('unpublishing screen');
    return this._pushMessage("unpublishScreen", {}).catch(() => { });
  }

  leaveRoom() {
    this.logger.log('leaving videoroom');
    return this._pushMessage("leaveVideoRoom", {}).catch(() => { });
  }

  sendTrickleCandidate(room: string, candidate: JanusCandidate, type: StreamType, feedId?: number) {
    this.logger.log('sending trickle candidate', candidate);
    candidate.type = type;
    // eslint-disable-next-line @typescript-eslint/camelcase
    candidate.feed_id = feedId;
    return this._pushMessage("iceCandidate", { room: room, candidate: candidate });
  }

  doneGatheringTrickleCandidates(room: string, type: StreamType, feedId?: number) {
    this.logger.log('sending done trickle');
    const candidate = {
      completed: true,
      type: type,
      // eslint-disable-next-line @typescript-eslint/camelcase
      feed_id: feedId,
    };
    return this._pushMessage("iceCandidate", { room: room, candidate: candidate });
  }

  sendSdp(
    room: string,
    type: RTCSessionDescriptionInit['type'],
    sdp: RTCSessionDescriptionInit['sdp'],
    streamType: StreamType,
    streamOptions: { muted?: boolean; update?: boolean } = {}
  ) {
    this.logger.log('sending sdp');
    const jsep = {
      type: type,
      sdp: sdp,
    };
    if (streamType === 'localvideoevent') {
      return this._pushMessage('publishVideo', { room: room, request: 'configure', jsep: jsep });
    }
    else if (streamType === 'localaudioevent') {
      const msg = {
        room: room,
        jsep: jsep,
        muted: streamOptions.muted || false,
        update: streamOptions.update || false,
      };
      return this._pushMessage('publishAudio', msg);
    }
    else if (streamType === 'screen') {
      return this._pushMessage('publishScreen', { room: room, request: 'configure', jsep: jsep });
    }
    else {
      this.logger.warning(`unknown stream type "${streamType}"`);
    }
  }

  subscribeToPublisher(
    id: WebRTCPublisher['id'],
    room: string,
    _display: WebRTCPublisher['display'],
    _audioCodec: WebRTCPublisher['audio_codec'],
    _videoCodec: WebRTCPublisher['video_codec']
  ) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    return this._pushMessage("subscribeToPublisher", { room: room, feed_id: id });
  }

  subscribeToWebrtcFeed(id: WebRTCPublisher['id'], room: string) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    return this._pushMessage("subscribeToPublisher", { room: room, feed_id: id });
  }

  sendStartMessage(
    room: string,
    type: RTCSessionDescriptionInit['type'],
    sdp: RTCSessionDescriptionInit['sdp'],
    feedId: number
  ) {
    this.logger.log('sending sdp');
    const jsep = {
      type: type,
      sdp: sdp,
    };
    // eslint-disable-next-line @typescript-eslint/camelcase
    return this._pushMessage("startReceivingFeed", { room: room, jsep: jsep, feed_id: feedId });
  }

  watchVideoSipFeed(feedId: number) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    return this._pushMessage("watchVideoSipFeed", { feed_id: feedId });
  }

  toggleVideoMute(feedId: number, muted: boolean) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    return this._pushMessage(muted ? "muteVideoFeed" : "unMuteVideoFeed", { feed_id: feedId });
  }

  kickUser(user: string) {
    return this._pushMessage("kick", { user: user });
  }

  startDeskControl(user: string, desktopControlType: string) {
    return this._pushMessage("startDeskControl", { user, desktopControlType });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mouseEvent(userId: string, displayName: string, mouseEventType: any, mouseEventData: any) {
    return this._sendMessage("mouseEvent", { userId, displayName, mouseEventType, mouseEventData });
  }

  stopDeskControl(user: string) {
    return this._pushMessage("stopDeskControl", { user });
  }

  startDrawing() {
    return this._pushMessage("startDrawing", {});
  }

  stopDrawing() {
    return this._pushMessage("stopDrawing", {});
  }

  startControllingDesktop() {
    return this._pushMessage("startControllingDesktop", {});
  }

  stopControllingDesktop() {
    return this._pushMessage("stopControllingDesktop", {});
  }

  inviteParticipants(emails: string[], params: Params) {
    return this._pushMessage("inviteParticipants", { emails: emails, params: params });
  }

  dialParticipant(destination: string) {
    return this._pushMessage("dialParticipant", { destination: destination });
  }

  changeRole(user: string, role: string) {
    return this._pushMessage("changeRole", { user: user, role: role });
  }

  applyLayout(layout: State['room']['layout'], layoutConfig: State['room']['layoutConfig']) {
    return this._pushMessage("applyLayout", { layout: layout, layoutConfig: layoutConfig });
  }

  toggleRoomLock() {
    return this._pushMessage("toggleRoomLock", {});
  }

  startRecording(params: Params) {
    return this._pushMessage("startRecording", params);
  }

  stopRecording() {
    return this._pushMessage("stopRecording", {});
  }

  startLivestreaming(params: Params) {
    return this._pushMessage("startStreaming", params);
  }

  stopLivestreaming() {
    return this._pushMessage("stopStreaming", {});
  }

  toggleAudioMute(userId: string, muted: boolean) {
    return this._pushMessage(muted ? "muteAudio" : "unMuteAudio", { user: userId });
  }

  toggleOwnAudioMute(muted: boolean) {
    return this._pushMessage(muted ? "muteMyAudio" : "unMuteMyAudio", {});
  }

  muteAll() {
    return this._pushMessage("muteAllAudio", {});
  }

  unMuteAll() {
    return this._pushMessage("unMuteAllAudio", {});
  }

  endMeeting() {
    return this._pushMessage("endMeeting", {});
  }

  toggleRaiseHand() {
    return this._pushMessage("toggleRaiseHand", {});
  }

  lowerRaisedHand(user: string) {
    return this._pushMessage("lowerRaisedHand", { user });
  }

  extendMeeting() {
    return this._pushMessage("extendMeeting", {});
  }

  publishChatMessage(message: string, to: string) {
    const m: { message: string; user?: string } = { message: message };
    if (to) {
      m['user'] = to;
    }
    return this._pushMessage('publish', m);
  }

  acceptLockedJoinRequest(username: string, reqId: string) {
    return this._pushMessage("acceptLockedJoinRequest", { username: username, reqId: reqId });
  }

  denyLockedJoinRequest(username: string, reqId: string) {
    return this._pushMessage("denyLockedJoinRequest", { username: username, reqId: reqId });
  }

  _sendMessage(method: string, payload: RpcMessage) {
    if (!this.channel) {
      return Promise.reject({ code: 5004, type: 'error', reason: 'disconnected' });
    }
    return Promise.resolve(this.channel.push(method, payload));
  }

  _pushMessage(method: string, payload: RpcMessage) {
    const p = new Promise((resolve, reject) => {
      if (!this.channel) {
        reject({ code: 5004, type: 'error', reason: 'disconnected' });
        return;
      }
      this.channel.push(method, payload)
        .receive("ok", (res) => {
          resolve(res);
        })
        .receive("error", (err) => {
          sentryTraceError('ws push message error', { error: err });
          reject(err);
        })
        .receive("timeout", () => {
          sentryTraceError('ws push timeout');
          reject({ type: "timeout", code: 5004, reason: null });
        });
    });
    return p;
  }
}

class Transport {
  private endpoint: string;
  private token: string;
  private socketConstructor: typeof Socket;
  private socket: null | Socket;

  constructor(endpoint: string, token: string, socketConstructor = Socket) {
    this.endpoint = endpoint;
    this.token = token;
    this.socketConstructor = socketConstructor;
    this.socket = null;
  }

  connect() {
    // const logger = getLogger('Websocket');
    const opts = {
      params: { token: this.token },
      // logger: ((kind, msg, data) => logger.info(`${kind}: ${msg}`, data)) as SocketConnectOption['logger'],
      heartbeatIntervalMs: 60000,
    };
    if (!this.socket) {
      this.socket = new this.socketConstructor(this.endpoint, opts);
    }
    this.socket.connect();
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.socket!.onError((event) => {
        sentryTraceError('socket connect error', { error: event });
        reject(event);
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.socket!.onClose((event) => {
        traceWSCloseEvent(event);
        resolve();
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.socket!.onOpen(() => resolve());
    });
  }

  channel(name: string, payload?: object) {
    if (this.socket) {
      return this.socket.channel(name, payload);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.socket = null;
  }
}


export { VideoRoom, Api, Transport, EventSub };
