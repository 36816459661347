/* eslint @typescript-eslint/camelcase: 0 */

import update from 'immutability-helper';

import { Channel } from 'phoenix';

import {
  SOCKET_CONNECT,
  SOCKET_CONNECTING,
  SOCKET_DISCONNECT,
  SOCKET_FAILURE,
  JOIN_SUCCESS,
  JOIN_FAILURE,
  JOIN_REQUEST,
  LEAVE_SUCCESS,
  SET_ROOM_ROLE,
  RESET_JOIN_FAILURE,
} from '../actions/websocket';

import { MEETING_UPDATED } from '../actions/room';


export interface Organizer {
  username: string | null;
  display: string | null;
}


export interface Attendee {
  email: string;
}


export interface Meeting {
  title: string;
  notes: string;
  dt_start: string;
  dt_end: string;
  slug: string;
  attendees: Attendee[];
  number: string;
  room_type: string;
  type: "audio_only" | "regular" | "lesson" | "webinar";
  publish_stream_url: null | string;
  organizer: Organizer;
}


export interface State {
  isConnected: boolean;
  isConnecting: boolean;
  token: null | string;
  errorCode: null | number;
  errorMessage: null | string;
  errorPayload: null | Meeting;
  room: null | Channel;
  room_name: null | string;
  room_roles: string[];
  leaveReason: null | string;
  uid: null | string;
  room_uid: null | string;
  joinFailure: boolean;
  meeting_details: Meeting | null;
}


const defaultState = {
  isConnected: false,
  isConnecting: false,
  token: null,
  errorCode: null,
  errorMessage: null,
  errorPayload: null,
  room: null,
  room_name: null,
  room_roles: [],
  leaveReason: null,
  uid: null,
  room_uid: null,
  joinFailure: false,
  meeting_details: null,
};


// FIXME: remove any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function websocket(state: State = defaultState, action: any = {}) {
  switch (action.type) {
    case SOCKET_CONNECTING:
      return Object.assign({}, state, {
        isConnected: false,
        isConnecting: true,
      });
    case SOCKET_CONNECT:
      return Object.assign({}, state, {
        isConnected: true,
        isConnecting: false,
        token: action.payload.token,
        apiserver: action.payload.apiserver,
        waitingroomapiserver: action.payload.waitingroomapiserver,
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: null,
        room_name: null,
        room_uid: null,
      });
    case SOCKET_DISCONNECT:
      return Object.assign({}, state, {
        isConnected: false,
        isConnecting: false,
        token: null,
        apiserver: null,
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
      });
    case SOCKET_FAILURE:
      return Object.assign({}, state, {
        isConnected: false,
        isConnecting: false,
        token: null,
        apiserver: null,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
      });
    case JOIN_SUCCESS:
      return Object.assign({}, state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: action.payload.room,
        room_name: action.payload.room_name,
        room_roles: action.payload.room_roles,
        meeting_details: action.payload.meeting_details,
        room_uid: action.payload.room_uid,
        leaveReason: null,
        uid: action.payload.uid,
      });
    case JOIN_FAILURE:
      return Object.assign({}, state, {
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        errorPayload: action.payload.errorPayload,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
        joinFailure: true,
        meeting_details: null,
      });
    case LEAVE_SUCCESS:
      return Object.assign({}, state, {
        room: null,
        room_name: null,
        room_roles: [],
        leaveReason: action.payload.reason,
        meeting_details: null,
        uid: null,
        room_uid: null,
      });
    case JOIN_REQUEST:
      return Object.assign({}, state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
        leaveReason: null,
        joinFailure: false,
      });
    case SET_ROOM_ROLE:
      return Object.assign({}, state, {
        room_roles: [action.payload.role],
      });
    case RESET_JOIN_FAILURE:
      return Object.assign({}, state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        joinFailure: false,
      });
    case MEETING_UPDATED: {
      return update(state, {
        meeting_details: {
          $merge: action.payload.meeting,
        }
      });
    }
    default:
      return state;
  }
}


export default websocket;
export { defaultState };
