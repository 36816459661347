
/* Libraries Imports */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
/* Components Imports */
/* Other imports */
import IntlPropType from '../../intl';
/* Actions Imports */
/* Local Style */
import style from './style';
import { getNotificationLevel, NotificationLevel } from './notificationLevels';


class NotificationArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      notification: null
    };
  }

  static getNotifications(props, _state) {
    const entry = props.notifications.slice(-1)[0];
    if (!entry) {
      return { open: false, notification: null };
    }

    return {
      open: true,
      notification: entry.notification,
      key: entry.ref
    };

  }

  static getDerivedStateFromProps(props, state) {
    const new_state = NotificationArea.getNotifications(props, state);
    return Object.assign(state, new_state);
  }

  getMessage(notification) {
    if (!notification) { return null; }

    const message = this.props.intl.formatMessage({
      id: notification.code || notification.message || 'defaultNotificationError',
      defaultMessage: notification.message
    }, notification.payload || {});

    if (message) {
      return message;
    } else {
      return notification.message;
    }
  }

  getSeverity(notification) {
    return (notification && notification.prio && notification.prio.description)
      ? getNotificationLevel(notification.prio.description)
      : NotificationLevel.INFO.toLowerCase();
  }

  render() {
    //const classes = this.props.classes;
    if (this.props.isRecorder) {
      return null;
    }

    const notification = this.state.notification;

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          key={this.state.key}
          open={this.state.open}
        >
          <Alert severity={this.getSeverity(notification)}>
            {this.getMessage(notification)}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

NotificationArea.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: IntlPropType.isRequired,
  notifications: PropTypes.array,
  isRecorder: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications || [],
    isRecorder: state.session.isRecorder || false,
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(NotificationArea)));
