/* Libraries Imports */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
/* UI Imports */
/* Components Imports */
import StandardLogin from './StandardLogin';
import OAuthLogin from './OAuthLogin';
/* Actions Imports */
/* Other Imports */


class Login extends Component {

  getStandardLogin() {
    return (
      <StandardLogin
        history={this.props.history}
        localStore={this.props.localStore}
      />
    );
  }

  getOAuthLogin() {
    return (
      <OAuthLogin
        history={this.props.history}
        localStore={this.props.localStore}
      />
    );
  }

  getLoginDialog() {
    const oauth = this.props.oauth;

    if ((oauth || []).length) {
      return this.getOAuthLogin();
    } else {
      return this.getStandardLogin();
    }

  }

  render() {
    return this.getLoginDialog();
  }
}


Login.propTypes = {
  oauth: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
};


export default withRouter(Login);
