import React from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Menu from "@material-ui/core/Menu";
import { PopoverProps } from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import SubMenu from "./SubMenu";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      backgroundColor: theme.palette.background.default
    },
    caption: {
      display: "flex",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flexGrow: 4
    }
  }),
);


export interface CascadingMenuItem {
  caption: string;
  key: string;
  onClick?: () => void;
  subMenuItems?: Array<CascadingMenuItem> | undefined;
  icon?: React.ReactNode;
  show?: boolean;
}

type PropsType = {
  menuItems: Array<CascadingMenuItem> | undefined;
  onClose: () => void;
  open: boolean;
} & PopoverProps

function RenderMenuItems(menuItems: Array<CascadingMenuItem> | undefined) {
  const classes = useStyles();

  if (menuItems === undefined) {
    return null;
  }
  else {
    return menuItems.map((menuItem: CascadingMenuItem) => {
      if (Object.prototype.hasOwnProperty.call(menuItem, "subMenuItems") && menuItem.subMenuItems) {

        const WrappedSubMenuWithForwardedRef = React.forwardRef((_props, _ref) => {
          return <SubMenu
            key={menuItem.key}
            caption={menuItem.caption}
            icon={menuItem.icon}
            menuItems={menuItem.subMenuItems}
          />;
        });
        WrappedSubMenuWithForwardedRef.displayName = "WrappedSubMenuWithForwardedRef";
        return (
          <WrappedSubMenuWithForwardedRef key={menuItem.key} />
        );
      }

      return (
        (menuItem.show === true || menuItem.show === undefined) &&
        <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
          {menuItem.icon}
          <Typography variant='body2' className={classes.caption}>
            {menuItem.caption}
          </Typography>
        </MenuItem>
      );
    });
  }
}

function CascadingMenu(props: PropsType) {
  const { anchorEl, open, onClose, menuItems, ...others } = props;
  const classes = useStyles();
  return (
    <Menu MenuListProps={{ className: classes.menu }}
      {...others}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {RenderMenuItems(menuItems)}
    </Menu>
  );
}
export default CascadingMenu;
