import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../lib/reducers';
import { WithAuthorization } from '../../authorization';
import { useIntl, defineMessages } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { setLockedJoinRequestDialogMinimized } from '../../lib/actions/notifications';
import Badge from '@material-ui/core/Badge';
import { IconUserWait } from '../IconSet';

const messages = defineMessages({
  showLockedJoinRequests: { id: 'showLockedJoinRequests' },
});


type MappedProps = {
  isLocked: boolean;
  lockedJoinRequests: State['notifications']['lockedJoinRequests'];
  isDialogMinimized: boolean;
}

const mapStateToProps = (state: State): MappedProps => ({
  isLocked: state.room.isLocked,
  lockedJoinRequests: state.notifications.lockedJoinRequests,
  isDialogMinimized: state.notifications.lockedJoinRequestDialogMinimized,
});

type Props = {
} & MappedProps

function LockedJoinRequestsButton(props: Props) {
  const { formatMessage } = useIntl();
  const { isLocked, lockedJoinRequests, isDialogMinimized } = props;
  const dispatch = useDispatch();

  const showRequests = (): void => {
    dispatch(setLockedJoinRequestDialogMinimized(!isDialogMinimized));
  };
  const msg = formatMessage(messages.showLockedJoinRequests);

  const requestCount = lockedJoinRequests.length;

  // requires fixing because the tooltip won't hide on dialog show and icon change
  // const getIcon = () => {
  //   return (
  //     <React.Fragment>
  //       {isDialogMinimized
  //         ? <IconUserWait size={28} />
  //         : <IconUserWaitSelected size={28} />
  //       }
  //     </React.Fragment>
  //   );
  // };

  if (isLocked && requestCount > 0) {
    return (
      <React.Fragment>
        <ListItem button >
          <Tooltip
            placement="left"
            title={msg}>
            <div onClick={showRequests}>
              <ListItemIcon>
                <Badge badgeContent={requestCount} color="secondary">
                  <IconUserWait size={28} />
                </Badge>
              </ListItemIcon>
            </div>
          </Tooltip>
        </ListItem>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, connect(mapStateToProps)(LockedJoinRequestsButton));
