import { VideoRoom } from '../api/videoroom';

import { State } from '../reducers';


export function getSelectedStream(state: State) {
  const layoutConfig = state.room.layoutConfig;
  if (layoutConfig.featured_type && layoutConfig.featured_id) {
    if (layoutConfig.featured_type === 'screen') {
      return `${layoutConfig.featured_id}_screen`;
    }
    else {
      return layoutConfig.featured_id;
    }
  }
  return null;
}


export function areSecondaryVideosHidden(state: State) {
  const layoutConfig = state.room.layoutConfig;
  if (layoutConfig.enlarge_video) {
    return true;
  }
  return false;
}


export function findRoomOwner(state: State) {
  const filterByOwnerRole = Object.keys(state.room.roster).filter(
    (uid) => {
      return state.room.roster[uid].role === 'room_owner';
    }
  );
  return filterByOwnerRole[0] || null;
}


export function hasScreen(state: State, uid: string) {
  if (uid === state.websocket.uid) {
    return Boolean(state.room.screenStream);
  }
  else {
    const user = state.room.roster[uid];
    return Boolean(user && user.screen);
  }
}


export function hasStream(state: State, uid: string) {
  if (uid === state.websocket.uid) {
    const stream = state.room.localvideo_stream;
    return Boolean(stream && VideoRoom.getVideoTrackFromStream(stream));
  }
  else {
    const user = state.room.roster[uid];
    return Boolean(user && user.stream && VideoRoom.getVideoTrackFromStream(user.stream));
  }
}


export function findSelectedStream(state: State, hasVideoStream: boolean) {
  const myUserId = state.websocket.uid;

  const layoutConfig = state.room.layoutConfig;
  let selectedStream = null;
  let myStreamIsSelected = false;
  let myScreenIsSelected = false;
  if (layoutConfig.featured_type && layoutConfig.featured_id) {
    if (layoutConfig.featured_type === 'screen') {
      if (layoutConfig.featured_id === myUserId && state.room.screenStream) {
        myScreenIsSelected = true;
        selectedStream = `${myUserId}_screen`;
      }
      else {
        const selectedUser = state.room.roster[layoutConfig.featured_id];
        if (selectedUser && selectedUser.screen) {
          selectedStream = `${layoutConfig.featured_id}_screen`;
        }
      }
    }
    else {
      if (layoutConfig.featured_id === myUserId && hasVideoStream) {
        myStreamIsSelected = true;
        selectedStream = myUserId;
      }
      else {
        const selectedUser = state.room.roster[layoutConfig.featured_id];
        if (selectedUser && selectedUser.stream && VideoRoom.getVideoTrackFromStream(selectedUser.stream)) {
          selectedStream = layoutConfig.featured_id;
        }
      }
    }
  }

  return { selectedStream, myStreamIsSelected, myScreenIsSelected };
}


