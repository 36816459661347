import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IntlPropType from '../../intl';


const messages = defineMessages({
  cancel: { id: 'cancel' },
  ok: { id: 'ok' },
  errorTitle: { id: 'streamErrorTitle' },
  errorContent: { id: 'streamErrorContent' },
});


class DeviceError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.deviceError || false,
    };
    this.close = this.close.bind(this);
  }

  close() {
    this.setState({ open: false });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.deviceError && this.props.deviceError) {
      this.setState({ open: true });
    }
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.errorTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.intl.formatMessage(messages.errorContent)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.close}
            variant="contained"
            color="primary"
          >
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


DeviceError.propTypes = {
  intl: IntlPropType.isRequired,
  deviceError: PropTypes.string,
};


function mapStateToProps(state) {
  return {
    deviceError: state.room.streamError,
  };
}


export default injectIntl(connect(mapStateToProps)(DeviceError));
