import React from 'react';

import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { IconVideo, IconVideoOff } from '../IconSet';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import messages from './buttonsMessages';

import { State as RoomState } from '../../lib/reducers/room';
import { State as WebsocketState } from '../../lib/reducers/websocket';
import { togglePublishVideo } from '../../lib/actions/room';
import { VideoRoom } from '../../lib/api/videoroom';
import { iconColors as colors } from '../../colors';


function VideoButton(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const muteVideo = React.useCallback(
    () => {
      if (props.hasVideoStream && props.canPublishVideo) {
        dispatch(togglePublishVideo(!props.isVideoMuted));
      }
    }
    , [dispatch, props.isVideoMuted, props.hasVideoStream, props.canPublishVideo]
  );

  const label = props.isVideoMuted ? formatMessage(messages.enable) : formatMessage(messages.disable);
  const selected = props.isVideoMuted ? classes.buttonsSelected : null;
  const cls = classNames(classes.buttons, selected);

  if (props.hasVideoStream && props.canPublishVideo) {
    return (
      <ToolbarButton
        icon={props.isVideoMuted
          ? <IconVideoOff color={colors.contrast} size={28} />
          : <IconVideo size={28} />
        }
        text={label}
        buttonProps={{ className: cls, onClick: muteVideo }}
      />
    );
  }
  else {
    return null;
  }
}


type State = {
  room: RoomState;
  websocket: WebsocketState;
}


type MappedProps = {
  isVideoMuted: boolean;
  hasVideoStream: boolean;
  canPublishVideo: boolean;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const user = state.websocket.uid;
  let isVideoMuted = false;
  if (user) {
    isVideoMuted = (state.room.roster[user] || { isVideoMuted: false }).isVideoMuted;
  }
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }
  return {
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
    isVideoMuted,
    hasVideoStream
  };
};


export default connect(mapStateToProps)(VideoButton);
