import { commitMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';
import { ConnectionHandler } from 'relay-runtime';
import {
  updateMeetingAsMutation,
  updateMeetingAsMutationResponse
} from './__generated__/updateMeetingAsMutation.graphql';

import { MeetingParams } from './updateMeeting';
import { handleSchedulingError } from './utils/errors';


const mutation = graphql`
mutation updateMeetingAsMutation(
  $input: UpdateMeetingAsInput!
) {
  updateMeetingAs(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title,
      dtStart,
      dtEnd,
      attendees {
        email
      },
      notes
    }
  }
}
`;


function maybeNotifyError(response: updateMeetingAsMutationResponse | null) {
  if (!response || !response.updateMeetingAs || response.updateMeetingAs.errors !== null) {
    handleSchedulingError(response ? response.updateMeetingAs : null);
  }
}


function updateMeetingAs(
  environment: IEnvironment,
  { id, title, userId, domainId, dtStart, dtEnd, users, notes, type, meta }: MeetingParams
) {
  const attendees = users.value.map((el: string) => {
    return ({ email: el });
  });
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          id,
          title,
          userId,
          domainId,
          dtStart,
          dtEnd,
          attendees,
          notes,
          type,
          meta
        }
      },
    };
    commitMutation<updateMeetingAsMutation>(
      environment,
      {
        mutation,
        variables,
        updater: (store) => {
          const payload = store.getRootField("updateMeetingAs");

          const model = payload.getLinkedRecord("meeting");
          const meet = { id: 'client:root' };
          const parent = store.get(meet.id);
          if (parent) {
            const connection = ConnectionHandler.getConnection(parent, "data_myMeetings");
            if (connection) {
              // a date can be updated, need to refresh connection from scratch to preserve order...
              ConnectionHandler.insertEdgeAfter(connection, model);
            }
          }
        },
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}


export { updateMeetingAs };
