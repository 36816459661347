import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { State as RelayState } from '../../lib/reducers/relay';
import { RELAY_DONE } from '../../lib/actions/relay';
import { DELETE_MEETING, SCHEDULE_MEETING } from '../../lib/utils/relayOperation';
import styles from './styles';
import Week from './Week';
import DayNames from './DayNames';
import { DayData } from './Day';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
//import Paper from '@material-ui/core/Paper';
import { IconChevronLeft, IconChevronRight } from '../IconSet';
import classNames from 'classnames';
import { Meeting } from '../MeetingsBoard/View';

interface CalendarProps {
  meetings: Array<Meeting>;
  onDayClick: (meetings: Array<Meeting>, refresh: boolean, selectedDay: moment.Moment | null) => void;
  onMonthClick: (selectedMonth: moment.Moment) => void;
}

type MappedState = {
  relay: RelayState;
}

type MappedProps = {
  relayEvents: RelayState;
}

const mapStateToProps = (state: MappedState): MappedProps => ({
  relayEvents: state.relay,
});

export type CalendarPropsType = CalendarProps & MappedProps & WithStyles<typeof styles>;

export interface Event {
  date: moment.Moment;
  meeting: Meeting;
}

interface State {
  selectedMonth: moment.Moment;
  selectedDay: moment.Moment | null;
  selectedMonthEvents: Array<Event>;
}

class Calendar extends React.Component<CalendarPropsType, State> {
  constructor(props: CalendarPropsType) {
    super(props);

    this.state = {
      selectedMonth: moment(),
      selectedDay: null,
      selectedMonthEvents: []
    };

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    //this.showCalendar = this.showCalendar.bind(this);
    this.goToCurrentMonthView = this.goToCurrentMonthView.bind(this);

  }

  componentDidMount() {
    this.initialiseEvents();
  }

  previous() {
    const currentMonthView = this.state.selectedMonth;
    const newMonth = currentMonthView.subtract(1, "month");

    this.setState({
      selectedMonth: newMonth,
      selectedDay: null
    });
    this.props.onMonthClick(newMonth);
  }

  next() {
    const currentMonthView = this.state.selectedMonth;
    const newMonth = currentMonthView.add(1, "month");
    this.setState({
      selectedMonth: newMonth,
      selectedDay: null
    });
    this.props.onMonthClick(newMonth);
  }

  goToCurrentMonthView() {
    this.setState({
      selectedMonth: moment()
    });
  }

  /*showCalendar() {
    this.setState({
      selectedMonth: this.state.selectedMonth,
      selectedDay: this.state.selectedDay
    });
  }*/

  renderMonthLabel() {
    const classes = this.props.classes;
    const currentMonthView = this.state.selectedMonth;
    return (
      <span className={classNames(classes.monthLabel)}>
        {currentMonthView.format("MMMM YYYY")}
      </span>
    );
  }

  onSelectDay = (day: DayData) => {
    let alreadySelected = false;
    if (this.state.selectedDay !== null) {
      alreadySelected = this.state.selectedDay.isSame(day.date, "day");
    }

    if (alreadySelected === false) {
      const selectedDay = day.date.clone();
      this.setState({
        selectedMonth: day.date,
        selectedDay: selectedDay,
      });
      const meetings: Array<Meeting> = day.events.map(function(event: Event) {
        return event.meeting;
      });

      this.props.onDayClick(meetings, false, selectedDay);
    }
    else {
      this.setState({
        selectedMonth: day.date,
        selectedDay: null,
      });
      this.props.onDayClick([], true, null);
    }

  }


  renderWeeks() {
    const currentMonthView = this.state.selectedMonth;
    const currentSelectedDay = this.state.selectedDay;
    const monthEvents = this.state.selectedMonthEvents;
    const weeks = [];
    let done = false;
    const previousCurrentNextView = currentMonthView
      .clone()
      .startOf("month")
      .subtract(1, "d")
      //.weekday(0);
      .isoWeekday(1);
    //.day("Monday");
    let count = 0;
    let monthIndex = previousCurrentNextView.month();
    let countWeeks = 0;

    while (!done) {
      weeks.push(
        <Week
          key={countWeeks}
          previousCurrentNextView={previousCurrentNextView.clone()}
          currentMonthView={currentMonthView}
          monthEvents={monthEvents}
          selected={currentSelectedDay}
          select={this.onSelectDay}
        />
      );
      previousCurrentNextView.add(1, "w");
      done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
      monthIndex = previousCurrentNextView.month();
      countWeeks = countWeeks + 1;
    }
    return weeks;
  }

  initialiseEvents() {

    const allEvents = [];

    if (this.props.meetings) {
      for (let z = 0; z < this.props.meetings.length; z++) {
        const meeting: Meeting = this.props.meetings[z];
        const event: Event = { date: moment(meeting.dtStart), meeting: meeting };
        allEvents.push(event);
      }
    }

    this.setState({
      selectedMonthEvents: allEvents
    });
  }

  checkRelayOps = (prevProps: CalendarPropsType, props: CalendarPropsType) => {
    const prevRelayOp = prevProps.relayEvents || {};
    const relayOp = props.relayEvents || {};
    const triggerOps = [SCHEDULE_MEETING, DELETE_MEETING];

    if (relayOp.type === RELAY_DONE && prevRelayOp.type !== relayOp.type
      && triggerOps.indexOf(relayOp.operation) >= 0) {
      this.setState({ selectedDay: null });
    }
  }

  componentDidUpdate = (prevProps: CalendarPropsType) => {
    if (this.props.meetings !== prevProps.meetings && this.props.meetings) {
      const allEvents = [];
      for (let z = 0; z < this.props.meetings.length; z++) {
        const meeting: Meeting = this.props.meetings[z];
        const event: Event = { date: moment(meeting.dtStart), meeting: meeting };
        allEvents.push(event);
      }
      this.setState({
        selectedMonthEvents: allEvents,
        //selectedMonth: moment(),
        //selectedDay: moment().startOf("day"),
      });
    }
    this.checkRelayOps(prevProps, this.props);
  }

  render() {
    const classes = this.props.classes;

    return (
      <Fragment>
        <div className={classes.calendarRectangle}>
          <section className={classes.mainCalendar}>
            <header className={classes.calendarHeader}>
              <div className={classNames(classes.row, classes.titleHeader)}>
                <div className={classNames(classes.box, classes.headerText)}>
                  {this.renderMonthLabel()}
                </div>
                <IconButton size="small" color="primary" onClick={this.previous}
                  className={classNames(classes.fab, classes.arrow)}>
                  <IconChevronLeft size={24} color="white" />
                </IconButton>
                <IconButton size="small" color="primary" onClick={this.next}
                  className={classNames(classes.fab, classes.arrow)}>
                  <IconChevronRight size={24} color="white" />
                </IconButton>

              </div>
              <DayNames />
            </header>
            <div className={classes.daysContainer}>
              {this.renderWeeks()}
            </div>

          </section>
        </div>

      </Fragment>
    );
  }

}


export default withStyles(styles)(connect(mapStateToProps)(Calendar));
