import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import clsx from 'clsx';
import sizeMe, { SizeMeOptions, SizeMeProps } from 'react-sizeme';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

import HideOnMouseOut from '../../HideOnMouseOut';
import {
  IconMicrophoneOff,
  // IconMinimize,
  IconModerator,
  IconMicrophone,
  IconVideoOff,
  IconVideo,
} from '../../IconSet';
import { shouldUseMiniToolbar } from '../../../lib/utils/videoDimensions';

import { mapStateToProps, MappedProps } from './mapState';
import VideoQualityInfo from './VideoQualityInfo';
import MainActions from './MainActions';
import SecondaryActions from './SecondaryActions';
import MoreButton from './MoreButton';
import { iconColors as colors } from '../../../colors';


const messages = defineMessages({
  screenVideoDisplayName: { id: 'screenVideoDisplayName' },
  moderatorIconTooltip: { id: 'presentationIconTooltip' },
  microphoneDisabledTooltip: { id: 'microphoneDisabledTooltip' },
  microphoneActiveTooltip: { id: 'microphoneActiveTooltip' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
      height: '100%',
      userSelect: 'none',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    displayName: {
      alignSelf: 'center',
      color: theme.palette.primary.contrastText,
    },
    topToolbar: {
      padding: theme.spacing(2),
      display: 'flex',
      alignSelf: 'flex-end'
    },
    topToolbarRegular: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    topToolbarMini: {
      padding: theme.spacing(1, 1, 0, 1),
      // zIndex is appbar's zIndex - 1
      zIndex: theme.zIndex.modal - 2
    },
    centeredToolbar: {
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    bottomToolbar: {
      display: 'flex',
      padding: 0,
      background: 'linear-gradient(to top,rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0))',
      width: '100%',
      justifyContent: 'flex-end'
    },
    bottomToolbarMini: {
      minHeight: 0,
    },
    infoButton: {
      margin: '1ex',
    },
    controls: {
      marginLeft: theme.spacing(1),
    },
    centeredControls: {
      marginLeft: theme.spacing(1),
      background: 'rgba(0, 0, 0, 0.3)',
      backdropFilter: 'blur(2px)',
      width: 48,
      height: 48,
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.5)',
      }
    },
    rounded: {
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    hidden: {
      visibility: 'hidden'
    }
  }),
);


function Controls(props: AdditionalProps) {
  const classes = useStyles();
  const [canClose, setCanClose] = React.useState<boolean>(true);

  const { uid, centerControls, size: { width } } = props;

  const useMini = shouldUseMiniToolbar(width);

  const handleMenu = (canClose: boolean) => {
    setCanClose(canClose);
  };

  const toolbar = centerControls ? classes.centeredToolbar : classes.topToolbar;
  const hidden = (!props.show && canClose) ? classes.hidden : null;
  const padding = useMini ? classes.topToolbarMini : classes.topToolbarRegular;
  const cls = clsx(toolbar, hidden, padding);
  const centeredCls = centerControls ? classes.centeredControls : classes.controls;
  const color = centerControls ? 'rgb(233, 233, 233)' : 'black';
  const size = centerControls ? 36 : (useMini ? 16 : undefined);  // undefined will use theme default

  return (
    <div className={cls}>
      <SecondaryActions iconSize={size} uid={uid} mini={useMini} />
      { !useMini &&
        <MainActions iconColor={color} iconSize={size} cName={centeredCls} uid={uid} mini={useMini} />
      }
      <MoreButton
        canClose={handleMenu}
        iconColor={color}
        iconSize={size}
        cName={centeredCls}
        user={uid}
        mini={useMini}
        uid={uid} />
    </div>
  );
}


function Info(props: AdditionalProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { width } = props.size;

  const useMini = shouldUseMiniToolbar(width);
  const hide  = !props.show && !props.isVideoMuted;

  const { rounded } = props;

  const { isAudioMuted, isVideoMuted, isModerator, uid, displayName, isScreen } = props;

  const name = isScreen
    ? formatMessage(messages.screenVideoDisplayName, { name: displayName })
    : displayName;

  const toolbar = rounded ? clsx(classes.bottomToolbar, classes.rounded) : classes.bottomToolbar;
  const hidden = hide ? classes.hidden : null;
  const mini = useMini ? classes.bottomToolbarMini : null;
  const cls = clsx(toolbar, hidden, mini);

  const textVariant = useMini ? 'caption' : 'subtitle1';
  const audioTipText = formatMessage(isAudioMuted
    ? messages.microphoneDisabledTooltip
    : messages.microphoneActiveTooltip
  );
  const videoTipText = formatMessage(isVideoMuted
    ? messages.microphoneDisabledTooltip
    : messages.microphoneActiveTooltip
  );

  return (
    <Toolbar className={cls} variant='dense' >
      <Typography variant={textVariant} className={classes.displayName}>
        {name}
      </Typography>
      <div className={classes.infoButton}>
        <Tooltip placement="top" title={audioTipText}>
          <div>
            {isAudioMuted
              ? <IconMicrophoneOff color={colors.inactive} size={16} />
              : <IconMicrophone color={colors.active} size={16} />
            }
          </div>
        </Tooltip>
      </div>
      <div className={classes.infoButton}>
        <Tooltip placement="top" title={videoTipText}>
          <div>
            {isVideoMuted
              ? <IconVideoOff color={colors.inactive} size={16} />
              : <IconVideo color={colors.active} size={16} />
            }
          </div>
        </Tooltip>
      </div>
      {isModerator ?
        <div className={classes.infoButton}>
          <Tooltip placement="top" title={formatMessage(messages.moderatorIconTooltip)}>
            <div>
              <IconModerator color={colors.contrast} size={16} />
            </div>
          </Tooltip>
        </div>
        : null
      }
      <div className={classes.infoButton}>
        <VideoQualityInfo iconSize={16} user={uid} />
      </div>
    </Toolbar>
  );
}


function Wrapper(props: AdditionalProps) {
  const classes = useStyles();
  return (
    <div className={classes.toolbar}>
      <Controls {...props} />
      <Info {...props} />
    </div>
  );
}


function VideoToolbar(props: ExtendedProps) {
  return (
    <HideOnMouseOut>
      <Wrapper {...props} />
    </HideOnMouseOut>
  );
}


type Props = {
  uid: string;
  rounded?: boolean;
  centerControls?: boolean;
}


type ExtendedProps = Props & MappedProps & SizeMeProps
type AdditionalProps = ExtendedProps & { show?: boolean }

const options: SizeMeOptions = {
  monitorWidth: true,
  refreshMode: 'debounce',
  refreshRate: 256,
};

export default connect(mapStateToProps)(sizeMe(options)(VideoToolbar));
