/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { ReactRelayContext } from 'relay-hooks';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './style';
/* Components Imports */
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListAdder from '../ListAdder';
/* Other imports */
import ClosableDialog from '../ClosableDialog';
import IntlPropType from '../../intl';
import { isEmail } from '../../lib/utils';
import { newEvent, ERROR } from '../../lib/notifications';
import { inviteParticipants } from '../../lib/actions/room';
import { inMeeting } from '../../lib/reduxSelectors/videoRoom';
import { inviteToPersonalRoom } from '../../lib/api/relay/inviteToMeeting';
import { getLogger } from '../../lib/logger';


const messages = defineMessages({
  ok: { id: 'ok' },
  cancel: { id: 'cancel' },
  email: { id: 'email' },
  title: { id: 'inviteNewParticipantDialogTitle' },
  content: { id: 'inviteNewParticipantDialogContent' },
  emailError: { id: 'inviteNewParticipantDialogValidationError' },
});


class InviteEmailDialog extends Component {

  static contextType = ReactRelayContext;

  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
    this.logger = getLogger('InviteByEmail');
  }

  onItemAdd = (item) => {
    const newItems = [item, ...this.state.items];
    this.setState({ items: newItems });
  }

  onItemsAdd = (items) => {
    const newItems = [...this.state.items, ...items];
    this.setState({ items: newItems });
  }

  onItemRemove = (index) => {
    const newItems = [...this.state.items];
    newItems.splice(index, 1);
    this.setState({ items: newItems });
  }

  emailValidator = (email) => {
    return isEmail(email) || email.length === 0;
  }

  showError = (err) => {
    if (err) {
      this.logger.error('error inviting:', err);
    }
    newEvent(ERROR, 'errorInviting', 'errorInviting', 'errorInviting');
  }

  onInvite = () => {
    if (this.props.inMeeting) {
      this.props.dispatch(inviteParticipants(this.state.items, { base_url: window.location.origin }));
    }
    else if (this.state.items.length) {
      inviteToPersonalRoom(this.context.environment, window.location.origin, this.state.items)
        .catch(this.showError);
    }
    else {
      this.showError();
    }
    this.props.onClose();
  }

  render() {
    const classes = this.props.classes;
    return (
      <ClosableDialog
        open={this.props.open}
        onClose={this.props.onClose}
        title={this.props.intl.formatMessage(messages.title)}
      >
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.text}>
            {this.props.intl.formatMessage(messages.content)}
          </DialogContentText>
          <ListAdder
            autoFocus
            items={this.state.items}
            textFieldLabel={this.props.intl.formatMessage(messages.email)}
            onAdd={this.onItemAdd}
            onAddAll={this.onItemsAdd}
            onRemove={this.onItemRemove}
            invalidMessage={this.props.intl.formatMessage(messages.emailError)}
            validator={this.emailValidator}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={this.state.items.length === 0}
            onClick={this.onInvite} color="primary"
          >
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </ClosableDialog>
    );
  }
}

InviteEmailDialog.propTypes = {
  intl: IntlPropType.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  inMeeting: PropTypes.bool.isRequired,
};


function mapStateToProps(state) {
  return {
    inMeeting: inMeeting(state),
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(InviteEmailDialog)));
