import React, { Component } from "react";
import PropTypes from "prop-types";
import fscreen from "fscreen";

class FullScreen extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    enabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    user: PropTypes.string,
    fullscreen_user: PropTypes.string,
    fullscreen_id: PropTypes.string,
  };

  static defaultProps = {
    enabled: false,
  };

  constructor(props) {
    super(props);

    this.detectFullScreen = this.detectFullScreen.bind(this);
    this.enterFullScreen = this.enterFullScreen.bind(this);
    this.leaveFullScreen = this.leaveFullScreen.bind(this);
  }

  componentDidMount() {
    fscreen.addEventListener("fullscreenchange", this.detectFullScreen);
  }

  componentWillUnmount() {
    fscreen.removeEventListener("fullscreenchange", this.detectFullScreen);
    this.props.onChange(false, this.props.user, this.props.fullscreen_user);
  }

  componentDidUpdate(prevProps) {
    // fullscreen_id is set on elements rendered on electron
    // to avoid interfering with the auto fullscreen mode, we disable handling
    // props
    if (!this.props.fullscreen_id && (this.props.enabled !== prevProps.enabled)) {
      this.handleProps(this.props);
    }
  }

  handleProps(props) {
    const enabled = this.isNodeFullScreen(this.node);
    if (!enabled && props.fullscreen_user !== props.user) {
      return;
    }
    if (enabled && !props.enabled && fscreen.fullscreenElement) {
      this.leaveFullScreen();
    } else if (!enabled && props.enabled && !fscreen.fullscreenElement && this.node) {
      this.enterFullScreen();
    }
  }

  isNodeFullScreen(node) {
    if (node && fscreen.fullscreenElement === node) {
      return true;
    }
    return false;
  }

  detectFullScreen() {
    if (this.props.onChange) {
      this.props.onChange(!!fscreen.fullscreenElement, this.props.user, this.props.fullscreen_user);
    }
  }

  enterFullScreen() {
    fscreen.requestFullscreen(this.node);
  }

  leaveFullScreen() {
    fscreen.exitFullscreen();
  }

  render() {
    return (
      <div
        className="FullScreen"
        id={this.props.fullscreen_id}
        ref={node => (this.node = node)}
        style={{ height: "100%", width: "100%" }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FullScreen;
