import React from 'react';

import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { SettingsRemote } from '@material-ui/icons';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import messages from './buttonsMessages';

import { enableDesktopControl } from '../../lib/actions/room';

import { State as RoomState } from '../../lib/reducers/room';
import { State as WebsocketState } from '../../lib/reducers/websocket';


function DesktopControl(props: ExtendedProps) {
  const { user, isDesktopControlEnabled, screenSourceType, isRequestingDesktopControl } = props;

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const enableOrDisableDesktopControl = React.useCallback(
    () => {
      dispatch(enableDesktopControl(user, !isDesktopControlEnabled, screenSourceType));
    }, [dispatch, user, isDesktopControlEnabled, screenSourceType]
  );

  const label = 
    isDesktopControlEnabled
      ? formatMessage(messages.disableDesktopControl)
      : formatMessage(messages.enableDesktopControl);

  const buttonSelected = isDesktopControlEnabled ? classes.buttonsSelected : null;
  const buttonClass = classNames(classes.buttons, buttonSelected);
  
  const iconSelected = isDesktopControlEnabled ? classes.desktopControlButtonSelected : null;
  const iconClass = classNames(classes.desktopControlButton, iconSelected);

  return (
    <ToolbarButton
      icon={<SettingsRemote className={iconClass} />}
      text={label}
      buttonProps={isRequestingDesktopControl
        ? { className: buttonClass }
        : { className: buttonClass, onClick: enableOrDisableDesktopControl }}
    />
  );
}


type State = {
  room: RoomState;
  websocket: WebsocketState;
}

type MappedProps = {
  user: string;
  isDesktopControlEnabled: boolean;
  isRequestingDesktopControl: boolean;
  screenSourceType: string;
}

type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const user = state.websocket.uid;
  let isDesktopControlEnabled = false;
  let isRequestingDesktopControl = false;

  if (user && state.room.roster[user]) {
    isDesktopControlEnabled = state.room.roster[user].isDesktopControlEnabled;
    isRequestingDesktopControl = state.room.roster[user].isRequestingDesktopControl;
  }
  let screenSourceType = '';
  if (state.room.screenSourceType) {
    screenSourceType = state.room.screenSourceType.toString();
  }

  return {
    user: user || '',
    isDesktopControlEnabled: isDesktopControlEnabled,
    isRequestingDesktopControl: isRequestingDesktopControl,
    screenSourceType: screenSourceType,
  };
};


export default connect(mapStateToProps)(DesktopControl);