import {
  TOGGLE_DEVICE_SETTINGS,
  VIDEO_DEVICE_SETTINGS,
  VIDEO_ENABLED,
  AUDIO_JOIN_MUTED,
  AUDIOIN_DEVICE_SETTINGS,
  AUDIOOUT_DEVICE_SETTINGS,
  VIDEO_QUALITY_SETTINGS,
  SET_IS_ELECTRON
} from '../actions/settings';


const defaultState = {
  device_settings: false,
  isElectron: false,
  videoEnabled: true,
  audioJoinMuted: false,
};


function settings(state = defaultState, action = {}) {
  switch (action.type) {
    case TOGGLE_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        device_settings: !state.device_settings,
      });
    case SET_IS_ELECTRON:
      return Object.assign({}, state, {
        isElectron: action.payload.isElectron,
      });
    case VIDEO_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        videoDevice: action.payload.config
      });
    case VIDEO_QUALITY_SETTINGS:
      return Object.assign({}, state, {
        videoQuality: action.payload.config
      });
    case VIDEO_ENABLED:
      return Object.assign({}, state, {
        videoEnabled: action.payload.config
      });
    case AUDIO_JOIN_MUTED:
      return Object.assign({}, state, {
        audioJoinMuted: action.payload.config
      });
    case AUDIOIN_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        audioInDevice: action.payload.config
      });
    case AUDIOOUT_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        audioOutDevice: action.payload.config
      });
    default:
      return state;
  }
}


export default settings;
export { defaultState };
