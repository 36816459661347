import React from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { IconUserAdd } from '../IconSet';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import messages from './buttonsMessages';
import { toggleInviteParticipantsDialog } from '../../lib/actions/room';


function InviteButton() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onClick = React.useCallback(
    () => {
      dispatch(toggleInviteParticipantsDialog());
    }
    , [dispatch]
  );

  return (
    <div>
      <ToolbarButton
        icon={<IconUserAdd size={28} />}
        text={formatMessage(messages.invite)}
        buttonProps={{ className: classes.buttons, onClick: onClick }}
      />
    </div>

  );
}


export default InviteButton;
