import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { APPCONFIG_SET, Action, OAuthConfig, RoomOptions } from '../actions/appconfig';
import { getDefaultThemeOptions } from '../utils/themeDefaults';


export interface State {
  site_logo?: string | undefined;
  site_title: string;
  login_enabled: boolean;
  oauth: Array<OAuthConfig> | [];
  is_holocom: boolean;
  theme: ThemeOptions;
  room_options: RoomOptions;
}


const setRoomOptions = (opts: RoomOptions): RoomOptions => {
  return {
    // eslint-disable-next-line @typescript-eslint/camelcase
    join_muted: opts.join_muted || false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    stream_quality: opts.stream_quality || 'all',
    // eslint-disable-next-line @typescript-eslint/camelcase
    frame_rate: opts.frame_rate,
    // eslint-disable-next-line @typescript-eslint/camelcase
    ice_reconnect_retries: opts.ice_reconnect_retries,
    // eslint-disable-next-line @typescript-eslint/camelcase
    ice_reconnect_retries_window: opts.ice_reconnect_retries_window,
    // eslint-disable-next-line @typescript-eslint/camelcase
    allowed_layouts: opts.allowed_layouts,
  };
};


const defaultState = {
  // eslint-disable-next-line @typescript-eslint/camelcase
  site_logo: undefined,
  // eslint-disable-next-line @typescript-eslint/camelcase
  site_title: 'HoloCom',
  // eslint-disable-next-line @typescript-eslint/camelcase
  login_enabled: true,
  oauth: [],
  // eslint-disable-next-line @typescript-eslint/camelcase
  is_holocom: false,
  theme: getDefaultThemeOptions(),
  // eslint-disable-next-line @typescript-eslint/camelcase
  room_options: setRoomOptions({}),
};


const getThemeOptions = (state: ThemeOptions, theme?: ThemeOptions): ThemeOptions => {
  return (theme && Object.keys(theme).length) ? theme : state;
};


function appconfig(state: State = defaultState, action: Action) {
  switch (action.type) {
    case APPCONFIG_SET:
      return Object.assign({}, state, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        site_logo: action.payload.appconfig.site_logo,
        // eslint-disable-next-line @typescript-eslint/camelcase
        site_title: action.payload.appconfig.site_title || state.site_title,
        // eslint-disable-next-line @typescript-eslint/camelcase
        login_enabled: action.payload.appconfig.login_enabled,
        oauth: action.payload.appconfig.oauth,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_holocom: action.payload.appconfig.is_holocom,
        theme: getThemeOptions(state.theme, action.payload.appconfig.theme),
        // eslint-disable-next-line @typescript-eslint/camelcase
        room_options: setRoomOptions(action.payload.appconfig.room_options || {}),
      });
    default:
      return state;
  }
}

export default appconfig;
export { defaultState };
