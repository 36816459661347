/* tslint:disable */
/* eslint-disable */
/* @relayHash 613d4ff6548efa5f31a226cc95a5c77d */

import { ConcreteRequest } from "relay-runtime";
export type sessionDetailsQueryVariables = {};
export type sessionDetailsQueryResponse = {
    readonly loggedUser: {
        readonly session: {
            readonly realm: string;
            readonly displayName: string;
            readonly username: string;
            readonly roles: ReadonlyArray<string>;
            readonly stunServers: ReadonlyArray<{
                readonly credential: string | null;
                readonly urls: ReadonlyArray<string>;
                readonly username: string | null;
            }>;
            readonly domainId: string;
            readonly email: string | null;
            readonly userId: string;
            readonly roomNumber: string;
            readonly roomOwnerNumber: string | null;
            readonly pstnNumber: string | null;
            readonly callStatsConfig: {
                readonly appId: string;
                readonly appSecret: string;
                readonly endpoint: string;
            } | null;
            readonly chromeScreenShareConfig: {
                readonly url: string;
            } | null;
            readonly recordingEnabled: boolean;
            readonly livestreamingEnabled: boolean;
            readonly sendAs: boolean;
            readonly personalRoomEnabled: boolean;
            readonly webinarEnabled: boolean;
            readonly isAudioOnly: boolean;
            readonly roomLockPreference: boolean;
        };
    };
};
export type sessionDetailsQuery = {
    readonly response: sessionDetailsQueryResponse;
    readonly variables: sessionDetailsQueryVariables;
};



/*
query sessionDetailsQuery {
  loggedUser {
    session {
      realm
      displayName
      username
      roles
      stunServers {
        credential
        urls
        username
      }
      domainId
      email
      userId
      roomNumber
      roomOwnerNumber
      pstnNumber
      callStatsConfig {
        appId
        appSecret
        endpoint
      }
      chromeScreenShareConfig {
        url
      }
      recordingEnabled
      livestreamingEnabled
      sendAs
      personalRoomEnabled
      webinarEnabled
      isAudioOnly
      roomLockPreference
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "loggedUser",
    "storageKey": null,
    "args": null,
    "concreteType": "LoggedUserData",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "session",
        "storageKey": null,
        "args": null,
        "concreteType": "Session",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "realm",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "roles",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "stunServers",
            "storageKey": null,
            "args": null,
            "concreteType": "StunServer",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "credential",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "urls",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "domainId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "roomNumber",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "roomOwnerNumber",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pstnNumber",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "callStatsConfig",
            "storageKey": null,
            "args": null,
            "concreteType": "CallStats",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "appId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "appSecret",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endpoint",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "chromeScreenShareConfig",
            "storageKey": null,
            "args": null,
            "concreteType": "ChromeScreenShareExtension",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "recordingEnabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "livestreamingEnabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sendAs",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "personalRoomEnabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "webinarEnabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isAudioOnly",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "roomLockPreference",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "sessionDetailsQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "sessionDetailsQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "sessionDetailsQuery",
    "id": null,
    "text": "query sessionDetailsQuery {\n  loggedUser {\n    session {\n      realm\n      displayName\n      username\n      roles\n      stunServers {\n        credential\n        urls\n        username\n      }\n      domainId\n      email\n      userId\n      roomNumber\n      roomOwnerNumber\n      pstnNumber\n      callStatsConfig {\n        appId\n        appSecret\n        endpoint\n      }\n      chromeScreenShareConfig {\n        url\n      }\n      recordingEnabled\n      livestreamingEnabled\n      sendAs\n      personalRoomEnabled\n      webinarEnabled\n      isAudioOnly\n      roomLockPreference\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4b4d9c5cb4a1841bee26939a814ba39f';
export default node;
