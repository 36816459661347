import React from 'react';
import linkifyit from 'linkify-it';


function linkify(text, target = "_blank", truncate = null) {
  const links = linkifyit().match(text) || [];
  let res = [];
  let currentIdx = 0;
  for (let i = 0; i < links.length; i++) {
    let slice = text.slice(currentIdx, links[i].index);
    if (slice) {
      res.push(text.slice(currentIdx, links[i].index));
    }
    currentIdx = links[i].lastIndex;
    let linkText = _truncateTextIfNeeded(links[i].text, truncate);
    let el = React.createElement(
      'a',
      { key: i, href: links[i].url, rel: "noopener noreferrer", target: target },
      linkText
    );
    res.push(el);
  }
  if (currentIdx < text.length) {
    let slice = text.slice(currentIdx, text.length);
    if (slice) {
      res.push(slice);
    }
  }
  return res;
}


function _truncateTextIfNeeded(text, truncate) {
  if (truncate) {
    if (text.length > truncate) {
      text = text.substr(0, truncate) + '...';
    }
  }
  return text;
}


function isPhoneNumber(number) {
  var re = /^[0-9]+$/;
  const isPhoneNumber = re.test(number.toLowerCase());
  return isPhoneNumber;
}


function isSipUri(uri) {
  const scheme = '(sip|sips):';
  const user_pass = '(?:([\\w\\.]+):?([\\w\\.]+)?@)?';
  const host_begin = '\\[?(';
  const ipv4_or = '(?:\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})|';
  const ipv6_or = '(?:(?:[0-9a-fA-F]{1,4}):){7}[0-9a-fA-F]{1,4}|';
  const str_hostname = '(?:(?:[0-9A-Za-z]+\\.)+[0-9A-Za-z]+)';
  const host_end = ')\\]?';
  const port = '(:\\d{1,6})?';
  const params = '(?:\\;([^\\?]*))?';
  const headers = '(?:\\?(.*))?';

  const re = new RegExp('^' + scheme + user_pass + host_begin + ipv4_or + ipv6_or + str_hostname +
    host_end + port + params + headers + '$'
  );

  return re.test(uri);
}

function isEmail(email) {
  var re = /^\S*[^\s@]+[^.+!*'(),;?&=]@[^\s@.]+\.[^\s@]+[^.]$/i;
  return re.test(email);
}

function findUsers(currentUsersList, nextUsersList) {
  const statusUsers = [];
  const exitedSet = new Set(nextUsersList);
  currentUsersList.forEach((user) => {
    if (!exitedSet.has(user)) {
      statusUsers.push({
        status: 'exited',
        id: user
      });
    }
  });

  const joinedSet = new Set(currentUsersList);
  nextUsersList.forEach((user) => {
    if (!joinedSet.has(user)) {
      statusUsers.push({
        status: 'joined',
        id: user
      });
    }
  });

  return statusUsers;
}

export { linkify, isPhoneNumber, isSipUri, isEmail, findUsers };
