import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainSplit: {
      position: 'relative !important' as 'relative',
    },
    content: {
      height: '100%',
      backgroundColor: theme.palette.secondary.light,
    },
    toolbar: {
      height: '100%',
    },
    paneDesktop: {
      height: '100%',
    },
    paneMobileBase: {
      position: 'relative',
      display: 'block', 
    },
  })
);

export default useStyles;
