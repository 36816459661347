import React from 'react';
import { State } from '../../lib/reducers';
import { connect, useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Switch from '@material-ui/core/Switch';
import { useRelayEnvironment } from 'relay-hooks';
import { updateDefaultRoomLock } from '../../lib/actions/user';
import { IconHelp } from '../IconSet';
import { Tooltip } from '@material-ui/core';

const messages = defineMessages({
  roomLockPreferenceSwitchLabel: { id: 'roomLockPreferenceSwitchLabel' },
  roomLockPreferenceSwitchText: { id: 'roomLockPreferenceSwitchText' },
  roomLockPreferenceSwitchInfo: { id: 'roomLockPreferenceSwitchInfo' },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userInfoEntryTitle: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1em',
    },
    userInfoEntryContent: {
      color: 'rgba(0, 0, 0, 0.90)',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },
    userInfoLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    userInfoLabelContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    info: {
      paddingLeft: theme.spacing(0.5)
    }
  })
);

function RoomLockPreferenceSwitch(props: ExtendedProps) {
  const {
    roomLockPreference,
    isFetching,
    isUpdating
  } = props;

  const classes = useStyles();
  const relayEnv = useRelayEnvironment();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const switchDisabled = isFetching || isUpdating;

  const handleChange = () => {
    dispatch(updateDefaultRoomLock(relayEnv, !roomLockPreference));
  };

  return (
    <div className={classes.userInfoEntryTitle}>
      <div className={classes.userInfoLabelContainer}>
        {formatMessage(messages.roomLockPreferenceSwitchLabel)}
        <Tooltip title={formatMessage(messages.roomLockPreferenceSwitchInfo)}>
          <div className={classes.info}>
            <IconHelp size={16} />
          </div>
        </Tooltip>
      </div>
      <div className={classes.userInfoLinkContainer}>
        <div className={classes.userInfoEntryContent}>
          {formatMessage(messages.roomLockPreferenceSwitchText)}
        </div>
        <Switch
          disabled={switchDisabled}
          checked={roomLockPreference}
          onChange={handleChange}
          color="primary"
        />
      </div>
    </div>
  );
}

type MappedProps = {
  roomLockPreference: State['session']['roomLockPreference'];
  isFetching: State['session']['isFetching'];
  isUpdating: State['session']['isUpdating'];
}

type Props = {
};

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State): MappedProps => ({
  roomLockPreference: state.session.roomLockPreference,
  isFetching: state.session.isFetching,
  isUpdating: state.session.isUpdating
});

export default connect(mapStateToProps)(RoomLockPreferenceSwitch);
