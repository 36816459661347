/* Actions Imports */
import {
  saveVideoConfig, saveAudioInConfig, saveAudioOutConfig,
} from '../actions/settings';

const defaultVideoQuality = 'nhd';


class RtcDevices {
  constructor(rtcProvider, logger) {
    this.rtc = rtcProvider;
    this.logger = logger;
  }

  permissionsGranted() {
    const p = this.rtc.mediaDevices.enumerateDevices();
    return p.then((r) => this._devicesArePermitted(r)).catch((err) => this._onEnumerateError(err));
  }

  askFullPermissions() {
    const constraints = { audio: true, video: true };
    return this.rtc.mediaDevices.getUserMedia(constraints);
  }

  askAudioOnlyPermissions() {
    const constraints = { audio: true, video: false };
    return this.rtc.mediaDevices.getUserMedia(constraints);
  }

  discoverDevices() {
    return this.rtc.mediaDevices.enumerateDevices();
  }

  _getLocalStreamByConstraints(constraints) {
    return this.rtc.mediaDevices.getUserMedia(constraints).catch(
      () => {
        constraints.video = false;
        return this.rtc.mediaDevices.getUserMedia(constraints);
      }
    );
  }

  _getDeviceQuality(quality, options) {
    if (options.video === false) {
      return null;
    }
    const qualityConstraint = options.qualityConstraint;
    if (!qualityConstraint || qualityConstraint === 'all') {
      return quality;
    }
    if (qualityConstraint === 'low') {
      if (quality === 'nhd' || quality === 'vga' || quality === '180p') {
        return quality;
      }
      else {
        return 'nhd';
      }
    }
    if (qualityConstraint === 'high') {
      if (quality === 'hd' || quality === 'fhd') {
        return quality;
      }
      else {
        return 'hd';
      }
    }
    else {
      return defaultVideoQuality;
    }
  }

  getLocalStreamByDevices(audioId, videoId, quality, options = {}) {
    quality = this._getDeviceQuality(quality, options);
    const frameRate = options.frameRate;
    const defaultQuality = options.video === false ? null : defaultVideoQuality;
    const qualityConstraints = this._buildVideoQualityConstraints(quality, frameRate);
    const videoConstraints = qualityConstraints ?
      Object.assign(qualityConstraints, {
        deviceId: videoId ? { exact: videoId } : undefined,
        facingMode: 'user',
      }) :
      false;
    const audioConstraints = {
      deviceId: audioId ? { exact: audioId } : undefined,
      /* see https://bugs.chromium.org/p/chromium/issues/detail?id=709931*/
      /* optional: this._getOptionalAudioConstraints(), */
    };
    const constraints = {
      audio: audioConstraints,
      video: videoConstraints
    };
    const defaultQualityConstraints = this._buildVideoQualityConstraints(defaultQuality, frameRate);
    const defaultVideoConstraints = defaultQualityConstraints ?
      Object.assign(defaultQualityConstraints, {
        deviceId: videoId ? { exact: videoId } : undefined,
        facingMode: 'user',
      }) :
      false;
    const defaultConstraints = {
      audio: audioConstraints,
      video: defaultVideoConstraints
    };
    if (options.fallbackToAudioOnly) {
      if (!quality) {
        return this.rtc.mediaDevices.getUserMedia(defaultConstraints).catch(
          () => {
            return this._getLocalStreamByConstraints(constraints);
          }
        );
      }
      else {
        return this._getLocalStreamByConstraints(constraints);
      }
    }
    else {
      return this.rtc.mediaDevices.getUserMedia(constraints);
    }
  }

  applyTrackConstraints(track, options) {
    const quality = options.streamQuality || defaultVideoQuality;
    const qualityConstraints = this._buildVideoQualityConstraints(quality, options.frameRate);
    const currSettings = track.getSettings();
    if (this._shoudlApplyNewConstraints(qualityConstraints, currSettings)) {
      return track.applyConstraints(qualityConstraints);
    }
    else {
      return Promise.resolve(null);
    }
  }

  _shoudlApplyNewConstraints(options, currSettings) {
    if (
      (!options.frameRate || options.frameRate === currSettings.frameRate)
      && currSettings.height
      && (currSettings.height === options.height.max)
    ) {
      return false;
    }
    return true;
  }

  setAudioOutputOn(videoElem, mediaDevice) {
    if (videoElem.setSinkId && mediaDevice.deviceId) {
      return videoElem.setSinkId(mediaDevice.deviceId);
    }
    return Promise.reject(
      new Error('No device ID or setSinkId on video element')
    );
  }

  stopStream(stream) {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  validateDevicesWithState(props, dispatch) {
    this.discoverDevices().then((devices) => {
      this._validateDevicesWithState(devices, props, dispatch);
    }).catch((e) => this.logger.error(e));
  }

  _validateDevicesWithState(devices, props, dispatch) {
    let found = false;

    // check audioInDevice
    found = devices.find((device) => {
      const deviceId = props.audioInDevice ? props.audioInDevice.deviceId : undefined;
      return device.deviceId === deviceId;
    });
    if (!found && props.audioInDevice !== null) {
      this.logger.warning('Audio IN device not found, setting to default');
      dispatch(saveAudioInConfig(null, props.localStore));
    }

    // check audioOutDevice
    found = devices.find((device) => {
      const deviceId = props.audioOutDevice ? props.audioOutDevice.deviceId : undefined;
      return device.deviceId === deviceId;
    });
    if (!found && props.audioOutDevice !== null) {
      this.logger.warning('Audio OUT device not found, setting to default');
      dispatch(saveAudioOutConfig(null, props.localStore));
    }

    // check videoDevice
    found = devices.find((device) => {
      const deviceId = props.videoDevice ? props.videoDevice.deviceId : undefined;
      return device.deviceId === deviceId;
    });
    if (!found && props.videoDevice !== null) {
      this.logger.warning('Video device not found, setting to default');
      dispatch(saveVideoConfig(null, props.localStore));
    }
  }

  _buildVideoQualityConstraints(quality, frameRate) {
    let constr = {};
    if (frameRate) {
      constr['frameRate'] = frameRate;
    }
    if (quality === '180p') {
      return { ...constr, width: { min: 320, max: 320 }, height: { min: 180, max: 180 } };
    }
    else if (quality === 'vga') {
      return { ...constr, width: { min: 640, max: 640 }, height: { min: 480, max: 480 } };
    } else if (quality === 'nhd') {
      return { ...constr, width: { min: 640, max: 640 }, height: { min: 360, max: 360 } };
    } else if (quality === 'hd') {
      return { ...constr, width: { min: 1280, max: 1280 }, height: { min: 720, max: 720 } };
    } else if (quality === 'fhd') {
      return { ...constr, width: { min: 1920, max: 1920 }, height: { min: 1080, max: 1080 } };
    } else if (quality === null) {
      return false;
    } else {
      return constr;
    }
  }

  _getOptionalAudioConstraints() {
    /*
    * these are taken from what google meet/hangouts do
    * some are enabled by default, like echoCan and autoGainControl.
    */
    return [
      { echoCancellation: true },
      { googEchoCancellation: true },
      { googEchoCancellation2: true },
      { googDAEchoCancellation: true },
      { googAutoGainControl: true },
      { googAutoGainControl2: true },
      { googNoiseSuppression: true },
      { googNoiseSuppression2: true },
      /*
      * intelligibilityEnhancer is not active yet
      * but present in chromium source code
      */
      { intelligibilityEnhancer: true },
      { googTypingNoiseDetection: true },
      { googHighpassFilter: true },
    ];
  }

  _devicesArePermitted(devices) {
    return devices.some((device) => this._isDeviceAccessible(device));
  }

  _isDeviceAccessible(device) {
    if (device.label === "") {
      return true;
    }
    return false;
  }

  _onEnumerateError(error) {
    this.logger.log("Enumeration error: ", error);
  }

}

function getVideoQualities() {
  return [
    { value: '180p', label: '180p' },
    { value: 'vga', label: 'SD (VGA)' },
    { value: 'nhd', label: 'nHD' },
    { value: 'hd', label: 'HD' },
    { value: 'fhd', label: 'Full HD' },
  ];
}

export { RtcDevices, getVideoQualities };
