/* Libraries Imports */
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import moment from 'moment';
/* Other Imports */
import { ExtendedMeeting } from '.';
import { capitalize } from '../../lib/utils/string';
/* Local Style */
import useStyles from './style';


const messages = defineMessages({
  roomScheduling: { id: 'roomInfoScheduling' },
  from: { id: 'from' },
  to: { id: 'to' },
});


type Props = {
  roomDetails: ExtendedMeeting;
}


function Scheduling(props: Props) {
  const { formatMessage, formatDate } = useIntl();
  const classes = useStyles();

  const getScheduling = (room: ExtendedMeeting) => {
    if (!(room.dt_start && room.dt_end)) {
      return null;
    }
    const today = new Date();
    let start = formatDate(room.dt_start, { hour: 'numeric', minute: 'numeric' });
    if (!moment(room.dt_start).isSame(today, 'day')) {
      start = formatDate(room.dt_start, { weekday: 'long', hour: 'numeric', minute: 'numeric' });
    }
    let end = formatDate(room.dt_end, { weekday: 'long', hour: 'numeric', minute: 'numeric' });
    if (moment(room.dt_start).isSame(room.dt_end, 'day')) {
      end = formatDate(room.dt_end, { hour: 'numeric', minute: 'numeric' });
    }
    const msg = `${ formatMessage(messages.from) } `
      + `${ start } ${ formatMessage(messages.to) } ${ end }`;
    return (
      <div className={classes.title}>
        {formatMessage(messages.roomScheduling)}
        <div className={classes.content}>
          {capitalize(msg)}
        </div>
      </div>
    );
  };

  return (
    <div>
      {getScheduling(props.roomDetails)}
    </div>
  );
}


export default Scheduling;