/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import MaterialUIAvatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import Phone from '@material-ui/icons/Phone';
import {
  amber, blueGrey, blue, brown, cyan, deepOrange, deepPurple, green, grey,
  indigo, lightBlue, lightGreen, orange, pink, purple, red, teal
} from '@material-ui/core/colors';
/* Other Imports */
/* Local Style */
import style from './style';


class Avatar extends Component {
  constructor(props) {
    super(props);
    this.chooseColor(props.uid);
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    displayName: PropTypes.string,
    viaPhone: PropTypes.bool,
    uid: PropTypes.string,
  }

  static defaultProps = {
    viaPhone: false,
  }

  hash(str) {
    let hash = 5381;
    let i = str.length;
    while (i) {
      hash = (hash * 33) ^ str.charCodeAt(--i);
    }
    return hash >>> 0;
  }

  chooseColor(uid) {
    const colors = [amber, blueGrey, blue, brown, cyan,
      deepOrange, deepPurple, green, grey, indigo,
      lightBlue, lightGreen, orange, pink, purple, red,
      teal];
    let index;
    if (uid) {
      index = this.hash(uid) % colors.length;
    }
    else {
      index = 0;
    }
    this.avatarColor = colors[index][500];
  }

  initialize(name) {
    let tokens = name.toUpperCase().split(/[\s+-]/);
    tokens = tokens.filter((word) => (word.length >= 1));
    if (tokens.length < 1) {
      return '';
    }
    else if (tokens.length === 1) {
      return tokens[0].slice(0, 2);
    }
    else {
      return tokens[0][0] + tokens[1][0];
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.uid !== prevProps.uid) {
      this.chooseColor(this.props.uid);
    }
  }

  render() {
    const {
      viaPhone,
      displayName,
      classes,
      className,
    } = this.props;
    if (viaPhone) {
      return (
        <MaterialUIAvatar
          className={classNames(classes.avatar, className)}
          style={{ backgroundColor: this.avatarColor }}
        >
          <Phone className={classes.avatarIcon} />
        </MaterialUIAvatar>
      );
    }
    const initials = this.initialize(displayName);
    if (initials) {
      return (
        <MaterialUIAvatar
          className={classNames(classes.avatar, className)}
          style={{ backgroundColor: this.avatarColor }}
        >
          <div className={classes.avatarIcon} >
            {initials}
          </div>
        </MaterialUIAvatar>
      );
    }
    else {
      return (
        <MaterialUIAvatar
          className={classNames(classes.avatar, className)}
          style={{ backgroundColor: this.avatarColor }}
        >
          <Person className={classes.avatarIcon} />
        </MaterialUIAvatar>
      );
    }
  }
}


export default withStyles(style)(Avatar);
