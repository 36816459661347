import React from 'react';

import { connect, useDispatch } from 'react-redux';

import sizeMe, { SizeMeProps } from 'react-sizeme';

import { scaleAndCenterVideo } from '../../../lib/grid';

import { RoomLayout, RoomLayoutStreamType } from '../../../lib/redux_types';

import { changeLayout } from '../../../lib/actions/room';

import Fullscreen from '../Fullscreen';
import VideoElement from '../../VideoElement';
import VideoToolbar from '../VideoToolbar';

import mapStateToProps, { ExtendedProps } from './state';


function MainVideo(props: ExtendedProps & SizeMeProps) {
  const {
    size,
    hasVideoStream,
    myUserId,
    localVideoStream,
    localScreenStream,
    remoteVideoStreams,
    selectedStream,
    myStreamIsSelected,
    myScreenIsSelected,
    layoutConfig,
    roomOwner,
    roomOwnerHasStream,
    roomOwnerHasScreen,
  } = props;

  const dispatch = useDispatch();

  const maybeUpdateLayout = (uid: string, type: RoomLayoutStreamType) => {
    if (uid !== layoutConfig.featured_id || type !== layoutConfig.featured_type) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const newLayoutConfig = { ...layoutConfig, featured_type: type, featured_id: uid };
      dispatch(changeLayout('featured' as RoomLayout, newLayoutConfig));
    }
  };

  if (!(size.width && size.height)) {
    // size detection returned null. Maybe we are mid-rendering, do not show a
    // anything for now, but render a maximized div or the resize detector
    // won't work again
    return (
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      </div>
    );
  }

  const videoStyle = scaleAndCenterVideo({ width: size.width, height: size.height });

  if (selectedStream) {
    let uid = selectedStream;
    let stream = null;
    let mirrored = false;
    if (myStreamIsSelected && hasVideoStream && myUserId) {
      uid = myUserId;
      stream = localVideoStream;
      mirrored = true;
    }
    else if (myScreenIsSelected && localScreenStream && myUserId) {
      uid = `${myUserId}_screen`;
      stream = localScreenStream;
    }
    else if (remoteVideoStreams[uid] && remoteVideoStreams[uid].stream) {
      stream = remoteVideoStreams[uid].stream;
    }

    if (stream) {
      return (
        <Video uid={uid} mirrored={mirrored} style={videoStyle} stream={stream} />
      );
    }
    else {
      return (
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        </div>
      );
    }
  }
  else {
    if (roomOwner && roomOwnerHasScreen) {
      // update layout, since we may have "lost" the previously selected stream
      maybeUpdateLayout(roomOwner, 'screen');

      const uid = `${roomOwner}_screen`;
      const stream = (roomOwner === myUserId) ? localScreenStream : remoteVideoStreams[uid].stream;
      return (
        <Video uid={uid} mirrored={false} style={videoStyle} stream={stream} />
      );
    }
    else if (roomOwner && roomOwnerHasStream) {
      // update layout, since we may have "lost" the previously selected stream
      maybeUpdateLayout(roomOwner, 'stream');

      const uid = `${roomOwner}`;
      const stream = (roomOwner === myUserId) ? localVideoStream : remoteVideoStreams[uid].stream;
      return (
        <Video uid={uid} mirrored={roomOwner === myUserId} style={videoStyle} stream={stream} />
      );
    }
    else if (hasVideoStream && myUserId && localVideoStream) {

      // update layout, since we may have "lost" the previously selected stream
      maybeUpdateLayout(myUserId, 'stream');

      return (
        <Video uid={myUserId} mirrored style={videoStyle} stream={localVideoStream} />
      );
    }
    else {
      // FIXME: decide what to do here
      // the outer div is there otherwise the resize detector stops working
      return (
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        </div>
      );
    }
  }
}


type VideoProps = {
  style: ReturnType<typeof scaleAndCenterVideo>;
  uid: string;
  stream: MediaStream;
  mirrored: boolean;
}


function Video(props: VideoProps) {
  const { style, uid, stream, mirrored } = props;

  // the outer div is needed for the resize detector
  // add key to Fullscreen to let unmount...
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ position: 'absolute', ...style }}>
        <Fullscreen key={uid} user={uid}>
          <VideoElement
            user={uid}
            mirrored={mirrored}
            addVideoMutedIconOverlay={true}
            src={stream}
          />
          <VideoToolbar uid={uid} />
        </Fullscreen>
      </div>
    </div>
  );
}


export default connect(mapStateToProps)(sizeMe({ monitorHeight: true })(MainVideo));
