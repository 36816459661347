import React from 'react';

import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { defineMessages, useIntl } from 'react-intl';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import AudioElement from '../AudioElement';

import { usePrevious } from '../../lib/utils/hooks';
import { getRoomNameFromParams } from '../../lib/utils/router';
import { checkPermissionNotification, sendNotification } from '../../notification';
import { newEvent, INFO } from '../../lib/notifications';
import { State } from '../../lib/reducers';
import { isThereAnyVideo, amModerator } from '../../lib/reduxSelectors/room';
import { isRecorder } from '../../lib/reduxSelectors/session';
import {
  createVideoRoom,
  tearDownRoom,
  addStream,
  toggleAllVideoMute,
  Action as RoomAction
} from '../../lib/actions/room';
import { joinRoom, leaveChannel } from '../../lib/actions/websocket';
import prepareWebRtcProvider from '../../rtc';
import { getLogger } from '../../lib/logger';

import FullscreenLayout from './FullscreenLayout';
import GridLayout from './GridLayout';
import PresentationLayout from './PresentationLayout';
import WebinarLayout from './WebinarLayout';
import LessonLayout from './LessonLayout';
import AudioOnlyLayout from './AudioOnlyLayout';
import MobileLayout from './MobileLayout';
import { isMobileOnly } from 'react-device-detect';
import { Channel } from 'phoenix';


const messages = defineMessages({
  pleaseWaitForRoom: { id: 'pleaseWaitForRoom' },
  canPublishVideoAgain: { id: 'canPublishVideoAgain' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingMaskContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingMaskMessage: {
      padding: theme.spacing(2),
    },
    root: {
      [theme.breakpoints.up('md')]: {
        width: 'inherit',
        height: 'inherit'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%'
      }
    }
  })
);


function onFullscreenLayout(
  dispatch: Dispatch<Action>,
  layout: MappedProps['layout'],
  previousLayout: MappedProps['layout'] | undefined
) {
  if (layout !== 'fullscreen' && previousLayout === 'fullscreen') {
    dispatch(toggleAllVideoMute(false));
  }
}


function Layout(
  layout: MappedProps['layout'],
  previousLayout: MappedProps['layout'] | undefined,
  noVideos: boolean,
  amIModerator: boolean,
) {
  const dispatch = useDispatch();

  const logger = getLogger('Debug layout');
  logger.info('layout:', layout, 'prevLayout', previousLayout);

  const webinarModerator = layout === 'webinar' && amIModerator;
  if (noVideos && webinarModerator) {
    // use audio only layout in webinars only if you are a moderator and no
    // video streams are found
    return <AudioOnlyLayout />;
  }
  else if (noVideos && layout !== 'webinar') {
    return <AudioOnlyLayout />;
  }

  onFullscreenLayout(dispatch, layout, previousLayout);

  if (isMobileOnly && layout !== 'audioonly') {
    return (
      <MobileLayout />
    );
  }

  if (layout === 'default') {
    return (
      <GridLayout />
    );
  }
  else if (layout === 'featured') {
    return (
      <PresentationLayout />
    );
  }
  else if (layout === 'audioonly') {
    return (
      <AudioOnlyLayout />
    );
  }
  else if (layout === 'fullscreen') {
    return (
      <FullscreenLayout />
    );
  }
  else if (layout === 'lesson') {
    return (
      <LessonLayout />
    );
  }
  else {
    return (
      <WebinarLayout />
    );
  }
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WsAction = any   // FIXME
type Action = RoomAction & WsAction


function getRoomOptions(
  audioJoinMuted: boolean,
  acquireVideo: boolean,
  publishVideo: boolean,
  roomOptions: ExtendedProps['roomOptions']
) {
  return {
    acquireVideo: acquireVideo,
    publishVideo: publishVideo,
    muted: audioJoinMuted || Boolean(roomOptions.join_muted),
    frameRate: roomOptions.frame_rate,
    streamQuality: roomOptions.stream_quality,
  };
}


function SetupRoomActions(props: ExtendedProps) {
  const { isSocketConnected, roomJoined } = props;
  const getRoomOptionsRef = React.useRef(
    () => getRoomOptions(props.audioJoinMuted, props.withVideo, props.canPublishVideo, props.roomOptions)
  );
  React.useEffect(
    () => {
      getRoomOptionsRef.current =
        () => getRoomOptions(props.audioJoinMuted, props.withVideo, props.canPublishVideo, props.roomOptions);
    },
    [props.audioJoinMuted, props.roomOptions, props.layout, props.withVideo, props.canPublishVideo]
  );

  const meetingId = getRoomNameFromParams(props.match.params);
  const logger = getLogger(`Meeting ${meetingId}`);

  const dispatch = useDispatch<Action>();

  // the callback to dispatch room actions are defined as refs because we do
  // not want to track their change when scheduling the effects below (e.g. we
  // need to run the join meeting callback only when the isSocketConnected prop
  // has changed, and not track the meetingId, logger changes).
  // Defining them as refs avoids putting them in the useEffect dependencies
  // array without removing the exhaustive-deps eslint check.
  const joinMeeting = React.useRef(
    () => {
      logger.info(`joining room ${meetingId}`);
      dispatch(createVideoRoom(meetingId, prepareWebRtcProvider(), logger));
      dispatch(joinRoom(meetingId, logger));
    }
  );

  const stopRoom = React.useRef(
    () => {
      logger.info('tear down room');
      dispatch(tearDownRoom());
      dispatch(leaveChannel());
    }
  );

  const addLocalStream = React.useRef(
    () => {
      const opts = getRoomOptionsRef.current();
      logger.info('add local stream with options', opts);
      dispatch(addStream(meetingId, opts, logger));
    }
  );

  React.useEffect(
    () => {
      if (isSocketConnected) {
        joinMeeting.current();
      }
      // capture the current value of teardown to be run at unmount. This
      // should not be necessary, but avoids a compiler warning
      const tdown = stopRoom.current;
      return () => {
        if (isSocketConnected) {
          tdown();
        }
      };
    }
    , [isSocketConnected]
  );

  React.useEffect(
    () => {
      if (roomJoined) {
        addLocalStream.current();
      }
    },
    [roomJoined]
  );
}


function LoadingMask(props: { isRecorder: boolean }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const getLoadingMask = () => {
    return (
      <React.Fragment>
        <CircularProgress />
        <Typography variant='caption' className={classes.loadingMaskMessage}>
          {formatMessage(messages.pleaseWaitForRoom)}
        </Typography>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.loadingMaskContainer}>
      {props.isRecorder
        ? null
        : getLoadingMask()}
    </div>
  );
}


function Room(props: ExtendedProps) {
  React.useEffect(checkPermissionNotification, []);
  SetupRoomActions(props);

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const loadingMaskTimeout = 2000;

  const [loadingMask, setLoadingMask] = React.useState(false);

  const previousLayout = usePrevious(props.layout, undefined);
  const previousCanPublishVideo = usePrevious(props.canPublishVideo, true);

  React.useEffect(
    () => {
      if (props.isThereAnyVideo) {
        setLoadingMask(false);
      }
    }
    , [props.isThereAnyVideo]
  );

  React.useEffect(
    () => {
      setLoadingMask(true);
      const timer = setTimeout(
        () => {
          setLoadingMask(false);
        }, loadingMaskTimeout
      );
      return (
        () => {
          if (timer) clearTimeout(timer);
        }
      );
    }
    , []
  );

  React.useEffect(
    () => {
      if (!previousCanPublishVideo && props.canPublishVideo && props.roomJoined) {
        /*
         * tell the user he can start publishing its own video again
         * with a browser notification if enabled and not in focus
         * or a snackbar
        */
        if (!document.hasFocus()) {
          const message = formatMessage(messages.canPublishVideoAgain);
          sendNotification(props.siteTitle, message);
        } else {
          newEvent(INFO, 'canPublishVideoAgain', 'canPublishVideoAgain', 'canPublishVideoAgain');
        }
      }
    }, [previousCanPublishVideo, props.siteTitle, props.canPublishVideo, props.roomJoined, formatMessage]
  );

  return (
    <div className={classes.root}>
      <AudioElement src={props.audio_stream} handleAudioOutputChange={true} />
      {loadingMask
        ? <LoadingMask isRecorder={props.isRecorder} />
        : Layout(props.layout, previousLayout, !props.isThereAnyVideo, props.amIModerator)
      }
    </div>
  );
}



type MappedProps =
  Pick<State['room'], 'layout' | 'audio_stream'>
  & {
    isSocketConnected: State['websocket']['isConnected'];
    isRecorder: boolean;
    roomJoined: boolean;
    isThereAnyVideo: boolean;
    withVideo: boolean;
    audioJoinMuted: boolean;
    roomOptions: State['appconfig']['room_options'];
    canPublishVideo: boolean;
    siteTitle: string;
    amIModerator: boolean;
  }


type Props = {} & RouteComponentProps<{ id: string }>


type ExtendedProps = Props & MappedProps

function roomJoined(room: Channel | null, roomName: string | null) {
  roomName = roomName == null ? "" : roomName;
  return Boolean(room) && !roomName.startsWith("waiting_room");
}

const mapStateToProps = (state: State, { match }: Props): MappedProps => {
  return {
    layout: state.room.layout,
    // eslint-disable-next-line @typescript-eslint/camelcase
    audio_stream: state.room.audio_stream,
    isSocketConnected: state.websocket.isConnected,
    isRecorder: isRecorder(state, match.url),
    roomJoined: roomJoined(state.websocket.room, state.websocket.room_name),
    isThereAnyVideo: isThereAnyVideo(state),
    withVideo: state.settings.videoEnabled,
    audioJoinMuted: state.settings.audioJoinMuted || !state.room.mediaPermissions.canPublishAudio,
    roomOptions: state.appconfig.room_options,
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
    siteTitle: state.appconfig.site_title,
    amIModerator: amModerator(state),
  };
};


export default withRouter(connect(mapStateToProps)(Room));
