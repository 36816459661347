import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';


export const StyledChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light
    },
    deleteIcon: {
      margin: 0,
      width: 'auto',
      height: 'auto',
    }
  }),
)(Chip);


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
    },
    list: {
      overflowY: 'auto',
      paddingTop: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(0.5),
      maxHeight: '20vh',
    },
    chip: {
      maxWidth: '50%',
      margin: theme.spacing(0.5),
    },
    tooltip: {
      maxWidth: 'none',
      boxSizing: 'unset'
    },
    autocomplete: {
      flexGrow: 1
    },
    inputWrapper: {
      paddingTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    uploadButton: {
      transform: 'rotate(180deg)'
    }
  }),
);


export default useStyles;
