import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import { State } from '../../../lib/reducers';
import { toggleDeviceSettings } from '../../../lib/actions/settings';

import { isMobileOnly } from 'react-device-detect';
import { FirstTimePopoverDialog } from '../../PopoverDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconSettings, IconSettingsSelected } from '../../IconSet';


const messages = defineMessages({
  mediaSettings: { id: 'mediaSettings' },
  mediaSettingsFirstTimeMsg: { id: 'mediaSettingsFirstTimeMsg' },
});


type MappedProps = {
  settingsOpen: boolean;
}

const mapStateToProps = (state: State): MappedProps => ({
  settingsOpen: state.settings.device_settings
});


function Icon({ settingsOpen }: { settingsOpen: boolean }) {
  return settingsOpen ?  <IconSettingsSelected size={32} /> : <IconSettings size={32} />;
}


function DesktopLayout({ settingsOpen }: MappedProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const openSettings = React.useCallback(
    () => dispatch(toggleDeviceSettings())
    , [dispatch]
  );

  return (
    <Tooltip title={formatMessage(messages.mediaSettings)}>
      <IconButton onClick={openSettings}>
        <Icon settingsOpen={settingsOpen} />
      </IconButton>
    </Tooltip>
  );
}


const ConnectedDesktopLayout = connect(mapStateToProps)(DesktopLayout);


function MobileLayout({ settingsOpen }: MappedProps) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const openSettings = React.useCallback(
    () => dispatch(toggleDeviceSettings())
    , [dispatch]
  );

  return (
    <MenuItem onClick={openSettings}>
      <ListItemIcon>
        <Icon settingsOpen={settingsOpen} />
      </ListItemIcon>
      <ListItemText primary={formatMessage(messages.mediaSettings)} />
    </MenuItem>
  );
}


const ConnectedMobileLayout = connect(mapStateToProps)(MobileLayout);


function MediaSettings(_props: {}) {
  const { formatMessage } = useIntl();

  if (isMobileOnly) {
    return <ConnectedMobileLayout />;
  }
  else {
    return (
      <FirstTimePopoverDialog
        configKey="mediaSettings"
        message={formatMessage(messages.mediaSettingsFirstTimeMsg)}
      >
        <ConnectedDesktopLayout />
      </FirstTimePopoverDialog>
    );
  }
}


export default MediaSettings;
