import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  GUESTLOGIN_REQUEST,
  GUESTLOGIN_SUCCESS,
  GUESTLOGIN_FAILURE,
  SYNC_TOKEN_LOGIN,
  SYNC_TOKEN_LOGOUT
} from '../actions/auth';


export interface State {
  isFetching: boolean;
  isAuthenticated: boolean;
  isAuthenticatedAsGuest: boolean;
  token: string | null;
  displayName: string | null;
  username: string | null;
  errorCode: number | null;
  errorMessage: string | null;
}


const defaultState: State = {
  isFetching: false,               // auth api request done, waiting for response
  isAuthenticated: false,          // correctly authenticated with username and password
  isAuthenticatedAsGuest: false,   // "authenticated" as guest
  token: null,                     // user token (as platform user or guest)
  displayName: null,               // name and surname if a user, requested display name if guest
  username: null,                  // username
  errorCode: null,                 // auth api error code
  errorMessage: null,              // auth api error message
};


// FIXME: remove any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function auth(state: State = defaultState, action: any = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        username: action.payload.username,
        errorCode: null,
        errorMessage: null,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        isAuthenticatedAsGuest: false,
        token: action.payload.token,
        username: action.payload.username,
        errorCode: null,
        errorMessage: null,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        username: null,
        token: null,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        isAuthenticatedAsGuest: false,
        username: null,
        token: null,
        errorCode: null,
        displayName: null,
        errorMessage: null,
      });
    case GUESTLOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticatedAsGuest: false,
        displayName: action.payload.displayName,
        errorCode: null,
        errorMessage: null,
      });
    case GUESTLOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticatedAsGuest: true,
        token: action.payload.token,
        displayName: action.payload.displayName,
        errorCode: null,
        errorMessage: null,
      });
    case GUESTLOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticatedAsGuest: false,
        displayName: null,
        token: null,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode,
      });
    case SYNC_TOKEN_LOGIN:
      return Object.assign({}, state, {
        token: action.payload.token,
      });
    case SYNC_TOKEN_LOGOUT:
      return state = defaultState;
    default:
      return state;
  }
}


export default auth;
export { defaultState };
