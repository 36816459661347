import React from 'react';

import { connect } from 'react-redux';

import { useIntl, defineMessages } from 'react-intl';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { isRecorder } from '../../lib/reduxSelectors/session';
import { getLogger, LoggerInterface } from '../../lib/logger';
import { State } from '../../lib/reducers';


export interface ConfigBackend {
  markAsDismissed: (key: string) => void;
  isDismissed: (key: string) => boolean;
  clearDismissed: (key: string) => void;
}


// TODO: this class needs to be moved elsewhere when multiple backends will be
// implemented
export class LocalStorageConfigBackend implements ConfigBackend {
  logger: LoggerInterface;

  constructor() {
    this.logger = getLogger('LocalStorageConfigBackend');
  }

  markAsDismissed(key: string) {
    try {
      localStorage.setItem(key, "dismissed");
    }
    catch (e) {
      this.logger.error(`Cannot set key ${key} in localStorage: ${e}`);
    }
  }

  isDismissed(key: string): boolean {
    return localStorage.getItem(key) === "dismissed";
  }

  clearDismissed(key: string) {
    localStorage.removeItem(key);
  }
}


const messages = defineMessages({
  gotIt: { id: 'gotIt' },
});


type OwnProps = {
  message: string;
  configKey: string;
  onDismiss?: () => void;
  configBackend?: ConfigBackend;
  children?: React.ReactNode;
}


type Props = OwnProps & MappedProps


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.secondary.main,
    },
    arrow: {
      color: theme.palette.secondary.main,
    },
    text: {
      color: theme.palette.primary.contrastText,
    },
    button: {
      color: theme.palette.primary.contrastText,
    },
  })
);


function FirstTimePopoverDialog(props: Props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { onDismiss, isRecorder } = props;

  const configKey = `FirstTimePopoverDialog::${props.configKey}`;

  const [dismissed, setDismissed] = React.useState(false);

  const configBackend = props.configBackend ? props.configBackend : new LocalStorageConfigBackend();

  React.useEffect(
    () => {
      const isDismissed = configBackend.isDismissed(configKey);
      setDismissed(isDismissed);
    }
    , [configBackend, configKey]
  );

  const onDismissClicked = React.useCallback(
    () => {
      configBackend.markAsDismissed(configKey);
      setDismissed(true);
      if (onDismiss) {
        onDismiss();
      }
    },
    [setDismissed, configBackend, onDismiss, configKey]
  );

  // wrap children in a component which get the tooltip ref forwarded as per
  // the docs (or the tooltip won't show up if the child is a custom component)
  const ComponentWithForwardedRef: React.ComponentType<Props> =
    React.forwardRef((props: Props, ref?: React.Ref<HTMLSpanElement>) => (
      <span ref={ref}>{props.children}</span>
    ));
  ComponentWithForwardedRef.displayName = "ComponentWithForwardedRef";

  if (isRecorder) {
    return <>{props.children}</>;
  }

  return (
    <Tooltip
      open={!dismissed}
      arrow
      interactive
      classes={{ tooltip: classes.content, arrow: classes.arrow }}
      title={
        <React.Fragment>
          <DialogContent>
            <DialogContentText className={classes.text}>
              {props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onDismissClicked} className={classes.button}>
              {formatMessage(messages.gotIt)}
            </Button>
          </DialogActions>
        </React.Fragment>
      }
    >
      <div>
        <ComponentWithForwardedRef {...props} />
      </div>
    </Tooltip>
  );
}


type MappedProps = {
  isRecorder: boolean;
}


const mapStateToProps = (state: State): MappedProps => {
  return {
    isRecorder: isRecorder(state),
  };
};


export default connect(mapStateToProps)(FirstTimePopoverDialog);
