/* Libraries Imports */
import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
/* UI Imports */
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { IconSleepyCalendar } from '../IconSet';
/* Other Imports */
import style from './styleEmpty';


const messages = defineMessages({
  allDone: { id: 'allDone' },
  allDoneSchedule: { id: 'allDoneSchedule' }
});


type PropsType = WithStyles<typeof style> & { intl: IntlShape };

class Empty extends Component<PropsType, {}> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      scheduleMeetingDialog: false,
    };
  }

  onScheduleClick = () => {
    this.setState({
      scheduleMeetingDialog: true
    });
  }

  onScheduleDialogClose = () => {
    this.setState({ scheduleMeetingDialog: false });
  }

  render() {
    const classes = this.props.classes;

    return (
      <Grid container className={classes.emptyContainer}>
        <Grid item xs={12}>
          <IconSleepyCalendar size={128} color='primary' />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom className={classes.emptyMessage}>
            {this.props.intl.formatMessage(messages.allDone)}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(style)(injectIntl(Empty));
