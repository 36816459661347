/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import URI from 'urijs';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import style from './style';
/* Components Imports */
import BodyLayout from '../../style/bodylayout';
import ExternalRedirect from '../ExternalRedirect';
/* Actions Imports */
/* Other Imports */
import IntlPropType from '../../intl';
import getRouteFor, { Route } from '../../lib/utils/router';


const messages = defineMessages({
  genericOAuth: { id: 'genericOAuth' },
});


function doRedirect(url) {
  if (url && (typeof document !== 'undefined')) {
    window.location = url;
  }
}

function RedirectButton(props) {
  const { config, classes, intl, redirectTo } = props;

  const redirect = React.useCallback(
    () => doRedirect(`/auth/oauth/${config.type}?redirectTo=${redirectTo}`)
    , [config.type, redirectTo]
  );

  const getButtonText = ({ label }) => {
    if (label) {
      return label;
    } else {
      return intl.formatMessage(messages.genericOAuth);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.oauthButton}
      onClick={redirect}
    >
      {getButtonText(config)}
    </Button>
  );
}


RedirectButton.propTypes = {
  intl: IntlPropType.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  oauthConfig: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  redirectTo: PropTypes.string.isRequired,
};


class OAuthLogin extends Component {
  getOAuthButtons = (redirectTo) => {
    const classes = this.props.classes;
    const oauthConfig = this.props.oauthConfig;

    const buttons = oauthConfig.map((config, idx) => {
      const buttonProps = { ...this.props, config, redirectTo };
      return (
        <ListItem key={idx}>
          <RedirectButton {...buttonProps} />
        </ListItem>
      );
    });

    return (
      <Paper className={classes.holocomDialogForm}>
        <List>
          {buttons}
        </List>
      </Paper>
    );
  }

  render() {
    const { oauthConfig } = this.props;

    let redirectTo = getRouteFor(Route.Home);
    const qs = new URI(this.props.history.location.search);
    const goTo = qs.search(true).redirectTo;
    if (goTo) {
      redirectTo = getRouteFor(Route.Meeting, { slug: goTo });
    }

    if (this.props.isAuthenticated) {
      return (
        <Redirect to={{
          pathname: redirectTo
        }} />
      );
    }

    if (oauthConfig && oauthConfig.length === 1) {
      return (
        <ExternalRedirect externalUrl={`/auth/oauth/${oauthConfig[0].type}?redirectTo=${redirectTo}`} />
      );
    }
    else {
      return (
        <BodyLayout fullHeight={true}>
          {this.getOAuthButtons(redirectTo)}
        </BodyLayout>
      );
    }
  }
}


OAuthLogin.propTypes = {
  intl: IntlPropType.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  oauthConfig: PropTypes.array.isRequired,
};


function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    oauthConfig: state.appconfig.oauth,
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(OAuthLogin)));
