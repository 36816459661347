import React  from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import ClosableDialog from '../ClosableDialog';


const messages = defineMessages({
  ok: { id: 'ok' },
  title: { id: 'navigationConfirmationTitle' },
  content: { id: 'navigationConfirmationMessage' },
});


type Props = {
};


type ExtendedProps = Props & RouteComponentProps<{id: string}>;


type NextLocation = Location<{doNotConfirm: boolean}>


function isBlocked(nextLocation: NextLocation) {
  if (!nextLocation) {
    return false;
  }
  if (nextLocation.state && nextLocation.state.doNotConfirm) {
    return false;
  }
  else {
    return true;
  }
}


function BlockNavigation(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const { history } = props;

  const unblockHistory = React.useRef<null | ReturnType<typeof history.block>>(null);

  const [savedLocation, setSavedLocation] = React.useState<null | NextLocation>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const historyRef = React.useRef(history);

  React.useEffect(
    () => {
      if (!unblockHistory.current) {
        unblockHistory.current = historyRef.current.block(
          (nextLocation) => {
            if (isBlocked(nextLocation)) {
              setSavedLocation(nextLocation);
              setDialogOpen(true);
              return false;
            }
          }
        );
        return () => {
          if (unblockHistory.current) {
            unblockHistory.current();
          }
        };
      }
    }
    , [setSavedLocation]
  );

  const dismiss = React.useCallback(
    () => setDialogOpen(false)
    , []
  );

  const navigateAway = React.useCallback(
    () => {
      setDialogOpen(false);
      if (unblockHistory.current) {
        unblockHistory.current();
      }
      if (savedLocation && savedLocation.pathname) {
        history.push(savedLocation.pathname);
      }
    }
    , [history, savedLocation]
  );

  return (
    <ClosableDialog
      open={dialogOpen}
      disableBackdropClick
      onClose={dismiss}
      fullWidth
      title={formatMessage(messages.title)}
    >
      <DialogContent>
        <DialogContentText>
          {formatMessage(messages.content)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={navigateAway} variant='contained' color="primary">
          {formatMessage(messages.ok)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


export default withRouter(BlockNavigation);
