import { UUID, LockedJoinRequest, NotificationEvent } from '../redux_types';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const ADD_LOCKED_JOIN_REQUEST = 'ADD_LOCKED_JOIN_REQUEST';
export const SET_LOCKED_JOIN_REQUESTS = 'SET_LOCKED_JOIN_REQUESTS';
export const REMOVE_LOCKED_JOIN_REQUEST = 'REMOVE_LOCKED_JOIN_REQUEST';
export const CLEAR_LOCKED_JOIN_REQUESTS = 'CLEAR_LOCKED_JOIN_REQUESTS';
export const SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED = 'SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED';

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: {
    ref: UUID;
    notification: NotificationEvent;
  };
}

interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  payload: {
    ref: UUID;
  };
}

interface AddLockedJoinRequestAction {
  type: typeof ADD_LOCKED_JOIN_REQUEST;
  payload: {
    request: LockedJoinRequest;
  };
}

interface SetLockedJoinRequestsAction {
  type: typeof SET_LOCKED_JOIN_REQUESTS;
  payload: {
    requests: LockedJoinRequest[];
  };
}

interface RemoveLockedJoinRequestAction {
  type: typeof REMOVE_LOCKED_JOIN_REQUEST;
  payload: {
    reqId: string;
  };
}

interface ClearLockedJoinRequestsAction {
  type: typeof CLEAR_LOCKED_JOIN_REQUESTS;
  payload: {
  };
}

interface SetLockedJoinRequestDialogMinimizedAction {
  type: typeof SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED;
  payload: {
    minimized: boolean;
  };
}

export type Action =
  AddNotificationAction
  | RemoveNotificationAction
  | AddLockedJoinRequestAction
  | SetLockedJoinRequestsAction
  | RemoveLockedJoinRequestAction
  | ClearLockedJoinRequestsAction
  | SetLockedJoinRequestDialogMinimizedAction;

export function addNotification(reference: UUID, event: NotificationEvent): AddNotificationAction {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      ref: reference,
      notification: event
    }
  };
}

export function removeNotification(reference: UUID): RemoveNotificationAction {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      ref: reference
    }
  };
}

export function addLockedJoinRequest(request: LockedJoinRequest): AddLockedJoinRequestAction {
  return {
    type: ADD_LOCKED_JOIN_REQUEST,
    payload: {
      request: request
    }
  };
}

export function setLockedJoinRequests(requests: LockedJoinRequest[]): SetLockedJoinRequestsAction {
  return {
    type: SET_LOCKED_JOIN_REQUESTS,
    payload: {
      requests: requests
    }
  };
}

export function removeLockedJoinRequest(reqId: string): RemoveLockedJoinRequestAction {
  return {
    type: REMOVE_LOCKED_JOIN_REQUEST,
    payload: {
      reqId: reqId
    }
  };
}

export function clearLockedJoinRequests(): ClearLockedJoinRequestsAction {
  return {
    type: CLEAR_LOCKED_JOIN_REQUESTS,
    payload: {
    }
  };
}

export function setLockedJoinRequestDialogMinimized(minimized: boolean): SetLockedJoinRequestDialogMinimizedAction {
  return {
    type: SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED,
    payload: {
      minimized: minimized
    }
  };
}
