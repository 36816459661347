import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { isMobileOnly } from 'react-device-detect';

import BlockNavigation from './BlockNavigation';
import InviteToMeetingDialog from '../InviteToMeetingDialog';
import JoinErrorDialog from './JoinErrorDialog';
import MeetingLeftDialog from '../MeetingLeftDialog';
import LockedJoinRequestDialog from './LockedJoinRequestDialog';
import MeetingTitle from './MeetingTitle';
import Pip from './Pip';
import Room from './Room';
import WaitingRoom from './WaitingRoom';
import ShortcutKeys from './ShortcutKeys';
import ExpiringMeetingDialog from './ExpiringMeetingDialog';
import DesktopControl from './DesktopControl';

import { State } from '../../lib/reducers';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { showMobileGuestLogin } from '../../lib/reduxSelectors/meeting';
import OwnMeetingControls from '../OwnMeetingControls';
import { toggleInviteParticipantsDialog } from '../../lib/actions/room';
import { isMyPersonalRoom } from '../../lib/reduxSelectors/room';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function shouldSkipWaitingRoom(params: URLSearchParams, loggedIn: boolean) {
  if (!loggedIn) {
    return false;
  }
  const skip = (params.get("skip_device_settings") || "").toLowerCase();
  return ["yes", "true", "on"].includes(skip);
}

function MeetingContent(props: ExtendedProps) {
  const {
    isLoggedIn,
    isRecorder,
    showMobileGuestLogin,
    joinFailed,
    showInviteOnJoin
  } = props;
  const dispatch = useDispatch();

  const queryParams = useQuery();
  const skipWaitingRoom = shouldSkipWaitingRoom(queryParams, isLoggedIn);
  const canJoinRoom = skipWaitingRoom || isRecorder || props.canJoinRoom;

  const showControls = !isMobileOnly && canJoinRoom && !showMobileGuestLogin;

  React.useEffect(
    () => {
      if (canJoinRoom && showInviteOnJoin && !isMobileOnly) {
        dispatch(toggleInviteParticipantsDialog());
      }
    }, [canJoinRoom, dispatch, showInviteOnJoin]
  );

  const getRoomContent = () => {
    return (
      <React.Fragment>
        <Room />
        <ExpiringMeetingDialog  />
        <ShortcutKeys />
        {showControls && <OwnMeetingControls />}
        <InviteToMeetingDialog />
        <MeetingLeftDialog />
        <LockedJoinRequestDialog />
        <BlockNavigation />
        <Pip />
        <DesktopControl />
      </React.Fragment>
    );
  };

  if (joinFailed) {
    return (
      <React.Fragment>
        <MeetingTitle />
        <JoinErrorDialog />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <MeetingTitle />
        {!canJoinRoom && <WaitingRoom />}
        {canJoinRoom && getRoomContent()}
      </React.Fragment>
    );
  }
}


type Props = {
  url: string;
}

type MappedProps = {
  isRecorder: boolean;
  showMobileGuestLogin: boolean;
  isLoggedIn: boolean;
  canJoinRoom: boolean;
  joinFailed: boolean;
  showInviteOnJoin: boolean;
}

const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    isRecorder: isRecorder(state, ownProps.url),
    showMobileGuestLogin: showMobileGuestLogin(state),
    isLoggedIn: state.auth.isAuthenticated || state.auth.isAuthenticatedAsGuest,
    canJoinRoom: state.waitingRoom.canJoinRoom,
    joinFailed: state.websocket.joinFailure,
    showInviteOnJoin: isMyPersonalRoom(state)
  };
};

type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(MeetingContent);
