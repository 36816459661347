import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'typeface-rubik';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { initNotifications } from './lib/notifications';
import { getRaven, startTracer } from './lib/sentry';
import { setLoggerTransport, getLogger } from './lib/logger';
import { checkBrowserSession } from './lib/api/session';
import { retriveStoredSettings } from './lib/api/settings';
import { rootReducer } from './lib/reducers';
import { defaultState as authDefaultState } from './lib/reducers/auth';
import { defaultState as websocketDefaultState } from './lib/reducers/websocket';
import { defaultState as roomDefaultState } from './lib/reducers/room';
import { defaultState as waitingRoomDefaultState } from './lib/reducers/waitingRoom';
import { defaultState as sessionDefaultState } from './lib/reducers/session';
import { defaultState as appconfigDefaultState } from './lib/reducers/appconfig';
import { setApplicationEnvironment } from './lib/utils/environment';
import { setAppConfig } from './lib/actions/appconfig';

import './index.css';
import App from './App';
import TabTitle from './tabTitle';
import LocalStorage from './localStorage';

import 'flat-map-polyfill';

import { composeWithDevTools } from 'redux-devtools-extension';
import { LockedJoinRequest, State } from './lib/redux_types';


declare global {
  interface Window {
    reduxStore: Store;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof composeWithDevTools;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callstats: any;
  }
}


let reduxDebugEnabled = false;


function fetchConfiguration() {
  const fetchConfig: RequestInit = {
    method: 'GET',
    mode: 'cors',
    cache: "default",
    credentials: 'same-origin'
  };

  return fetch("/_holocom_config.json", fetchConfig).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Cannot fetch configuration.');
  });
}


function setWindowTitle(config: { site_title?: string }) {
  const title = config.site_title || "Unconfigured Holocom Server";
  new TabTitle(title);
}


function setupStores(debug: boolean, sessionToken: string | null) {
  let token;
  const localStore = new LocalStorage();
  if (sessionToken) {
    token = sessionToken;
    localStore.saveToken(token);
  } else {
    token = localStore.loadToken();
  }

  const initialState = {
    auth: Object.assign({}, authDefaultState, { isAuthenticated: false, token: token }),
    settings: retriveStoredSettings(localStore),
    websocket: websocketDefaultState,
    room: roomDefaultState,
    waitingRoom: waitingRoomDefaultState,
    session: sessionDefaultState,
    // eslint-disable-next-line @typescript-eslint/camelcase
    invite_participants: undefined,
    relay: undefined,
    notifications: undefined,
    appconfig: appconfigDefaultState,
  };

  let composeEnhancers = compose;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionSanitizer = (action: any) => {
    if (action.type === 'ADD_LOCKED_JOIN_REQUEST' && action.payload && action.payload.snapshot) {
      const payload = {
        ...action.payload, snapshot: action.payload.request.snapshot.substring(0, 100)
      };
      return { ...action, payload: payload };
    } else if (action.type === 'SET_LOCKED_JOIN_REQUESTS' && action.payload && action.payload.requests) {
      const payload = {
        ...action.payload, requests: action.payload.requests.map((req: LockedJoinRequest) => {
          if (req.snapshot)
            return { ...req, snapshot: req.snapshot.substring(0, 100) };
          return req;
        })
      };
      return { ...action, payload: payload };
    } else if (action.type === 'STORE_SNAPSHOT' && action.payload && action.payload.snapshot) {
      const payload = {
        ...action.payload, snapshot: action.payload.snapshot.substring(0, 100)
      };
      return { ...action, payload: payload };
    } else {
      return action;
    }
  };

  if (reduxDebugEnabled && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer,
      stateSanitizer: (state: State) => {
        const requests =
          state.notifications &&
          state.notifications.lockedJoinRequests &&
          state.notifications.lockedJoinRequests.map((req: LockedJoinRequest) => {
            if (req.snapshot)
              return { ...req, snapshot: req.snapshot.substring(0, 100) };
            return req;
          });
        const notifications = { ...state.notifications, lockedJoinRequests: requests };
        let waitingRoom = { ...state.waitingRoom };
        if (waitingRoom.snapshot) {
          waitingRoom = { ...state.waitingRoom, snapshot: waitingRoom.snapshot.substring(0, 100) };
        }
        return { ...state, notifications: notifications, waitingRoom: waitingRoom };
      }
    });
  }

  const store = createStore(rootReducer, initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware)));

  if (debug) {
    window.reduxStore = store;
  }

  return { store, localStore };
}


function runBrowserSession(lang: string) {
  setLoggerTransport(window.console);

  fetchConfiguration().then((holoConfig) => {
    // set window title
    setWindowTitle(holoConfig);

    // start application
    checkBrowserSession().then((res) => {
      const isGuest = res.is_guest;
      const token = isGuest ? null : res.token;
      const stores = setupStores(reduxDebugEnabled, token);
      stores.store.dispatch(setAppConfig(holoConfig));
      startApp(stores.store, stores.localStore, lang);
    }).catch(() => {
      const stores = setupStores(reduxDebugEnabled, null);
      stores.store.dispatch(setAppConfig(holoConfig));
      startApp(stores.store, stores.localStore, lang);
    });
  });
}


function startApp(store: Store, localStore: LocalStorage, lang: string) {
  getRaven().context(() => {
    // start notification subsystem
    startNotificationsSubsystem(store);

    ReactDOM.render(
      <Provider store={store}>
        <App lang={lang} localStore={localStore} />
      </Provider>,
      document.getElementById('root'));
  });
}


function startNotificationsSubsystem(store: Store) {
  const logger = getLogger('Notifications');
  initNotifications(store.dispatch, logger);
}


/////////////////////////////////////
// BROWSER APPLICATION ENTRY POINT //
/////////////////////////////////////

// setup debug tools
if (process.env.NODE_ENV === 'development') {
  setApplicationEnvironment('dev');
  /* trace on devel sentry project */
  startTracer('https://da750a419a014e8cb5887d75fe08552f@sentry.voismart.net/3');
  reduxDebugEnabled = true;
} else {
  /* trace on production sentry project */
  setApplicationEnvironment('prod');
  startTracer('https://ed479faefeeb4f8ab04ee6418fcf8aeb@sentry.voismart.net/2');
  reduxDebugEnabled = true;  // for now also enabled in production mode
}

// setup moment locale
let lang = navigator.language.toLowerCase().split(/[_-]+/)[0];
if (lang !== 'it') {
  lang = 'en';
}

//setup locale
moment.locale(lang === 'it' ? 'it' : 'en-gb');

// start app
runBrowserSession(lang);
