/* Libraries Imports */
import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
/* Components imports */
import DialogMultiLineText from '../DialogMultiLineText';
/* Other imports */
import IntlPropType from '../../intl';
import prepareWebRtcProvider from '../../rtc';
import { RtcDevices } from '../../lib/api/rtcDevices';
import { getLogger } from '../../lib/logger';
/* Local Style */
import style from './style';

const messages = defineMessages({
  info: { id: 'mediaPermissionsMessage' },
  title: { id: 'mediaPermissionsTitle' },
  blockedTitle: { id: 'mediaPermissionsBlockedTitle' },
  permErr: { id: 'mediaPermissionsPermsErr' },
  genErr: { id: 'mediaPermissionsGenErr' },
});

class MediaPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: null,
      content: null
    };
  }

  componentDidMount() {
    this.checkMediaPermissions();
  }

  checkMediaPermissions() {
    const logger = getLogger('MediaDevices Enumeration');
    const webrtc = prepareWebRtcProvider();
    const rtc = new RtcDevices(webrtc, logger);
    rtc.permissionsGranted().then((res) => this.shouldShowInfoDialog(res));
  }

  shouldShowInfoDialog(bool) {
    if (bool) {
      this.showInfo();
      this.askPermissions();
      return;
    }
    this.setState({ show: false });
  }

  askPermissions() {
    const logger = getLogger('MediaDevices getUserMedia');
    const webrtc = prepareWebRtcProvider();
    const rtc = new RtcDevices(webrtc, logger);
    rtc.askFullPermissions().then(
      () => this.shouldShowInfoDialog(false)
    ).catch(
      (e) => {
        if (e.name === 'NotAllowedError') {
          this.showNotAllowedError();
        }
        else {
          rtc.askAudioOnlyPermissions().then(
            () => this.shouldShowInfoDialog(false)
          ).catch((e) => this.onPermissionsError(e));
        }
      }
    );
  }

  onPermissionsError(err) {
    if (err.name === "NotAllowedError") {
      this.showNotAllowedError();
    } else {
      this.showGenericError();
    }
  }

  showGenericError() {
    const content = (
      <DialogContent>
        <DialogMultiLineText
          message={this.props.intl.formatMessage(messages.genErr)}
        />
      </DialogContent>
    );
    this.setState({
      show: true,
      content: content,
      title: this.props.intl.formatMessage(messages.blockedTitle)
    });
  }

  showNotAllowedError() {
    const content = (
      <DialogContent>
        <DialogMultiLineText
          message={this.props.intl.formatMessage(messages.permErr)}
        />
      </DialogContent>
    );
    this.setState({
      show: true,
      content: content,
      title: this.props.intl.formatMessage(messages.blockedTitle)
    });
  }

  showInfo() {
    const content = (
      <DialogContent>
        <DialogMultiLineText
          message={this.props.intl.formatMessage(messages.info)}
        />
      </DialogContent>
    );
    this.setState({
      show: true,
      content: content,
      title: this.props.intl.formatMessage(messages.title)
    });
  }

  render() {
    return (
      <Dialog
        open={this.state.show}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='md'
      >
        <DialogTitle>
          {this.state.title}
        </DialogTitle>
        {this.state.content}
      </Dialog>
    );
  }
}


MediaPermissions.propTypes = {
  intl: IntlPropType.isRequired,
};

export default withStyles(style)(injectIntl(MediaPermissions));
