import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import ClosableDialog from '../ClosableDialog';
/* Other imports */
import getRouteFor, { Route } from '../../lib/utils/router';
import { logoutUser } from '../../lib/actions/auth';
import { State as AuthState } from '../../lib/reducers/auth';
import LocalStorage from '../../localStorage';


const messages = defineMessages({
  ok: { id: 'ok' },
  cancel: { id: 'cancel' },
  title: { id: 'logout' },
  body: { id: 'logoutDialogMessage' },
});


type MappedProps = {
  isAuthenticated: boolean;
  authToken: string | null;
}


type Props = {
  localStore: LocalStorage;
  onLogoutClick: () => void;
  onCancelClick: () => void;
} & MappedProps;



type State = {
  auth: AuthState;
}


const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout: (authToken, localStore) => logoutUser(authToken, localStore)
    },
    dispatch
  );


const mapStateToProps = (state: State): MappedProps => ({
  isAuthenticated: state.auth.isAuthenticated,
  authToken: state.auth.token,
});


type ExtendedProps = Props
  & {intl: IntlShape}
  & RouteComponentProps
  & ReturnType<typeof mapDispatchToProps>;


class Logout extends Component<ExtendedProps> {
  handleCancel = () => {
    this.props.onCancelClick();
  }

  handleLogout = () => {
    this.props.logout(this.props.authToken, this.props.localStore);
    this.props.onLogoutClick();
    this.props.history.push(getRouteFor(Route.Home), { doNotConfirm: true });
  }

  render() {
    return (
      <ClosableDialog
        open={true}
        title={this.props.intl.formatMessage(messages.title)}
        onClose={this.handleCancel}
      >
        <DialogContent>
          <DialogContentText>
            {this.props.intl.formatMessage(messages.body)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={this.handleLogout} color="primary">
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </ClosableDialog>
    );
  }
}


export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout)));
