import { createSelector } from 'reselect';
import moment from 'moment';

import { ChatMessages, ChatMessageClass, Roster, PrivateUserMessages } from '../../lib/redux_types';


export type AggregateChatMessage = {
  class: ChatMessageClass;
  from: string;
  displayName: string;
  messages: ChatMessages;
  timestamp: number;
}


export function groupByAuthor(messages: ChatMessages): AggregateChatMessage[] {
  type Accumulator = {
    result: AggregateChatMessage[];
    lastEl: null | AggregateChatMessage;
  }

  const initialAcc: Accumulator = { result: [], lastEl: null };
  const res = messages.reduce(
    (acc, el) => {
      if (!acc.lastEl) {
        const lastEl = {
          ...{
            class: el.class,
            from: el.data.from,
            displayName: el.data.fromDisplayName,
            timestamp: el.data.timestamp
          },
          messages: [el]
        };
        return { result: acc.result, lastEl: lastEl };
      }
      else if (el.data.from === acc.lastEl.from
        && (moment.unix(el.data.timestamp).minutes() === moment.unix(acc.lastEl.timestamp).minutes()
          || el.class === 'event')) {
        acc.lastEl.messages.push(el);
        return { result: acc.result, lastEl: acc.lastEl };
      }
      else {
        const lastEl = {
          ...{
            class: el.class,
            from: el.data.from,
            displayName: el.data.fromDisplayName,
            timestamp: el.data.timestamp
          },
          messages: [el]
        };
        return { result: [...acc.result, acc.lastEl], lastEl: lastEl };
      }
    }
    , initialAcc
  );

  if (res.lastEl) {
    res.result.push(res.lastEl);
  }
  return res.result;
}

/**
 * Required to let each component have its own copy of the selector
 * which cannot be shared among components
 * https://redux.js.org/recipes/computing-derived-data#sharing-selectors-across-multiple-components
 */
export const makeGetChatMessages = () => {
  return createSelector(
    [groupByAuthor],
    (messages) => messages
  );
};


function _canSendPrivateMessage(roster: Roster): boolean {
  const rosterWithoutPhones = Object.entries(roster).filter(([_k, v]) => !v.viaPhone);
  return rosterWithoutPhones.length > 1;
}

export const canSendPrivateMessage = createSelector(
  (roster: Roster) => roster,
  _canSendPrivateMessage
);


const _getPrivateMessagesKeys = (privateMessages: PrivateUserMessages): string[] => {
  return Object.keys(privateMessages);
};

export const getPrivateMessagesKeys = createSelector(
  [_getPrivateMessagesKeys],
  (privateMessages) => privateMessages
);
