/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators, Dispatch } from 'redux';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { createRefetchContainer, RelayRefetchProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
/* UI Imports */
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
/* Local Imports */
import Details from './Details';
import Calendar from '../Calendar';
import { FirstTimePopoverDialog } from '../PopoverDialog';
/* Other Imports */
import { RELAY_DONE } from '../../lib/actions/relay';
import { State as ReduxState } from '../../lib/reducers';
import { relayOperation, DELETE_MEETING, SCHEDULE_MEETING } from '../../lib/utils/relayOperation';
import { deleteMeeting } from '../../lib/api/relay/deleteMeeting';
import { deleteMeetingAs } from '../../lib/api/relay/deleteMeetingAs';
import { generateRequestId } from '../../lib/utils/requestId';
import { View_data as ViewDataFragment } from '../../lib/api/relay/__generated__/View_data.graphql';
import { myMeetings } from '../../lib/api/relay/myMeetings';
import { getLogger, LoggerInterface } from '../../lib/logger';
import homeStyles from '../../style/HomePadding';
import classNames from 'classnames';
import combineStyles from '../../lib/utils/combineStyle';
/* Local Style */
import style from './style';

type myMeetingsT = NonNullable<ViewDataFragment["loggedUser"]["meetings"]>;
type meetingEdges = NonNullable<myMeetingsT>["edges"];
type meetingEdge = NonNullable<meetingEdges>[0];

export type Meeting = NonNullable<NonNullable<meetingEdge>["node"]>;

const messages = defineMessages({
  calendarFirstTimeMsg: { id: 'calendarFirstTimeMsg' },
});

interface State {
  selectedMeeting: Array<Meeting>;
  selectedDay: moment.Moment | null;
  selectedMonth: moment.Moment;
}

type Props = {
  data: ViewDataFragment;
}

type MappedProps = {
  relayEvents: ReduxState['relay'];
  myUserId: ReduxState['session']['userId'];
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch)
  };
}

type withStyleProps = keyof ReturnType<typeof style> | keyof ReturnType<typeof homeStyles>;

const combinedStyles = combineStyles(style, homeStyles);

type PropsType = Props
  & WithStyles<withStyleProps>
  & ReturnType<typeof mapDispatchToProps>
  & MappedProps
  & { intl: IntlShape; relay: RelayRefetchProp };

const mapStateToProps = (state: ReduxState): MappedProps => ({
  relayEvents: state.relay,
  myUserId: state.session.userId
});


class View extends Component<PropsType, State> {
  logger: LoggerInterface;

  // Component callbacks
  constructor(props: PropsType) {
    super(props);

    this.logger = getLogger('Meeting View');
    this.onCalendarDayClick = this.onCalendarDayClick.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);

    this.state = {
      selectedMeeting: [],
      selectedDay: null,
      selectedMonth: moment(),
    };
  }

  componentDidMount = () => {
    this.showMeeting();
  }

  componentDidUpdate = (prevProps: PropsType) => {
    this.checkRelayOps(prevProps, this.props);
  }

  showMeeting = () => {
    const meetings = this.getData();
    const realMeetings = meetings.reduce<Meeting[]>((filtered, meeting) => {
      if (meeting && meeting.node) {
        filtered.push(meeting.node);
      }
      return filtered;
    }, []);
    this.setState({
      selectedMeeting: realMeetings
    });

  }

  handleDeleteMeeting = (meeting: Meeting) => {
    const environment = this.props.relay.environment;
    const reqId = generateRequestId();

    const { id, userId, domainId } = meeting;

    const deleteAction = userId === this.props.myUserId
      ? deleteMeeting
      : deleteMeetingAs;

    relayOperation(
      this.props.dispatch,
      () => deleteAction(environment, id, userId, domainId),
      reqId,
      DELETE_MEETING
    );
  }

  getSelectedMeeting = () => { return this.state.selectedMeeting; }

  getSelectedDay = () => { return this.state.selectedDay; }

  buildQueryFilter = () => {
    const queryFilter = [];
    const endMonthDay = this.state.selectedMonth.clone().endOf('month');
    const startMonthDay = this.state.selectedMonth.clone().startOf('month');
    queryFilter.push({ match: "GTE", key: "dtStart", value: startMonthDay.toISOString() });
    queryFilter.push({ match: "LTE", key: "dtEnd", value: endMonthDay.toISOString() });
    return queryFilter;
  }

  onMonthClick = (selectedMonth: moment.Moment) => {
    this.setState({ selectedMonth: selectedMonth, selectedDay: null }, () =>
      this.refreshMeetings()
    );
  }

  onCalendarDayClick = (meetings: Array<Meeting>, refresh: boolean, selectedDay: moment.Moment | null) => {
    this.setState({ selectedDay: selectedDay });

    if (refresh === true) {
      this.refreshMeetings();
    }
    else {
      this.setState({ selectedMeeting: meetings });
    }
  }

  getData = () => {
    if (this.props.data && this.props.data.loggedUser.meetings && this.props.data.loggedUser.meetings.edges) {
      return this.props.data.loggedUser.meetings.edges.filter(
        (edge, _index) => {
          if (edge && edge.node) {
            return true;
          }
          else {
            return false;
          }
        }

      );
    } else {
      return [];
    }
  }

  checkRelayOps = (prevProps: PropsType, props: PropsType) => {
    const prevRelayOp = prevProps.relayEvents || {};
    const relayOp = props.relayEvents || {};
    const triggerOps = [SCHEDULE_MEETING, DELETE_MEETING];

    if (relayOp.type === RELAY_DONE && prevRelayOp.type !== relayOp.type
      && triggerOps.indexOf(relayOp.operation) >= 0) {
      this.setState({ selectedDay: null });
      this.refreshMeetings();
    }
  }

  refreshMeetings = () => {
    const filters = this.buildQueryFilter();
    const refetchVariables = (fragmentVariables: ViewDataFragment) => ({
      ...fragmentVariables,
      //first: this.props.pageSize,
      after: null,
      filters: filters
    });

    this.props.relay.refetch(
      refetchVariables,
      null,
      (errors: Error | null | undefined) => {
        this.showMeeting();
        if (errors) {
          this.logger.error("Error while refreshing meetings:", errors);
        }
      });
  }

  renderList = () => {
    const classes = this.props.classes;

    const meetings = this.getData().reduce<Meeting[]>((filtered, meeting) => {
      if (meeting && meeting.node) {
        filtered.push(meeting.node);
      }
      return filtered;
    }, []);
    return (
      <div>
        <Grid container className={classNames(classes.meetingListGridContainer, classes.homePadding)}>
          <Grid item xs={12} sm={6} className={classes.calendarContainer} >
            <Calendar onMonthClick={this.onMonthClick} onDayClick={this.onCalendarDayClick} meetings={meetings} />
            <div className={classes.popupContainer}>
              <FirstTimePopoverDialog
                configKey="calendar"
                message={this.props.intl.formatMessage(messages.calendarFirstTimeMsg)}
              >
              </FirstTimePopoverDialog>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.meetingListDetails}>
            <Details
              meetingDetails={this.getSelectedMeeting()}
              onDelete={this.handleDeleteMeeting}
              selectedDay={this.getSelectedDay()}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return this.renderList();
  }
}

export default createRefetchContainer(
  withStyles<withStyleProps>(combinedStyles, { withTheme: true })(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(View))),
  {
    data: graphql`
  fragment View_data on RootQueryType
  @argumentDefinitions(
    first: {type: "Int"}
    after: {type: "String"}
    last: {type: "Int"}
    before: {type: "String"}
    sorting: {type: "[SearchSort]"}
    filters: {type: "[SearchFilter]"}
  ){
    loggedUser {
      meetings(
        first: $first, after: $after,
        last: $last, before: $before,
        sorting: $sorting, filters: $filters
      ){
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            title,
            slug,
            dtStart,
            dtEnd,
            id,
            userId,
            organizerId,
            domainId,
            notes,
            type,
            meta {
              privacy
            }
            attendees {
              email
            },
          }
        }
      }
    }
  }
  ` },
  myMeetings
);
