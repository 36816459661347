import { graphql } from 'babel-plugin-relay/macro';

const myRecordingsQuery = graphql`
query myRecordingsQuery ($count: Int!,
  $after: String
  $sorting: [SearchSort]
  $filters: [SearchFilter]
) {
  ...myRecordings
}
`;

const myRecordingsQueryForward = graphql`
query myRecordingsForwardQuery (
  $count: Int!,
  $after: String,
  $sorting: [SearchSort],
  $filters: [SearchFilter]
) {
    ...myRecordings
}
`;

const myRecordingsFragment = graphql`
fragment myRecordings_recording on Recording {
  id
  status
  recStart
  recEnd
  slug
  title
  notes
  number
}
`;

const myRecordingsFragmentList = graphql`
fragment myRecordings on RootQueryType {
  myRecordings(first: $count,
              after: $after,
              sorting: $sorting,
              filters: $filters
    ) @connection(key: "RecordingList_myRecordings", filters:["SearchFilter", "SearchSort"]) {
    edges {
      node {
        ...myRecordings_recording
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`;

export { myRecordingsQuery, myRecordingsFragmentList, myRecordingsFragment, myRecordingsQueryForward };
