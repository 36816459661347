import React from 'react';


// hook to store the previous value of a prop in a functional react component.
// This is the hook equivalent of the componentDidUpdate lifecycle method
// example use:
//   const myPrevProp = usePrevious(props.myProp, 0);
//   const myCurrentProp = props.myProp;
//   React.useEffect(
//     () => {
//       if (myCurrentProp !== myPrevProp) {
//         // ...
//       }
//     },
//     [myCurrentProp, myPrevProp]
//   );
export function usePrevious<T>(value: T, initialValue: T) {
  const ref = React.useRef<T>(initialValue);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
