import moment from 'moment';

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { State } from '../../lib/reducers';
import { expiringMeetingAcked, extendMeeting } from '../../lib/actions/room';

import ClosableDialog from '../ClosableDialog';


const messages = defineMessages({
  extend: { id: 'extend' },
  meetingIsExpiring: { id: 'meetingIsExpiring' },
  meetingWillExpireSoon: { id: 'meetingWillExpireSoon' },
  meetingWillExpireIn: { id: 'meetingWillExpireIn' },
});


function ExtendButton(_props: {}) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const extend = React.useCallback(
    () => {
      dispatch(extendMeeting());
      dispatch(expiringMeetingAcked());
    },
    [dispatch]
  );
  return (
    <Button onClick={extend} variant='contained' color='primary'>
      {formatMessage(messages.extend)}
    </Button>
  );
}


function ExpiringMeetingDialog(props: Props) {
  const { isExpiring, willExpireAt } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const dismiss = React.useCallback(
    () => {
      if (isExpiring) {
        dispatch(expiringMeetingAcked());
      }
    }
    , [dispatch, isExpiring]
  );

  const renderMsg = React.useCallback(
    () => {
      if (willExpireAt) {
        const willExpireIn = moment(willExpireAt).fromNow();
        if (moment().diff(moment(willExpireAt)) >= 0) {
          // if the expiry is in the past, the client clock may be disaligned,
          // so just show a generic message
          return formatMessage(messages.meetingWillExpireSoon);
        }
        return formatMessage(messages.meetingWillExpireIn, { time: willExpireIn });
      }
      else {
        // should not happen
        return '';
      }
    },
    [willExpireAt, formatMessage]
  );

  if (!isExpiring) {
    return null;
  }

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={dismiss}
      fullWidth
      title={formatMessage(messages.meetingIsExpiring)}
    >
      <DialogContent>
        <DialogContentText>
          {renderMsg()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ExtendButton />
      </DialogActions>
    </ClosableDialog>
  );
}


type MappedProps = {
  isExpiring: boolean;
  willExpireAt: Date | null;
}


const mapStateToProps = (state: State): MappedProps => {
  const isExpiring = Boolean(state.room.meetingExpiration.isExpiring);
  return {
    isExpiring,
    willExpireAt: isExpiring ? state.room.meetingExpiration.willExpireAt : null,
  };
};

type Props = {
} & MappedProps


export default (connect(mapStateToProps)(ExpiringMeetingDialog));
