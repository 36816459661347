import React from 'react';
import Button from '@material-ui/core/Button';
import { IconEmail } from '../IconSet';
import InviteEmailDialog from './InviteEmailDialog';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

const messages = defineMessages({
  inviteEmail: { id: 'inviteEmail' }
});

type Props = { intl: IntlShape };

function InviteEmailButton(props: Props) {
  const { intl } = props;
  const [inviteDialogOpen, setInviteDialogOpen] = React.useState<boolean>(false);

  const onClick = React.useCallback(
    () => {
      setInviteDialogOpen(true);
    }
    , []
  );

  const onCloseDialog = React.useCallback(
    () => {
      setInviteDialogOpen(false);
    }
    , []
  );
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<IconEmail size={24} />}
        onClick={onClick}
      >
        {intl.formatMessage(messages.inviteEmail)}
      </Button>
      {
        inviteDialogOpen &&
        <InviteEmailDialog
          open={inviteDialogOpen}
          onClose={onCloseDialog}
        />}
    </div>

  );
}
export default (injectIntl(InviteEmailButton));
