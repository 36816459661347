import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';

import Avatar from '../AvatarNew';
import RippleBadge from './RippleBadge';
import useStyles from './style';
import { IconShareScreen, IconHandUp } from '../IconSet';
import { Avatar as MuiAvatar, IconButton, Badge } from '@material-ui/core';
import { State } from '../../lib/reducers';
import VideoElement from '../VideoElement';
import { iconColors } from '../../colors';

import { showStream } from '../../lib/utils/mobile';
import { toggleVideoMute } from '../../lib/actions/room';
import { amModerator } from '../../lib/reduxSelectors/room';


interface Props {
  name: string;
  uid: string;
  isVisible: boolean;
}

type MappedProps = {
  screen: MediaStream | null;
  isTalking: boolean;
  stream: MediaStream | null;
  isVideoMuted: boolean;
  isScreenMuted: boolean;
  myself: boolean;
  isFeatured: boolean;
  featuredType: State['room']['layoutConfig']['featured_type'];
  hasHandRaised: boolean;
  amIModerator: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State, { uid }: Props): MappedProps => {
  const myUid = state.websocket.uid;
  let screen: MediaStream | null = null;
  let stream: MediaStream | null = null;
  const isTalking = state.room.roster[uid].is_talking;
  const isVideoMuted = state.room.roster[uid].isVideoMuted;
  const isScreenMuted = state.room.roster[uid].isScreenMuted;
  const myself = myUid === uid;
  const isFeatured =  state.room.layoutConfig.featured_id === uid;
  const featuredType = state.room.layoutConfig.featured_type;
  const hasHandRaised = (state.room.roster[uid] || { raisedHand: false }).raisedHand;
  const amIModerator = amModerator(state);
  if (myUid === uid) {
    stream = state.room.localvideo_stream;
    screen = state.room.screenStream;
  } else {
    stream = state.room.roster[uid].stream;
    screen = state.room.roster[uid].screen;
  }
  return ({
    screen,
    isTalking,
    stream,
    isVideoMuted,
    isScreenMuted,
    myself,
    isFeatured,
    featuredType,
    hasHandRaised,
    amIModerator
  });
};


function RosterAvatar(props: ExtendedProps) {
  const classes = useStyles();
  const {
    isTalking,
    isVisible,
    myself,
    name,
    screen, 
    stream,
    isVideoMuted,
    isScreenMuted,
    uid,
    isFeatured,
    featuredType,
    hasHandRaised,
    amIModerator
  } = props;

  const dispatch = useDispatch();

  React.useEffect(
    () => {
      if (!myself && isVisible !== null && isMobileOnly) {
        if (stream && !isVideoMuted) {
          const isStreamFeatured = isFeatured && featuredType === 'stream';
          const hideStream = (isVisible) ? false : !isStreamFeatured;
          dispatch(toggleVideoMute(uid, hideStream));
        }
        if (screen && !isScreenMuted) {
          const isScreenFeatured = isFeatured && featuredType === 'screen';
          const hideScreen = (isVisible) ? false : !isScreenFeatured;
          dispatch(toggleVideoMute(`${uid}_screen`, hideScreen));
        }
      }
    }, [myself, stream, screen, isVideoMuted, isScreenMuted, isFeatured, featuredType, isVisible, uid, dispatch]
  );

  const onSelectStream = () => showStream('stream', uid, dispatch);
  const onSelectScreen = () => showStream('screen', uid, dispatch);

  const getScreenIcon = () => {
    return (
      <div className={classes.badgeContent}>
        <IconButton
          onClick={onSelectScreen}
          className={classes.screenButton}
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          disabled={!isMobileOnly}
          size='small'>
          <IconShareScreen size={20} />
        </IconButton>
      </div>
    );
  };

  const getVideoElement = () => {
    return (
      <div onClick={onSelectStream}>
        <VideoElement
          user={uid}
          mirrored={myself ? true : false}
          addVideoMutedIconOverlay={false}
          src={stream}
          circled={true}
        />
      </div>
    );
  };

  const getStateOverlay = () => {
    return (
      <React.Fragment>
        { hasHandRaised &&
          <div className={classes.stateContainer}>
            <MuiAvatar className={classes.stateAvatar}>
              <IconHandUp size={22} color={iconColors.normal}/>
            </MuiAvatar>
          </div>
        }
      </React.Fragment>
    );
  };

  const getVideoAvatar = () => {
    return (
      <div style={{ position: "relative" }}>
        { (amIModerator || myself) && getStateOverlay() }
        <React.Fragment>
          {isMobileOnly && stream && !isVideoMuted
            ? getVideoElement()
            : <Avatar name={name} />
          }
        </React.Fragment>
      </div>
    );
  };

  const getScreenBadge = () => {
    return (
      <Badge
        classes={{ badge: classes.badgeStyle }}
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={getScreenIcon()}
      >
        {getVideoAvatar()}
      </Badge>
    );
  };

  const getAvatar = () => {
    return (
      <React.Fragment>
        {screen
          ? getScreenBadge()
          : getVideoAvatar()
        }
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      {getAvatar()}
      {isTalking &&
        <RippleBadge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          classes={{ badge: classes.badge }}
        >
        </RippleBadge>
      }
    </div>
  );
}


export default connect(mapStateToProps)(RosterAvatar);
