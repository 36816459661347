import { UUID, NotificationPayload } from '../redux_types';
import uuid from 'uuid';
import { addNotification, removeNotification, Action as NotificationAction } from '../actions/notifications';
import { Dispatch } from 'react';
import { LoggerInterface } from '../logger';

export const DEBUG = Symbol('DEBUG');
export const INFO = Symbol('INFO');
export const WARNING = Symbol('WARNING');
export const ERROR = Symbol('ERROR');

let _instance: Notifications;

class Notifications {
  _dispatch: Dispatch<NotificationAction>;
  _logger: LoggerInterface;

  constructor(dispatch: Dispatch<NotificationAction>, logger: LoggerInterface) {
    this._dispatch = dispatch;
    this._logger = logger;
  }

  newEvent(priority: symbol, code: number | string, reason: string, message?: string, payload?: NotificationPayload) {
    const event = { prio: priority, code: code, reason: reason, message: message, payload: payload || {} };
    const ref = uuid.v4();
    this.expireEvent(ref);

    this._logger.debug("Dispatching notification ", event, ref);

    this._dispatch(addNotification(ref, event));
  }

  expireEvent(ref: UUID) {
    setTimeout(() => {
      this._logger.debug("Starting expire event for notification", ref);
      this._dispatch(removeNotification(ref));
    }, 5000);
  }
}

export function newEvent(
  priority: symbol,
  code: number | string,
  reason: string,
  message?: string,
  payload?: NotificationPayload) {
  if (_instance) {
    _instance.newEvent(priority, code, reason, message, payload);
  }
}

export function initNotifications(dispatch: Dispatch<NotificationAction>, logger: LoggerInterface) {
  if (!_instance) {
    logger.info("Started notifications subsystem");
    _instance = new Notifications(dispatch, logger);
  }
  return _instance;
}

