import lodash from 'lodash';
import { State } from '../reducers';
import { RosterUser } from '../redux_types';
import { createDeepEqualitySelector } from '.';
import {
  isAudioOnlyLayout,
  isThereAnyVideo,
  isWebinarLayout,
  isLessonLayout,
  amModerator
} from './room';
import { isMobileOnly } from 'react-device-detect';


export type ObservedUser = {
  stream: RosterUser['stream'];
  screen: RosterUser['screen'];
  display: RosterUser['display'];
  viaPhone: RosterUser['viaPhone'];
  username: RosterUser['username'];
  role: RosterUser['role'];
  raisedHand: RosterUser['raisedHand'];
  raisedHandSince: RosterUser['raisedHandSince'];
}

export type ObservedRoster = { [k: string]: ObservedUser }

const _getRoster = (state: State): ObservedRoster => {
  const wantedKeys = ['stream', 'screen', 'display', 'viaPhone', 'username', 'role', 'raisedHand', 'raisedHandSince'];
  const roster = state.room.roster;
  const users = Object.keys(roster);
  const observedRoster: ObservedRoster = {};
  for (const user of users) {
    const obsUser = lodash.pick(roster[user], wantedKeys);
    observedRoster[user] = obsUser as ObservedUser;
  }
  return observedRoster;
};

export const getRoster = createDeepEqualitySelector([_getRoster],  (roster) => roster);

const _getNumberOfRaisedHands = (state: State): number => {
  return Object.values(_getRoster(state)).filter(u => u.raisedHand).length;
};

export const getNumberOfRaisedHands = createDeepEqualitySelector([_getNumberOfRaisedHands],  (raised) => raised);

const _getLatestRaisedHand = (state: State): ObservedUser | null => {
  if (_getNumberOfRaisedHands(state) > 0) {
    return Object.values(_getRoster(state))
      .reduce((max, raised) => (max.raisedHandSince > raised.raisedHandSince) ? max : raised);
  } else {
    return null;
  }
};

export const getLatestRaisedHand = createDeepEqualitySelector([_getLatestRaisedHand],  (latest) => latest);

export const disableRoster = (state: State): boolean => {
  const amIModerator = amModerator(state);
  return !isThereAnyVideo(state)
    || (isWebinarLayout(state) && !amIModerator)
    // on mobile we do not disable roster bacause we need to allow students to switch between moderators' streams
    // which are also the only entries available in roster on mobile devices
    || (!isMobileOnly && isLessonLayout(state) && !amIModerator)
    || isAudioOnlyLayout(state);
};

export const hideChatUsersAndMessages = (state: State): boolean => {
  return !amModerator(state) && (isWebinarLayout(state) || isLessonLayout(state));
};
