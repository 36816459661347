import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { IconEsc } from '../IconSet';
import useStyles, { StyledChip } from './style';


const messages = defineMessages({
  roomInfoMoreParticipants: { id: 'roomInfoMoreParticipants' },
});


type Props = {
  list: Array<string>;
  handleRemove: (index: number) => void;
}

function EmailContainer(props: Props) {
  const { list, handleRemove } = props;
  const { formatMessage } = useIntl();

  const emailThreshold = 50;
  const length = list.length;
  const emails = length <= emailThreshold ? list : list.slice(0, emailThreshold);

  const chips = emails.map((el, idx) => {
    return (
      <EmailItem key={idx} text={el} handleRemove={handleRemove} index={idx} />
    );
  });

  if (length > emailThreshold) {
    const extraEmails = length - emailThreshold;
    const text = formatMessage(messages.roomInfoMoreParticipants, { otherAttendees: extraEmails });
    chips.push(
      <EmailItem
        key={emailThreshold}
        text={text}
        handleRemove={handleRemove}
        index={emailThreshold}
        multi
      />
    );
  }

  return (
    <React.Fragment>
      {chips}
    </React.Fragment>
  );
}


type ItemProps = {
  index: number;
  handleRemove: (index: number) => void;
  multi?: boolean;
  text: string;
}

function EmailItem(props: ItemProps) {
  const classes = useStyles();
  const { index, handleRemove, multi, text } = props;

  const remove = () => {
    handleRemove(index);
  };

  return (
    <Tooltip
      arrow
      interactive
      title={text}
      classes={{ tooltip: classes.tooltip }}
    >
      <StyledChip
        key={index}
        className={classes.chip}
        label={text}
        onDelete={multi ? undefined : remove}
        deleteIcon={
          <IconButton size="small">
            <IconEsc size={22} />
          </IconButton>
        }
      />
    </Tooltip>
  );
}


export default EmailContainer;
