import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconEsc } from '../IconSet';
import EndMeetingDialog from '../EndMeetingDialog';
import { endMeeting } from '../../lib/actions/room';
import { iconColors as colors } from '../../colors';


const messages = defineMessages({
  endMeeting: { id: 'endMeetingMsg' },
});


const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    handleEndMeeting: () => endMeeting(),
  },
  dispatch
  );


type Props = {
  closeMenu: () => void;
}

type PropsType = Props & { intl: IntlShape } & ReturnType<typeof mapDispatchToProps>

function EndMeetingButton(props: PropsType) {

  const l10n = props.intl.formatMessage;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const endMeeting = (): void => {
    setDialogOpen(false);
    props.handleEndMeeting();
    props.closeMenu();
  };

  const openDialog = (): void => {
    if (dialogOpen) {
      props.closeMenu();
    }
    setDialogOpen(!dialogOpen);
  };

  return (
    <div>
      <React.Fragment>
        <MenuItem onClick={openDialog}>
          <ListItemIcon>
            <IconEsc color={colors.attention} size={32} />
          </ListItemIcon>
          <ListItemText primary={l10n(messages.endMeeting)} />
        </MenuItem>
        <EndMeetingDialog
          open={dialogOpen}
          onConfirm={endMeeting}
          onCancel={openDialog}
        />
      </React.Fragment>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(injectIntl(EndMeetingButton));
