import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { State as RoomState } from '../../../lib/reducers/room';
import { State as WebsocketState } from '../../../lib/reducers/websocket';
import { IconStats } from '../../IconSet';
import TooltipMultiLineText from '../../TooltipMultiLineText';
import { usePrevious } from '../../../lib/utils/hooks';
import { RoomLayoutStreamType } from '../../../lib/redux_types';
import { uplinkQuality as colors } from '../../../colors';


const messages = defineMessages({
  highLinkQuality: { id: 'highLinkQuality' },
  mediumLinkQuality: { id: 'mediumLinkQuality' },
  lowLinkQuality: { id: 'lowLinkQuality' },
  computingLinkQuality: { id: 'computingLinkQuality' },
  linkQualityNotAvailable: { id: 'linkQualityNotAvailable' },
});


function getUserStream(user: string, myUserId: string, state: State, streamType: string) {
  let stream;
  let muted = false;
  let videoTs = 0;
  if (user === myUserId) {
    if (streamType === 'screen') {
      videoTs = state.room.localscreen_stream_ts;
      stream = state.room.screenStream;
    }
    else {
      stream = state.room.localvideo_stream;
      videoTs = state.room.localvideo_stream_ts;
      muted = (state.room.roster[user] || { isVideoMuted: true }).isVideoMuted;
    }
  }
  else {
    const defaultUserInfo = {
      screen: null,
      stream: null,
      // eslint-disable-next-line @typescript-eslint/camelcase
      screen_ts: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      stream_ts: 0,
      isScreenMuted: true,
      isVideoMuted: true,
    };
    const userInfo = state.room.roster[user] || defaultUserInfo;
    if (streamType === 'screen') {
      stream = userInfo.screen;
      videoTs = userInfo.screen_ts;
      muted = userInfo.isScreenMuted;
    }
    else {
      stream = userInfo.stream;
      videoTs = userInfo.stream_ts;
      muted = userInfo.isVideoMuted;
    }
  }
  return { stream, muted, videoTs };
}

function VideoQualityInfo(props: ExtendedProps) {
  const { videoPublished, uplinkQuality, iconSize = 24, showAlways = true } = props;
  const { formatMessage } = useIntl();
  const timerRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);
  const [preventLinkQualityFromShowing, setPreventLinkQualityFromShowing] = React.useState(true);
  const prevVideoPublished = usePrevious(videoPublished, false);

  const destroyLinkQualityTimer = (): void => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = null;
  };

  const startLinkQualityTimer = React.useCallback(
    () => {
      destroyLinkQualityTimer();
      timerRef.current = setTimeout(() => setPreventLinkQualityFromShowing(false), 30000);
    }
    , []
  );

  const renderUplinkQuality = (): React.ReactNode | null => {
    if (!uplinkQuality) {
      return null;
    }
    let color = colors.high;
    let tooltip = formatMessage(messages.highLinkQuality);
    let icon = <IconStats size={iconSize} color={color} />;
    if (preventLinkQualityFromShowing) {
      if (showAlways) {
        color = colors.unknown;
        tooltip = formatMessage(messages.computingLinkQuality);
        if (videoPublished) {
          icon = <IconStats size={iconSize} color={color} />;
        }
        else {

          tooltip = formatMessage(messages.linkQualityNotAvailable);
          icon = <IconStats size={iconSize} color={color} />;
        }

      }
      else {
        return null;
      }
    }
    else if (uplinkQuality === 'low') {
      color = colors.low;
      tooltip = formatMessage(messages.lowLinkQuality);
      icon = <IconStats size={iconSize} color={color} />;
    }
    else if (uplinkQuality === 'medium') {
      color = colors.medium;
      tooltip = formatMessage(messages.mediumLinkQuality);
      icon = <IconStats size={iconSize} color={color} />;
    }
    if (showAlways || uplinkQuality === 'medium' || uplinkQuality === 'low') {
      return (
        <TooltipMultiLineText
          placement="top"
          title={tooltip}
        >
          <div>
            {icon}
          </div>
        </TooltipMultiLineText>
      );
    }
    else {
      return null;
    }

  };

  React.useEffect(() => {
    if (videoPublished !== prevVideoPublished) {
      setPreventLinkQualityFromShowing(true);
      if (videoPublished && !timerRef.current) {
        startLinkQualityTimer();
      }
      else if (timerRef.current) {
        destroyLinkQualityTimer();
      }
    }
  }, [prevVideoPublished, videoPublished, startLinkQualityTimer]);

  React.useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    let showProgress = false;
    if (now - props.videoPublishedTs < 30) {
      showProgress = true;
    }

    if (props.videoPublished && !timerRef.current && showProgress) {
      startLinkQualityTimer();
    }
    if (!showProgress) {
      setPreventLinkQualityFromShowing(false);
    }

    return () => { if (timerRef.current) { clearTimeout(timerRef.current); } };
  }, [props.videoPublishedTs, props.videoPublished, startLinkQualityTimer]);

  return (
    <React.Fragment>
      {renderUplinkQuality()}
    </React.Fragment>
  );
}

type MappedProps = {
  videoPublishedTs: number;
  videoPublished: boolean;
  uplinkQuality: string;
}

type State = {
  room: RoomState;
  websocket: WebsocketState;
}

type Props = {
  user: string;
  iconSize?: number;
  showAlways?: boolean;
}

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State, props: Props): MappedProps => {
  let streamType: RoomLayoutStreamType = 'stream';
  if (props.user.endsWith('_screen')) {
    streamType = 'screen';
  }

  const user = props.user.replace(/_screen$/, '');
  const userInfo = state.room.roster[user];
  const myUserId = state.websocket.uid || '';
  let videoQuality;
  if (streamType === 'screen') {
    // eslint-disable-next-line @typescript-eslint/camelcase
    videoQuality = (userInfo || { screen_uplink_quality: null }).screen_uplink_quality;
  }
  else {
    // eslint-disable-next-line @typescript-eslint/camelcase
    videoQuality = (userInfo || { video_uplink_quality: null }).video_uplink_quality;
  }
  const userStream = getUserStream(user, myUserId, state, streamType);
  return {
    uplinkQuality: videoQuality,
    videoPublishedTs: userStream.videoTs,
    videoPublished: userStream.stream && !userStream.muted,
  };
};

export default connect(mapStateToProps)(VideoQualityInfo);
