import { createStyles, Theme } from '@material-ui/core/styles';
const styles = (theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.contrastText
    },
    fab: {
      margin: theme.spacing(1),
    },

    content: {
      color: theme.palette.primary.contrastText
    },

    arrow: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },

    calendarRectangle: {
      width: '100%',
      position: 'relative',
      fontSize: '1em',
      overflow: 'hidden',

      [theme.breakpoints.down('md')]: {
        width: '100%'
      },

      [theme.breakpoints.up('md')]: {
        width: '80%'
      },
    },

    calendarContent: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },

    daysHeader: {
      width: '100%',
      height: '30%',
      textTransform: 'capitalize',
      marginBottom: theme.spacing(),
    },

    calendarHeader: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      height: '30%',
    },

    titleHeader: {
      width: '100%',
      height: '70%',
      whiteSpace: 'nowrap',
      fontSize: '1.2em',
      textTransform: 'capitalize',
      marginBottom: theme.spacing(),

      '@media (min-width: 992px)': {
        fontSize: '1.4em',
      },

      '@media (min-width: 1200px)': {
        fontSize: '1.2em',
      },
    },

    headerText: {
      flex: 5,
      display: 'flex',
      height: '100%',
    },

    monthLabel: {
    },

    mainCalendar: {
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },

    row: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    box: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignIitems: 'center',
      flex: 1,
      height: '100%',
      transition: 'all 0.4s ease-in-out 0s',
    },

    dayNumber: {
      height: '90%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      borderRadius: '50%',
      margin: theme.spacing(),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    today: {
    },

    differentMonth: {
      opacity: 0.68,
      color: 'rgba(0,0,0,0.68)',
    },

    hasEvents: {
      textAlign: 'center',
      '&::before': {
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        bottom: 0,
        height: '8px',
        content: '""',
        width: '8px',
        left: '45%',
        position: 'absolute',

      },
    },

    daySelected: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginLeft: '25%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },

    week: {
      height: '15%',
    },

    daySelectedWrapper: {
      flex: 1,
    },

    day: {
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      height: '100%',
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
      textAlign: 'center',
    },

    daysContainer: {
      width: '100%',
      height: '70%',

      week: {
        height: '15%',
      }
    },

    dayDisabled: {
      pointerEvents: 'none',
      opacity: 0.38,
      color: 'rgba(0,0,0,0.38)',
    }


  });

export default styles;


