import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State as RoomState } from '../../lib/reducers/room';
import { IconUnlocked, IconUnlockedSelected } from '../IconSet';
import { WithAuthorization } from '../../authorization';
import { useIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { lockRoom } from '../../lib/actions/room';
import { FirstTimePopoverDialog } from '../PopoverDialog';


const messages = defineMessages({
  lockRoom: { id: 'lockRoom' },
  unLockRoom: { id: 'unLockRoom' },
  lockRoomFirstTimeMsg: { id: 'lockRoomFirstTimeMsg' },
});


type MappedProps = {
  isLocked: boolean;
  isRequestingLockRoom: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    handleLockRoom: () => lockRoom(),
  },
  dispatch
  );

type State = {
  room: RoomState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isLocked: state.room.isLocked,
  isRequestingLockRoom: state.room.isRequestingLockRoom,
});

type Props = {
} & MappedProps

type PropsType = Props & ReturnType<typeof mapDispatchToProps>

function LockRoom(props: PropsType) {
  const { formatMessage } = useIntl();
  const { isLocked, isRequestingLockRoom } = props;

  const lockRoom = (): void => {
    props.handleLockRoom();
  };
  const msg = formatMessage(props.isLocked ? messages.unLockRoom : messages.lockRoom);

  return (
    <FirstTimePopoverDialog
      configKey="LockRoom"
      message={formatMessage(messages.lockRoomFirstTimeMsg)}
    >
      <Tooltip
        placement="left"
        title={msg}
      >
        <div onClick={isRequestingLockRoom ? undefined : lockRoom}>
          {isLocked
            ? <IconUnlockedSelected size={28} />
            : <IconUnlocked size={28} />
          }
        </div>
      </Tooltip>
    </FirstTimePopoverDialog>
  );
}

const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, connect(mapStateToProps, mapDispatchToProps)(LockRoom));
