/* Libraries Imports */
import React from 'react';
import moment from 'moment';
import { useIntl, defineMessages } from 'react-intl';

/* UI Imports */
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

/* Other Imports */
import { linkify } from '../../lib/utils';
import { AggregateChatMessage } from './utils';
import { UserMessage, EventMessage } from '../../lib/redux_types';
import AvatarNew from '../AvatarNew';


const msgs = defineMessages({
  userJoined: { id: 'userJoined' },
  userLeft: { id: 'userLeft' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: '1 1 auto',
      padding: theme.spacing(0, 2),
      width: 'calc(100% - 64px)',
    },
    displayNameText: {
      fontVariant: 'small-caps',
      fontSize: 'small',
    },
    message: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // Following CSS might be useful for a Whatsapp like message rendering
      // borderRadius: '5px',
      // padding: theme.spacing(0, 0.5),
      // backgroundColor: theme.palette.grey[200],
      // marginTop: theme.spacing(0.5),
    },
    messageText: {
      wordWrap: 'break-word',
      margin: theme.spacing(0.25, 0),
    },
    systemTextContainer: {
      display: 'flex',
      flexGrow: 1,
      alignSelf: 'center',
      justifyContent: 'center'
    },
    systemText: {
      fontStyle: 'italic',
      textAlign: 'center',
      borderRadius: '100px',
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(0, 1),
      wordWrap: 'break-word',
      margin: theme.spacing(0.25, 0),
    },
    messageTime: {
      textAlign: 'right',
      paddingLeft: theme.spacing(1),
    }
  })
);


type Props = {
  message: AggregateChatMessage;
}


function UserMessageComp(props: Props) {
  const classes = useStyles();
  const { displayName, messages, timestamp } = props.message;
  const ts = moment.unix(timestamp).format("HH:mm");

  function getUserMessage(msg: UserMessage, index: number) {
    const parsed = linkify(msg.data.message, '_blank', 25);
    return (
      <Typography variant="body2" className={classes.messageText} key={index}>
        {parsed}
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <AvatarNew name={displayName} />
      <div className={classes.container}>
        <div className={classes.message}>
          <Typography color="textSecondary" variant="body2" className={classes.displayNameText}>
            {displayName}
          </Typography>
          <Typography color="textSecondary" variant="caption" className={classes.messageTime}>
            {ts}
          </Typography>
        </div>
        {messages.map((msg, idx) => {
          return getUserMessage(msg as UserMessage, idx);
        })}
      </div>
    </React.Fragment>
  );
}


function EventMessageComp(props: Props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { messages } = props.message;

  function getEventMessage(msg: EventMessage, index: number) {
    const { subClass, data: { fromDisplayName: displayName, timestamp } } = msg;
    const ts = moment.unix(timestamp).format("HH:mm");
    const baseMessage = subClass === 'join' ? msgs.userJoined : msgs.userLeft;
    const sysMessage = formatMessage(baseMessage, { name: displayName });
    return (
      <div className={classes.message} key={index}>
        <div className={classes.systemTextContainer}>
          <Typography color="textSecondary" variant="caption" className={classes.systemText}>
            {sysMessage}
          </Typography>
        </div>
        <Typography color="textSecondary" variant="caption" className={classes.messageTime}>
          {ts}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {messages.map((msg, idx) => {
        return getEventMessage(msg as EventMessage, idx);
      })}
    </div>
  );
}


export default function ChatMessage(props: Props) {
  return (
    <React.Fragment>
      {props.message.class === 'message'
        ? <UserMessageComp {...props} />
        : <EventMessageComp {...props} />
      }
    </React.Fragment>
  );
}
