import React from 'react';

import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { CallEndRounded } from '@material-ui/icons';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import messages from './buttonsMessages';

import getRouteFor, { Route } from '../../lib/utils/router';
import { tearDownRoom } from '../../lib/actions/room';
import { leaveChannel } from '../../lib/actions/websocket';
import { State } from '../../lib/reducers';
import { amModerator } from '../../lib/reduxSelectors/room';
import { findRoomOwner } from '../../lib/reduxSelectors/presentationLayout';
import LeaveMeetingDialog from '../LeaveMeetingDialog';


type Props = {} & MappedProps & RouteComponentProps


function ExitButton(props: Props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [ dialogOpen, setDialogOpen ] = React.useState(false);

  const onExit = React.useCallback(
    () => {
      dispatch(tearDownRoom());
      dispatch(leaveChannel());
      props.history.push(getRouteFor(Route.Home), { doNotConfirm: true });
    }
    , [dispatch, props.history]
  );

  const onClose = React.useCallback(
    () => {
      setDialogOpen(false);
    }
    , []
  );

  const onHangup = React.useCallback(
    () => {
      if (props.showDialog) {
        setDialogOpen(true);
      } else {
        onExit();
      }
    }
    , [props.showDialog, onExit]
  );

  return (
    <React.Fragment>
      <ToolbarButton
        icon={<CallEndRounded className={classes.exitButton} />}
        text={formatMessage(messages.leaveMeeting)}
        buttonProps={{ className: classes.buttons, onClick: onHangup }}
      />
      <LeaveMeetingDialog onClose={onClose} onHangup={onExit} open={dialogOpen} />
    </React.Fragment>
  );
}


type MappedProps = {
  showDialog: boolean;
}

const mapStateToProps = (state: State): MappedProps => {
  const amIOwner = state.room.amIRoomOwner;
  const amIModerator = amModerator(state);
  const isThereTheOwner = Boolean(findRoomOwner(state));
  const isThereAnyOneElse = Object.keys(state.room.roster).length > 1;
  const showDialog = (amIOwner || (!isThereTheOwner && amIModerator)) && isThereAnyOneElse;
  return { showDialog };
};


export default connect(mapStateToProps)(withRouter(ExitButton));
