/* Libraries Imports */
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

/* UI Imports */
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { IconUsers, IconUsersSelected, IconHandUp } from '../IconSet';

import { State } from '../../lib/reducers';
import {
  getNumberOfRaisedHands,
  disableRoster,
  getLatestRaisedHand,
  ObservedUser
} from '../../lib/reduxSelectors/roster';
import {
  amModerator,
  isWebinarLayout,
  amWebinarPresenter,
  isAudioOnlyLayout,
  isThereAnyVideo
} from '../../lib/reduxSelectors/room';
import { iconColors as colors } from '../../colors';
import { isMobileOnly } from 'react-device-detect';
import { usePrevious } from '../../lib/utils/hooks';
import { sendNotification } from '../../notification';


const messages = defineMessages({
  raisedHand: { id: 'raisedHand' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    badgeContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  })
);


function RosterIcon(props: ExtendedProps) {
  const classes = useStyles();
  const {
    numberOfRaisedHands,
    latestRaisedHand,
    rosterPanelOpen,
    amIModerator,
    amWebinarPresenter,
    isWebinarLayout,
    disableRoster,
    isThereAnyVideo,
    myUsername,
    siteTitle,
    isAudioOnlyLayout
  } = props;

  const { formatMessage } = useIntl();

  const canShowNotification = ((isWebinarLayout && amWebinarPresenter) || amIModerator)
    && numberOfRaisedHands > 0;

  const showBadge = canShowNotification && isThereAnyVideo && !isAudioOnlyLayout;

  // send notification on latestRaisedHand prop change
  const prevRaisedHand = usePrevious(latestRaisedHand, {} as ObservedUser);
  React.useEffect(
    () => {
      if (canShowNotification && latestRaisedHand) {
        const showNotification = !(document.hasFocus()) && latestRaisedHand.username !== myUsername
          && (prevRaisedHand ? (prevRaisedHand.raisedHandSince < latestRaisedHand.raisedHandSince) : true);
        if (showNotification) {
          const title = siteTitle;
          const lastMessage = formatMessage(messages.raisedHand, { dname: latestRaisedHand.display });
          sendNotification(title, lastMessage);
        }
      }
    },
    [latestRaisedHand, prevRaisedHand, myUsername, formatMessage, siteTitle, canShowNotification]
  );

  const getBadgeContent = () => {
    if (showBadge) {
      return (
        <div className={classes.badgeContent}>
          {numberOfRaisedHands} <IconHandUp color={colors.contrast} size={12}/>
        </div>
      );
    } else {
      return 0;
    }
  };

  return (
    <Badge badgeContent={getBadgeContent()} color="secondary">
      { rosterPanelOpen 
        ? <IconUsersSelected size={28} />
        // isMobileOnly is required because on MeetingBar the disabled color is enforced by the listitem component
        // unfortunately in the MeetingMobileBar the Tab component do not allow to change the icon color in an easy way
        : <IconUsers color={disableRoster && isMobileOnly ? 'rgb(0,0,0,0.6)' : 'rgb(0,0,0)'} size={28}/>
      }
    </Badge>
  );
}


type Props = {
  rosterPanelOpen: boolean;
}

type MappedProps = {
  numberOfRaisedHands: number;
  latestRaisedHand: ObservedUser | null;
  amIModerator: boolean;
  isWebinarLayout: boolean;
  amWebinarPresenter: boolean;
  disableRoster: boolean;
  isAudioOnlyLayout: boolean;
  isThereAnyVideo: boolean;
  myUsername: State['session']['username'];
  siteTitle: State['appconfig']['site_title'];
}

type ExtendedProps = Props & MappedProps;


const mapStateToProps = (state: State): MappedProps => ({
  numberOfRaisedHands: getNumberOfRaisedHands(state),
  latestRaisedHand: getLatestRaisedHand(state),
  amIModerator: amModerator(state),
  isWebinarLayout: isWebinarLayout(state),
  amWebinarPresenter: amWebinarPresenter(state),
  disableRoster: disableRoster(state),
  isAudioOnlyLayout: isAudioOnlyLayout(state),
  isThereAnyVideo: isThereAnyVideo(state),
  myUsername: state.session.username,
  siteTitle: state.appconfig.site_title,
});


export default connect(mapStateToProps)(RosterIcon);
