import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { IconMaximize, IconMinimize } from '../../IconSet';
import { toggleFullscreen } from '../../../lib/actions/room';
import { defineMessages } from 'react-intl';
import { State } from '../../../lib/reducers';
import { canFullscreen } from '../../../lib/reduxSelectors/user';


const messages = defineMessages({
  enableFullscreen: { id: 'enterFullScreenButton' },
  disableFullscreen: { id: 'exitFullScreenButton' }
});


function FullscreenMenuItem(props: ExtendedProps) {
  const { user, isFullscreenEnabled, fullscreenUser, onClose, iconSize = 20 } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const onFullscreenAction = React.useCallback(
    () => {
      if (fullscreenUser) {
        dispatch(toggleFullscreen(null));
      }
      else {
        dispatch(toggleFullscreen(user));
      }
      onClose();
    }
    , [dispatch, user, onClose, fullscreenUser]
  );

  const getIcon = (): React.ReactElement => {
    if (fullscreenUser) {
      return <IconMinimize size={iconSize} />;
    }
    else {
      return <IconMaximize size={iconSize} />;
    }

  };

  if (!isFullscreenEnabled) {
    return null;
  }

  return (
    <MenuItem onClick={onFullscreenAction} >
      <ListItemIcon>
        {getIcon()}
      </ListItemIcon>
      <ListItemText primary={formatMessage(!fullscreenUser
        ? messages.enableFullscreen
        : messages.disableFullscreen)} />
    </MenuItem>

  );

}

type Props = {
  user: string;
  iconSize?: number;
  onClose: () => void;
}

type MappedProps = {
  fullscreenUser: string | null;
  isFullscreenEnabled: boolean;
}

type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    fullscreenUser: state.room.fullScreenEnabled,
    isFullscreenEnabled: canFullscreen(ownProps.user, state),
  };
};


export default connect(mapStateToProps)(FullscreenMenuItem);
