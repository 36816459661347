/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let DownloadIcon = props =>
  <SvgIcon {...props}>
    <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"/>
  </SvgIcon>;

DownloadIcon = pure(DownloadIcon);
DownloadIcon.muiName = 'SvgIcon';

export default DownloadIcon;
