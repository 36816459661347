import React from 'react';

import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';

import { State } from '../../lib/reducers';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { ScreenSharingOptions } from '../../lib/redux_types';
import { getMemoizedDeskControlledUser } from '../Meeting/reduxSelectors';
import { ScreenSourceType } from '../../lib/redux_types';
import {
  amWebinarPresenter,
  isWebinarLayout,
  isLessonLayout,
  isAudioOnlyLayout,
  amModerator,
} from '../../lib/reduxSelectors/room';

import MicButton from './MicButton';
import RaiseHandButton from './RaiseHandButton';
import VideoButton from './VideoButton';
import ShareScreenButton from './ShareScreenButton';
import SwitchCamera from './SwitchCamera';
import InviteButton from './InviteButton';
import ExitButton from './ExitButton';
import DesktopControlButton from './DesktopControlButton';
import { FirstTimePopoverDialog, LocalStorageConfigBackend } from '../PopoverDialog';
import messages from './buttonsMessages';
import { isMobile, isMobileOnly, isElectron } from 'react-device-detect';
import { IconChevronLeft, IconChevronRight } from '../IconSet';
import ToolbarButton from './ToolbarButton';
import { VideoRoom } from '../../lib/api/videoroom';
import { iconColors } from '../../colors';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: theme.zIndex.drawer,
      height: '90px', // TODO
    },
    paper: {
      position: 'absolute',
      top: 'auto',
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      maxHeight: '100%',
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      zIndex: theme.zIndex.drawer,
      WebkitOverflowScrolling: 'touch',
      outline: 0,
      opacity: '0.8',
    },
    toolbar: {
      padding: '1em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5em',
      },
    },
    buttons: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0.2ex',
        marginRight: '0.2ex',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '2em',
        marginRight: '2em',
      },
    },
    // taken and adapted from ./buttonsStyle.buttons style
    moreOrLess: {
      [theme.breakpoints.down('sm')]: {
        borderRadius: '50%',
        minWidth: 0,
        margin: theme.spacing(0, 1),
        padding: theme.spacing(1),
        backgroundColor: iconColors.contrast,
        borderColor: iconColors.contrast,
      },
    }
  })
);


function canIToggleAudioMute(props: ExtendedProps) {
  return props.canPublishAudio || props.isMyAudioUnmuted;
}


function canIPublishVideo(props: ExtendedProps) {
  if (props.ownerIsAudioOnly) {
    return false;
  }
  else if (props.isWebinarLayout) {
    return props.amWebinarPresenter;
  }
  else if (props.isAudioLayout) {
    return false;
  }
  return true;
}


function OwnMeetingControls(props: ExtendedProps) {
  const classes = useStyles();
  const {
    someoneHasDesktopControl,
    screenSourceType,
    isWebinarLayout,
    amIModerator,
    hasVideoStream,
    canPublishVideo
  } = props;

  const { formatMessage } = useIntl();
  const canSendVideo = canIPublishVideo(props);
  const canToggleAudioMute = canIToggleAudioMute(props);

  let canRaiseHand = true;
  if (isWebinarLayout) {
    canRaiseHand = !props.amWebinarPresenter;
  }

  const hideControlsTimeout = 3000;
  const easeTimeout = 500;

  const popoverConfigKey = 'videoToolbar';

  const [popoverDismissed, setPopoverDismissed] = React.useState(false);
  React.useEffect(() => {
    const ls = new LocalStorageConfigBackend();
    // FIXME: this component shouldn't know how to build the key
    setPopoverDismissed(ls.isDismissed(`FirstTimePopoverDialog::${popoverConfigKey}`));
  }, [setPopoverDismissed]
  );

  const [shown, setShown] = React.useState(!popoverDismissed);
  const preventHide = React.useRef(false);
  const timerRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  const hideControlsWhenIdle = React.useCallback(
    () => {
      if (!popoverDismissed || isMobileOnly) {
        return;
      }
      else {
        timerRef.current = setTimeout(
          () => {
            if (shown && !preventHide.current) {
              setShown(false);
            }
            if (timerRef.current) {
              timerRef.current = null;
            }
          }
          , hideControlsTimeout
        );
      }
    }
    , [shown, timerRef, hideControlsTimeout, popoverDismissed]
  );

  // cleanup timers on unmount, to avoid trying to set state on an unmounted
  // component
  React.useEffect(() => {
    hideControlsWhenIdle();
    return function cleanup() {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  });

  const dismissPopover = React.useCallback(
    () => {
      setPopoverDismissed(true);
      preventHide.current = false;
    }
    , [setPopoverDismissed]
  );

  const onMouseMoved = React.useCallback(
    () => {
      if (!shown) {
        setShown(true);
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      hideControlsWhenIdle();
    }
    , [shown, timerRef, hideControlsWhenIdle]
  );

  const onMouseEntered = React.useCallback(
    () => {
      if (!preventHide.current) preventHide.current = true;
    }
    , []
  );

  const onMouseLeft = React.useCallback(
    () => {
      if (preventHide.current) preventHide.current = false;
    }
    , []
  );

  let sharingConstraints: undefined | ScreenSharingOptions = undefined;
  if (props.isLessonLayout && !amIModerator) {
    sharingConstraints = {
      frameRate: 1,
    };
  }

  if (props.isRecorder) return null;

  const getControls = () => {
    let canInvite = true;
    if (isWebinarLayout && !amIModerator) {
      canInvite = false;
    }
    else if (isLessonLayout && !amIModerator) {
      canInvite = false;
    }
    return (
      <Paper
        elevation={0}
        square
        className={classes.paper}
        onMouseEnter={onMouseEntered}
        onMouseLeave={onMouseLeft}
      >
        <FirstTimePopoverDialog
          message={formatMessage(messages.manageYourControlsHere)}
          configKey={popoverConfigKey}
          onDismiss={dismissPopover}
        >
          <Toolbar
            canRaiseHand={canRaiseHand}
            canSendVideo={canSendVideo}
            canToggleAudioMute={canToggleAudioMute}
            canInvite={canInvite}
            sharingConstraints={sharingConstraints}
            screenSourceType={screenSourceType}
            someoneHasDesktopControl={someoneHasDesktopControl}
            hasVideoStream={hasVideoStream}
            canPublishVideo={canPublishVideo}
          />
        </FirstTimePopoverDialog>
      </Paper>
    );
  };

  const getSlidingBar = () => {
    return (
      <div
        className={classes.container}
        onMouseMove={onMouseMoved}
      >
        <Slide
          in={shown || isMobileOnly}
          direction='up'
          timeout={easeTimeout}
          appear={false}
        >
          {getControls()}
        </Slide>
      </div>
    );
  };

  return (
    <React.Fragment>
      { isMobileOnly ? getControls() : getSlidingBar() }
    </React.Fragment>
  );
}


type ToolbarProps = {
  canSendVideo: boolean;
  canRaiseHand: boolean;
  canToggleAudioMute: boolean;
  canInvite: boolean;
  sharingConstraints?: ScreenSharingOptions;
  screenSourceType: ScreenSourceType;
  someoneHasDesktopControl: boolean;
  hasVideoStream: boolean;
  canPublishVideo: boolean;
}

function Toolbar(props: ToolbarProps) {
  const classes = useStyles();
  const canEnableDesktopControl = isElectron && props.screenSourceType === 'screen' && !props.someoneHasDesktopControl;
  const {
    canSendVideo,
    canRaiseHand,
    canToggleAudioMute,
    canInvite,
    sharingConstraints,
    hasVideoStream,
    canPublishVideo
  } = props;
  const [ showMore, setShowMore ] = React.useState(false);
  const [ showMoreCtrl, setShowMoreCtrl ] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  // check whether to show the more control button or not
  React.useEffect(
    () => {
      if (isMobileOnly && ref.current) {
        const hasVideo = hasVideoStream && canPublishVideo;
        const canExit = true;
        // has video is counted twice, one for the video and the other for the switchcamera/sharescreen button
        const num =
        [canToggleAudioMute, hasVideo, canRaiseHand, canExit, hasVideo, canInvite, canEnableDesktopControl]
          .filter(v => v).length;
        // empiric size of each button on mobile
        const elSize = 54;
        const { clientWidth } = ref.current;
        if (num * elSize > clientWidth) {
          setShowMore(true);
        } else {
          setShowMore(false);
        }
      }
    }, [canToggleAudioMute, hasVideoStream, canPublishVideo, canRaiseHand, canInvite, canEnableDesktopControl]
  );

  const showMoreControls = React.useCallback(
    () => {
      setShowMoreCtrl(!showMoreCtrl);
    }, [setShowMoreCtrl, showMoreCtrl]
  );

  const getShowMoreOrLess = (more: boolean) => {
    return (
      <ToolbarButton
        icon={more
          ? <IconChevronRight size={14} />
          : <IconChevronLeft size={14} />
        }
        text={''}
        buttonProps={{ className: classes.moreOrLess, onClick: showMoreControls }}
      />
    );
  };

  const getMore = () => {
    return (
      <React.Fragment>
        { showMoreCtrl && getShowMoreOrLess(false)}
        { canSendVideo &&
          (isMobile
            ? <SwitchCamera />
            : <ShareScreenButton constraints={sharingConstraints}/>
          )
        }
        { canInvite && <InviteButton /> }
        { (canEnableDesktopControl && <DesktopControlButton /> )}
      </React.Fragment>
    );
  };

  const getControls = () => {
    return (
      <React.Fragment>
        { canToggleAudioMute && <MicButton /> }
        { canSendVideo && <VideoButton /> }
        { canRaiseHand && <RaiseHandButton /> }
        <ExitButton />
        { showMore ? getShowMoreOrLess(true) : getMore() }
      </React.Fragment>
    );
  };

  return (
    <div ref={ref} className={classes.toolbar}>
      { showMoreCtrl ? getMore() : getControls() }
    </div>
  );
}

type Props = {} & RouteComponentProps

type ExtendedProps = Props & MappedProps;


type MappedProps = {
  isRecorder: boolean;
  amWebinarPresenter: boolean;
  isWebinarLayout: boolean;
  isAudioLayout: boolean;
  screenSourceType: ScreenSourceType;
  someoneHasDesktopControl: boolean;
  isLessonLayout: boolean;
  amIModerator: boolean;
  canPublishAudio: boolean;
  isMyAudioUnmuted: boolean;
  ownerIsAudioOnly: boolean;
  hasVideoStream: boolean;
  canPublishVideo: boolean;
}


const mapStateToProps = (state: State, { match }: Props): MappedProps => {
  const controlledUser = getMemoizedDeskControlledUser(state);
  const myUser = state.websocket.uid;
  const me = myUser ? state.room.roster[myUser] : null;
  let isMyAudioUnmuted = false;
  if (me) {
    isMyAudioUnmuted = !me.isAudioMuted;
  }
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }

  return {
    isRecorder: isRecorder(state, match.url),
    amWebinarPresenter: amWebinarPresenter(state),
    amIModerator: amModerator(state),
    isWebinarLayout: isWebinarLayout(state),
    isAudioLayout: isAudioOnlyLayout(state),
    screenSourceType: state.room.screenSourceType,
    someoneHasDesktopControl: controlledUser !== undefined && controlledUser !== myUser,
    isLessonLayout: isLessonLayout(state),
    canPublishAudio: state.room.mediaPermissions.canPublishAudio,
    isMyAudioUnmuted,
    ownerIsAudioOnly: state.room.ownerIsAudioOnly,
    hasVideoStream,
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
  };
};


export default withRouter(connect(mapStateToProps)(OwnMeetingControls));
