import { graphql } from 'babel-plugin-relay/macro';
import { myProfileQueryResponse } from './__generated__/myProfileQuery.graphql';

export const myProfileQuery = graphql`
query myProfileQuery {
  loggedUser {
    profile {
      email
      middlename
      name
      username
      surname
      meta {
        livestreaming {
          apiKey
          apiUrl
          publishUrl
        }
      }
    }
  }
}
`;


function extractProfileResponse(response: myProfileQueryResponse) {
  const { loggedUser } = response;
  const { profile } = loggedUser || { profile: null };
  return profile;
}


export function getLiveStreamingApiUrl(response: myProfileQueryResponse) {
  const profile = extractProfileResponse(response);

  if (profile && profile.meta && profile.meta.livestreaming) {
    return profile.meta.livestreaming.apiUrl;
  }
}


export function getLiveStreamingApiKey(response: myProfileQueryResponse) {
  const profile = extractProfileResponse(response);

  if (profile && profile.meta && profile.meta.livestreaming) {
    return profile.meta.livestreaming.apiKey;
  }
}


export function getLiveStreamingPublishUrl(response: myProfileQueryResponse) {
  const profile = extractProfileResponse(response);

  if (profile && profile.meta && profile.meta.livestreaming) {
    return profile.meta.livestreaming.publishUrl;
  }
}
