import React from 'react';
import { connect } from 'react-redux';

import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { State as AuthState } from '../../lib/reducers/auth';

import StartMeetingHeader from '../StartMeetingHeader';
import EnterMeeting from '../EnterMeeting';
import MeetingsBoard from '../MeetingsBoard';



const styles = (_theme: Theme) =>
  createStyles({
    main: {
      padding: 0,
      height: '100%'
    },
    noPadding: {
      padding: 0,
    },
  });


type MappedProps = {
  isAuthenticated: boolean;
}


type Props = {} & MappedProps;


type State = {
  auth: AuthState;
}


const mapStateToProps = (state: State): MappedProps => ({
  isAuthenticated: state.auth.isAuthenticated,
});


class HomePage extends React.Component<Props & WithStyles<typeof styles>, {}> {
  render() {
    const { classes } = this.props;

    if (!this.props.isAuthenticated) {
      return (
        <EnterMeeting />
      );
    }

    return (
      <React.Fragment>
        <Box display="flex" flexDirection="column" overflow="auto" p={1} className={classes.main}>
          <Box p={1} flexGrow={1} className={classes.noPadding}>
            <StartMeetingHeader />
          </Box>
          <Box p={1} flexGrow={8} className={classes.noPadding}>
            <MeetingsBoard />
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}


export default withStyles(styles)(connect(mapStateToProps)(HomePage));
