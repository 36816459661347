import React from 'react';
import moment from 'moment';
import { Channel } from 'phoenix';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { getRoomNameFromParams } from '../../../lib/utils/router';
import { getLogger } from '../../../lib/logger';
import {
  Action as WaitingRoomAction,
  storeSnapshot,
  setWaitingReason,
  tearDownWaitingRoom,
} from '../../../lib/actions/waitingRoom';
import { joinWaitingRoom, leaveChannel } from '../../../lib/actions/websocket';
import { State } from '../../../lib/reducers';
import { Meeting } from '../../../lib/reducers/websocket';

import WaitingRoomBottomContent from './WaitingRoomBottomContent';
import GuestLoginField from '../GuestLoginField';


const messages = defineMessages({
  leaveWaitingRoom: { id: 'leaveWaitingRoom' },
  waitingReason: {
    "wait_owner": {
      title: { id: "waitOwnerTitle" },
      body: { id: "waitOwnerBody" }
    },
    "room_locked": {
      title: { id: "roomLockedTitle" },
      body: { id: "roomLockedBody" }
    }
  },
  tooEarlyTitle: { id: 'errorCode1002Title' },
  tooEarlyRoomNotAvailable: { id: 'theRoomIsNotAvailableYet' },
  tooEarlyMeetingIsFromTo: { id: 'theRoomIsScheduledFromTo' },
  tooEarlyMeetingIsTodayFromTo: { id: 'theRoomIsScheduledTodayFromTo' },
  tooEarlyMeetingWillBeAvailableIn: { id: 'theRoomWillBeAvailableIn' },
  checkSettingsAndJoinTitle: { id: 'checkSettingsAndJoinTitle' },
  checkSettingsAndJoinBody: { id: 'checkSettingsAndJoinBody' },
  lockedJoinRequestDeniedTitle: { id: 'lockedJoinRequestDeniedTitle' },
  lockedJoinRequestDeniedBody: { id: 'lockedJoinRequestDeniedBody' },
  insertYourNameAndJoinTitle: { id: 'insertYourNameAndJoinTitle' },
  insertYourNameAndJoinBody: { id: 'insertYourNameAndJoinBody' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingBottomTitle: {
      padding: theme.spacing(0, 0, 1.5, 0)
    },
    paddingBottomBody: {
      padding: theme.spacing(0, 0, 3, 0)
    },
    guest: {
      padding: theme.spacing(0, 1)
    }
  })
);


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WsAction = any   // FIXME
type Action = WaitingRoomAction & WsAction

type TooEarlyForMeetingBoxProps = {
  meetingDetails: Meeting;
  takeSnapshot: () => string | null;
};

function TooEarlyForMeetingBox(props: TooEarlyForMeetingBoxProps) {
  const {
    meetingDetails,
    takeSnapshot
  } = props;

  const [availableIn, setAvailableIn] = React.useState("");

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const classes = useStyles();

  React.useEffect(
    () => {
      const meetingId = meetingDetails.slug;
      const logger = getLogger(`Meeting ${meetingId}`);
      const attemptJoinOrUpdateMsg = () => {
        const start = moment(meetingDetails.dt_start);
        const now = moment();
        const time = moment(start).from(now);
        if (start.diff(now, 'seconds') < 1) {
          logger.info(`meeting can now start`);
          const snapshot = takeSnapshot();
          dispatch(storeSnapshot(snapshot));
          dispatch(setWaitingReason(null, null));
        } else {
          setAvailableIn(formatMessage(messages.tooEarlyMeetingWillBeAvailableIn, { time: time }));
        }
      };
      const timer = setInterval(attemptJoinOrUpdateMsg, 1000);
      attemptJoinOrUpdateMsg();
      return () => {
        clearInterval(timer);
      };
    }, [meetingDetails, dispatch, formatMessage, takeSnapshot]
  );

  const getSchedulingMessage = () => {
    const [start, end] = [moment(meetingDetails.dt_start), moment(meetingDetails.dt_end)];
    const now = moment();

    let schedulingMessage = '';
    if (start.isSame(now, 'day')) {
      schedulingMessage = formatMessage(messages.tooEarlyMeetingIsTodayFromTo,
        { start: start.format('LT'), end: end.format('LT') });
    }
    else {
      schedulingMessage = formatMessage(messages.tooEarlyMeetingIsFromTo,
        { date: start.format('LL'), start: start.format('LT'), end: end.format('LT') });
    }
    return schedulingMessage;
  };

  return (
    <React.Fragment>
      <Typography variant="h4" align="center" className={classes.paddingBottomTitle}>
        {formatMessage(messages.tooEarlyTitle)}
      </Typography>
      <Typography variant="body2" align="center">
        {formatMessage(messages.tooEarlyRoomNotAvailable, { name: meetingDetails.title })}
      </Typography>
      <Typography variant="body2" align="center">
        {getSchedulingMessage()}
      </Typography>
      <Typography variant="body2" align="center" className={classes.paddingBottomBody}>
        {availableIn}
      </Typography>
      <WaitingRoomBottomContent canJoin={false} showProgress />
    </React.Fragment>
  );
}

function LockedJoinRequestDeniedBox(_props: {}) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h4" align="center" className={classes.paddingBottomTitle}>
        {formatMessage(messages.lockedJoinRequestDeniedTitle)}
      </Typography>
      <Typography variant="body2" align="center" className={classes.paddingBottomBody}>
        {formatMessage(messages.lockedJoinRequestDeniedBody)}
      </Typography>
      <WaitingRoomBottomContent canJoin={false} showProgress={false} />
    </React.Fragment>
  );
}

type WaitingMessageBoxProps = {
  waitingReason: string;
}

function WaitingMessageBox(props: WaitingMessageBoxProps) {
  const { waitingReason } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const waitingReasons = messages.waitingReason;
  type waitingReasonType = keyof typeof waitingReasons;

  return (
    <React.Fragment>
      <Typography variant="h4" align="center" className={classes.paddingBottomTitle}>
        {formatMessage(messages.waitingReason[waitingReason as waitingReasonType].title)}
      </Typography>
      <Typography variant="body2" align="center" className={classes.paddingBottomBody}>
        {formatMessage(messages.waitingReason[waitingReason as waitingReasonType].body)}
      </Typography>
      <WaitingRoomBottomContent canJoin={false} showProgress />
    </React.Fragment>
  );
}

type CheckSettingsAndJoinBoxProps = {
  showGuestLogin: boolean;
  joinClicked: boolean;
  onJoin: () => void;
}

function CheckSettingsAndJoinBox(props: CheckSettingsAndJoinBoxProps) {
  const {
    showGuestLogin,
    joinClicked,
    onJoin,
  } = props;

  const { formatMessage } = useIntl();
  const classes = useStyles();

  const title = formatMessage(showGuestLogin
    ? messages.insertYourNameAndJoinTitle
    : messages.checkSettingsAndJoinTitle
  );

  const body = formatMessage(showGuestLogin
    ? messages.insertYourNameAndJoinBody
    : messages.checkSettingsAndJoinBody
  );

  const getGuest = () => {
    return (
      <div className={classes.guest}>
        <GuestLoginField onJoin={onJoin} />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="h4" align="center" className={classes.paddingBottomTitle}>
        {title}
      </Typography>
      <Typography variant="body2" align="center" className={classes.paddingBottomBody}>
        {body}
      </Typography>
      {showGuestLogin
        ? getGuest()
        : <WaitingRoomBottomContent canJoin={!joinClicked} onJoin={onJoin} showProgress={joinClicked} />
      }
    </React.Fragment>
  );
}

function WaitingRoomContent(props: ExtendedProps) {
  const {
    isAuthenticatedAsGuest,
    isAuthenticated,
    joiningOrJoined,
    lockedJoinRequestDenied,
    meetingDetails,
    takeSnapshot,
    isSocketConnected,
    snapshot,
    match,
    waitingReason
  } = props;

  const meetingId = getRoomNameFromParams(match.params);

  const dispatch = useDispatch();
  const logger = getLogger(`Meeting ${meetingId}`);

  const [joinClicked, setJoinClicked] = React.useState(joiningOrJoined);

  React.useEffect(
    () => {
      if (isSocketConnected && joinClicked && !joiningOrJoined) {
        logger.info(`joining waiting room ${meetingId}`);
        dispatch(joinWaitingRoom(meetingId, snapshot, logger));
      }
    }
    , [isSocketConnected,
      joinClicked,
      joiningOrJoined,
      snapshot,
      dispatch,
      logger,
      meetingId]
  );

  // use a different effect to ensure the teardown is only executed on component unmount
  React.useEffect(
    () => {
      return () => {
        logger.info('tear down waiting room');
        dispatch(leaveChannel());
        dispatch(tearDownWaitingRoom());
      };
    }, [dispatch, logger]
  );

  const showGuestLogin = !isAuthenticated && !isAuthenticatedAsGuest;

  const onJoin = () => {
    const base64img: string | null = takeSnapshot();
    dispatch(storeSnapshot(base64img));
    setJoinClicked(true);
  };

  const getContent = () => {
    if (lockedJoinRequestDenied) {
      return <LockedJoinRequestDeniedBox />;
    } else if (waitingReason === 'too_early' && meetingDetails) {
      return <TooEarlyForMeetingBox meetingDetails={meetingDetails} takeSnapshot={takeSnapshot} />;
    } else if (waitingReason) {
      return <WaitingMessageBox waitingReason={waitingReason} />;
    } else {
      return <CheckSettingsAndJoinBox showGuestLogin={showGuestLogin} joinClicked={joinClicked} onJoin={onJoin} />;
    }
  };

  return (
    <Grid container direction='column' alignItems='center' justify='center'>
      { getContent()}
    </Grid>
  );
}

function waitingRoomJoined(room: Channel | null, roomName: string | null) {
  roomName = roomName == null ? "" : roomName;
  return Boolean(room) && roomName.startsWith("waiting_room");
}

function isJoiningOrHasJoined(state: State) {
  const joining = state.waitingRoom.isJoining;
  const joined = waitingRoomJoined(state.websocket.room, state.websocket.room_name);
  // when the user arrives too early for a scheduled meeting,
  // joining the waiting room returns an error,
  // but instead of showing a join failure we set the waiting reason to 'too_early'
  return joining || joined || Boolean(state.waitingRoom.waitingReason);
}

type MappedProps = {
  isAuthenticated: boolean;
  isAuthenticatedAsGuest: boolean;
  isSocketConnected: State['websocket']['isConnected'];
  waitingReason: State['waitingRoom']['waitingReason'];
  meetingDetails: State['waitingRoom']['meetingDetails'];
  lockedJoinRequestDenied: State['waitingRoom']['lockedJoinRequestDenied'];
  joiningOrJoined: boolean;
  snapshot: State['waitingRoom']['snapshot'];
}

type Props = {
  takeSnapshot: () => string | null;
} & RouteComponentProps<{ id: string }>

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State): MappedProps => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticatedAsGuest: state.auth.isAuthenticatedAsGuest,
    isSocketConnected: state.websocket.isConnected,
    waitingReason: state.waitingRoom.waitingReason,
    meetingDetails: state.waitingRoom.meetingDetails,
    lockedJoinRequestDenied: state.waitingRoom.lockedJoinRequestDenied,
    joiningOrJoined: isJoiningOrHasJoined(state),
    snapshot: state.waitingRoom.snapshot,
  };
};


export default withRouter(connect(mapStateToProps)(WaitingRoomContent));
