import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';


type Props = {
  icon: React.ReactNode;
  text: string;
  buttonProps: ButtonProps;
}


function ToolbarButton(props: Props) {
  const { icon, text, buttonProps } = props;

  return (
    <Tooltip title={text}>
      <div>
        <Button
          variant='outlined'
          {...buttonProps}
        >
          {icon}
        </Button>
      </div>
    </Tooltip>
  );
}

export default ToolbarButton;
