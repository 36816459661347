/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DateRange from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { DateTimePicker } from '@material-ui/pickers';
/* Other imports */
import IntlPropType from '../../intl';
/* Local Style */
import style from './style';


const messages = defineMessages({
  dateFormat: { id: 'fullDateFormat' },
  reportFilterTemporalRangeRecording: { id: 'reportFilterTemporalRangeRecording' },
  from: { id: 'from' },
  to: { id: 'to' },
  reset: { id: 'reset' },
  close: { id: 'close' },
  reportTitleRecording: { id: 'reportTitleRecording' },
  reportNoteRecording: { id: 'reportNoteRecording' },
  reportSlugRecording: { id: 'reportSlugRecording' },
  ok: { id: 'ok' },
  cancel: { id: 'cancel' },
});

class MenuFilter extends Component {

  constructor(props) {
    super(props);

    const menuFilterConfig = this.props.menuFilterConfig;
    this.state = {
      slugValue: menuFilterConfig.slug || '',
      titleValue: menuFilterConfig.title || '',
      notesValue: menuFilterConfig.notes || ''
    };

    this.onSlugChange = this.onSlugChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onNotesChange = this.onNotesChange.bind(this);
    this.dateEndHandler = this.dateEndHandler.bind(this);
    this.dateStartHandler = this.dateStartHandler.bind(this);
    this.handleEnableTemporalRange = this.handleEnableTemporalRange.bind(this);
    this.onHandleResetFilter = this.onHandleResetFilter.bind(this);

    this.onHandleQueryDebounced = this.onHandleQueryDebounced.bind(this);
    this.onHandleQueryDebounced = this.debounce(500, this.onHandleQueryDebounced);
  }

  handleEnableTemporalRange() {
    this.props.onHandleEnableTemporalRange(!this.props.menuFilterDateConfig.enableTemporalRange, {
      defStart: this.getDefaultStartDateFilterValue(),
      defEnd: this.getDefaultEndDateFilterValue()
    });
  }

  dateStartHandler = date => {
    this.props.onHandleStartDateQuery(date);
  }

  dateEndHandler = date => {
    this.props.onHandleEndDateQuery(date);
  }

  debounce(delay, callback) {
    let timerID = null;
    let start = 0;

    const wrapper = (args) => {
      const now = Number(new Date());

      if (start === 0) {
        start = now;
      }

      const exec = () => {
        start = 0;
        timerID = null;
        callback(args);
      };

      if (timerID) {
        clearTimeout(timerID);
        timerID = null;
      }

      timerID = setTimeout(exec, delay);
    };

    return wrapper;
  }

  onHandleQueryDebounced(data) {
    this.props.onHandleQuery(data);
  }

  onSlugChange = event => {
    const target = event.target;

    this.setState(() => {
      return {
        slugValue: target.value
      };
    });

    return this.onHandleQueryDebounced({ key: target.id, value: target.value });
  }

  onTitleChange = event => {
    const target = event.target;

    this.setState(() => {
      return {
        titleValue: target.value
      };
    });

    return this.onHandleQueryDebounced({ key: target.id, value: target.value });
  }

  onNotesChange = event => {
    const target = event.target;

    this.setState(() => {
      return {
        notesValue: target.value
      };
    });

    return this.onHandleQueryDebounced({ key: target.id, value: target.value });
  }

  getDefaultStartDateFilterValue() {
    const date = new Date();
    date.setHours(0, 0, 0);
    return date;
  }

  getDefaultEndDateFilterValue() {
    const date = new Date();
    date.setHours(23, 59, 59);
    return date;
  }

  onHandleResetFilter() {
    this.setState((_prevState, _props) => {
      return {
        slugValue: '',
        titleValue: '',
        notesValue: '',
      };
    });

    this.props.onHandleResetFilter();
  }

  render() {
    const { classes, menuFilterDateConfig } = this.props;
    return (
      <React.Fragment>
        <div className={classes.menuFilterContainer}>
          <FormControl className={classes.menuFilterFormControl}>
            <TextField
              id="slug"
              label={this.props.intl.formatMessage(messages.reportSlugRecording)}
              onChange={this.onSlugChange}
              margin="normal"
              value={this.state.slugValue}
            />
            <TextField
              id="title"
              label={this.props.intl.formatMessage(messages.reportTitleRecording)}
              onChange={this.onTitleChange}
              margin="normal"
              value={this.state.titleValue}
            />
            <TextField
              id="notes"
              label={this.props.intl.formatMessage(messages.reportNoteRecording)}
              onChange={this.onNotesChange}
              margin="normal"
              value={this.state.notesValue}
            />
            <FormGroup row >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuFilterDateConfig.enableTemporalRange || false}
                    onChange={this.handleEnableTemporalRange}
                    color="default"
                  />
                }
                label={this.props.intl.formatMessage(messages.reportFilterTemporalRangeRecording)}
              />
            </FormGroup>
            {menuFilterDateConfig.enableTemporalRange &&
              <React.Fragment>
                <DateTimePicker
                  label={this.props.intl.formatMessage(messages.from)}
                  ampm={false}
                  value={menuFilterDateConfig.startDate || this.getDefaultStartDateFilterValue()}
                  onChange={this.dateStartHandler}
                  format={this.props.intl.formatMessage(messages.dateFormat)}
                  leftArrowIcon={<KeyboardArrowLeft />}
                  rightArrowIcon={<KeyboardArrowRight />}
                  dateRangeIcon={<DateRange />}
                  timeIcon={<AccessTime />}
                  okLabel={this.props.intl.formatMessage(messages.ok)}
                  cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                />
                <DateTimePicker
                  label={this.props.intl.formatMessage(messages.to)}
                  ampm={false}
                  value={menuFilterDateConfig.endDate || this.getDefaultEndDateFilterValue()}
                  onChange={this.dateEndHandler}
                  format={this.props.intl.formatMessage(messages.dateFormat)}
                  leftArrowIcon={<KeyboardArrowLeft />}
                  rightArrowIcon={<KeyboardArrowRight />}
                  dateRangeIcon={<DateRange />}
                  timeIcon={<AccessTime />}
                  okLabel={this.props.intl.formatMessage(messages.ok)}
                  cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                />
              </React.Fragment>
            }
          </FormControl>
        </div>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={this.onHandleResetFilter} >
            {this.props.intl.formatMessage(messages.reset)}
          </Button>
          <Button color="primary" variant="contained" onClick={this.props.onClose} >
            {this.props.intl.formatMessage(messages.close)}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}


MenuFilter.propTypes = {
  intl: IntlPropType.isRequired,
  classes: PropTypes.object.isRequired,
  menuFilterConfig: PropTypes.object,
  menuFilterDateConfig: PropTypes.object,
  onApplyFilter: PropTypes.func,
  onHandleQuery: PropTypes.func,
  onHandleStartDateQuery: PropTypes.func,
  onHandleEndDateQuery: PropTypes.func,
  onHandleEnableTemporalRange: PropTypes.func,
  onHandleResetFilter: PropTypes.func,
  onClose: PropTypes.func
};


export default withStyles(style)(injectIntl(MenuFilter));
