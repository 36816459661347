/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type myRecordings_recording = {
    readonly id: string;
    readonly status: string | null;
    readonly recStart: string | null;
    readonly recEnd: string | null;
    readonly slug: string | null;
    readonly title: string | null;
    readonly notes: string | null;
    readonly number: string | null;
    readonly " $refType": "myRecordings_recording";
};
export type myRecordings_recording$data = myRecordings_recording;
export type myRecordings_recording$key = {
    readonly " $data"?: myRecordings_recording$data;
    readonly " $fragmentRefs": FragmentRefs<"myRecordings_recording">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "myRecordings_recording",
  "type": "Recording",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'c2f0a99eb1d65104781f82d3fc48a8d2';
export default node;
