import Raven from 'raven-js';


export function startTracer(dsn) {
  Raven.config(dsn).install();
}


export function getRaven() {
  return Raven;
}


export function setTracedUser(id, username, email, ip_addr) {
  Raven.setUserContext({
    id: id,
    username: username,
    email: email,
    ip_address: ip_addr
  });
}


export function unsetTracedUser() {
  Raven.setUserContext({});
}


export function traceWSCloseEvent(event) {
  if (event.wasClean === true) { return; }

  const data = {
    wasClean: event.wasClean,
    type: event.close,
    code: event.code
  };
  Raven.captureMessage("WebSocket Closed", { extra: data });
}


export function traceError(message, event = {}) {
  Raven.captureMessage(message, { extra: event });
}
