import { ObservedUser, ObservedRoster } from '../reduxSelectors/roster';


export function orderedRosterKeys(roster: ObservedRoster, myUid: null | string) {
  const keys = Object.keys(roster);
  const keysAndUserData: [string, ObservedUser][] = keys.map((uid) => [uid, roster[uid]]);
  keysAndUserData.sort((a, b) => {
    const keyA = a[0];
    const keyB = b[0];
    const userDataA = a[1];
    const userDataB = b[1];

    // my user is always shown first, so if any of the two items to compare is
    // my user, prioritize it
    if (keyA === myUid) return -1;
    if (keyB === myUid) return 1;

    // if one of the user has raised their hand, prioritize it. If both did,
    // prefer the one who raised their hand first
    if (userDataA.raisedHand && !userDataB.raisedHand) return -1;
    if (userDataB.raisedHand && !userDataA.raisedHand) return 1;
    if (userDataA.raisedHand && userDataB.raisedHand && (userDataA.raisedHandSince < userDataB.raisedHandSince)) {
      return -1;
    }
    if (userDataA.raisedHand && userDataB.raisedHand && (userDataA.raisedHandSince > userDataB.raisedHandSince)) {
      return 1;
    }

    // otherwise, oder lexicographically by display name
    if (userDataA.display > userDataB.display) return 1;
    else if (userDataA.display < userDataB.display) return -1;
    else return 0;
  });
  return keysAndUserData.map(([a, _b]) => a);
}
