import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { iconColors } from '../../colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    stateContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: theme.zIndex.appBar,
    },
    stateAvatar: {
      border: `4px solid ${theme.palette.secondary.main}`,
      background: iconColors.contrast
    },
    screenButton: {
      backgroundColor: iconColors.contrast,
      width: theme.spacing(3),
      height: theme.spacing(3),
      '&:hover': {
        backgroundColor: iconColors.contrast
      },
      '&:disabled': {
        backgroundColor: iconColors.contrast
      }
    },
    badgeStyle: {
      zIndex: theme.zIndex.modal - 1,
    },
    badgeContent: {
      border: `1px solid ${theme.palette.grey.A100}`,
      borderRadius: '50%'
    },
    badge: {
      marginRight: theme.spacing(0.5)
    },
  }),
);


export default useStyles;
