import { State } from '../reducers';
import { isMobileOnly as isMobile } from 'react-device-detect';

export function showGuestLogin(state: State): boolean {
  return !state.auth.isAuthenticated && !state.auth.isAuthenticatedAsGuest;
}

export function showMobileGuestLogin(state: State): boolean {
  return isMobile && showGuestLogin(state);
}

export function skipJoinMeetingDialog(state: State): boolean {
  return isMobile && !showGuestLogin(state);
}
