import React from 'react';
import Button from '@material-ui/core/Button';
import DialToMeetingDialog from '../DialToMeetingDialog';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { IconPhone } from '../IconSet';


const messages = defineMessages({
  dialToMeeting: { id: 'dialToMeeting' }
});

type Props = { intl: IntlShape };

function InviteDialButton(props: Props) {
  const { intl } = props;
  const [inviteDialogOpen, setInviteDialogOpen] = React.useState<boolean>(false);

  const onClick = React.useCallback(
    () => {
      setInviteDialogOpen(true);
    }
    , []
  );

  const onCloseDialog = React.useCallback(
    () => {
      setInviteDialogOpen(false);
    }
    , []
  );

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<IconPhone size={24} />}
        onClick={onClick}
      >
        {intl.formatMessage(messages.dialToMeeting)}
      </Button>
      {
        inviteDialogOpen &&
        <DialToMeetingDialog
          open={inviteDialogOpen}
          onClose={onCloseDialog}
        />}
    </div>
  );
}
export default (injectIntl(InviteDialButton));
