// main redux state type. Maybe move it to store file

import { Participant } from './api/presence';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type State = any // FIXME

export type Params = { [key: string]: string }

export type Error = {
  errorMessage: string;
  errorCode: number;
}

export type ChatMessageClass = 'event' | 'message'

export type UserMessageSubClass = 'public' | 'private'
export type UserMessageData = {
  from: string;
  fromDisplayName: string;
  message: string;
  timestamp: number;
  color?: string | null;
}
export type UserMessage = {
  class: ChatMessageClass;
  subClass: UserMessageSubClass;
  data: UserMessageData;
}

export type PrivateUserMessageData = {
  to: string;
  toDisplayName: string;
} & UserMessageData
export type PrivateUserMessage = {
  class: ChatMessageClass;
  subClass: UserMessageSubClass;
  data: PrivateUserMessageData;
}
export type PrivateUserMessages = { [k: string]: PrivateUserMessage[] }
export type UnreadPrivateUserMessages = { [k: string]: number }

export type EventMessageSubClass = 'join' | 'quit'
export type EventMessageData = {
  from: string;
  username: string;
  fromDisplayName: string;
  timestamp: number;
  color?: string | null;
}
export type EventMessage = {
  class: ChatMessageClass;
  subClass: EventMessageSubClass;
  data: EventMessageData;
}

export type ChatMessage = EventMessage | UserMessage | PrivateUserMessage

export type ChatMessages = Array<ChatMessage>

export type LastMessage = {
  from: string;
  to?: string;
  displayName: string;
  message: string;
  msgId: string;
}

export type ChatMessageError = {
  error: ChatMessageErrorType;
}

export enum ChatMessageErrorType {
  NoSuchUser = 'no_such_user',
  UserOffline = 'user_offline',
}

export type LinkQuality = Participant['video_uplink_quality']

export type RoomRole = Participant['role']

export type VideoSipPublisher = {
  kind: 'sip';
} & BasicPublisher

export type WebRTCPublisher = {
  kind: 'webrtc';
  video_codec: string;
  audio_codec: string;
} & BasicPublisher

type BasicPublisher = {
  id: number;
  display: string;
}

export type PublisherSubscribeInfo = {
  subscribed: boolean;
  subscribeRequested: boolean;
  subscribeError: boolean;
}

export type Publisher = VideoSipPublisher | WebRTCPublisher

export type RosterUser = {
  is_talking: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stream: null | any;  // FIXME
  streamPublisherData: null | Publisher & PublisherSubscribeInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  screen: null | any;  // FIXME
  screenPublisherData: null | Publisher & PublisherSubscribeInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stream_ts: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  screen_ts: number;
  isVideoMuted: boolean;
  isScreenMuted: boolean;
  isRequestingVideoMute: boolean;
  isRequestingAudioMute: boolean;
  isRequestingAllAudioMute: boolean;
  isRequestingRoleChange: boolean;
  isRequestingUserKick: boolean;
  isRequestingDesktopControl: boolean;
  isDesktopControlEnabled: boolean;
  desktopControlType: string;
  isAudioMuted: boolean;
  muted: boolean;
  isRequestingRaiseHand: boolean;
  raisedHand: boolean;
} & Participant

export type Roster = { [k: string]: RosterUser }


// FIXME: move this types in video room api maybe
export type RoomLayout = 'default' | 'featured' | 'audioonly' | 'webinar' | 'fullscreen' | 'lesson' | 'mobile'
export type RoomLayoutStreamType = 'screen' | 'stream'
export type RoomLayoutConfig = {
  featured_id?: string;
  featured_type?: RoomLayoutStreamType;
  enlarge_video?: boolean;
  observedScreens?: Set<string>;
}
export type UserStreamType = 'screen' | 'stream';
export type Stream = MediaStream;
export type StreamType =
  'remotevideoevent'
  | 'remotescreenevent'
  | 'sip_remotevideoevent'
  | 'localaudioevent'
  | 'localvideoevent'
  | 'screen'
export type RoomError = {
  type?: string;
  code: number;
  reason: string;
  message?: string;
}

export type ScreenSourceType = null | 'window' | 'screen';
export type ScreenSharingOptions = {
  frameRate?: number;
  maxWidth?: number;
  maxHeight?: number;
}
export type StreamOptions = {
  acquireVideo: boolean;
  publishVideo: boolean;
  muted: boolean;
  frameRate?: number;
  streamQuality?: 'low' | 'high' | 'all';
}

export type VideoQualityOptions = {
  frameRate?: number;
  streamQuality?: '180p' | 'vga' | 'nhd' | 'hd' | 'fhd';
}

export type UUID = string;

export type LockedJoinRequest = {
  username: string;
  dname: string;
  snapshot: string;
  reqId: string;
}

export type NotificationPayload = {
  [key: string]: string;
}

export type NotificationEvent = {
  prio: symbol;
  code: number | string;
  reason: string;
  message: string | undefined;
  payload: NotificationPayload;
}
