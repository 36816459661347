import React from 'react';

import { connect, useDispatch } from 'react-redux';

import SplitPane from 'react-split-pane';

import { subscribeToVideo, toggleAllVideoMute } from '../../../lib/actions/room';
import { State } from '../../../lib/reducers';
import { areSecondaryVideosHidden } from '../../../lib/reduxSelectors/presentationLayout';

import usePublishersWatcher from '../usePublishersWatcher';
import MainVideo from './MainVideo';
import SecondaryVideos from './SecondaryVideos';


function PresentationLayout(props: ExtendedProps) {
  // const classes = useStyles();

  const { secondaryPaneVisible } = props;

  const initialPaneSize = 300;

  const [paneSize, setPaneSize] = React.useState(initialPaneSize);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(toggleAllVideoMute(false));
  }, [dispatch]);

  const newPublishers = usePublishersWatcher();

  newPublishers.forEach((u) => {
    if (u.stream) {
      dispatch(subscribeToVideo(u.uid));
    }
    if (u.screen) {
      dispatch(subscribeToVideo(`${u.uid}_screen`));
    }
  });

  return (
    <SplitPane
      primary='second'
      split='vertical'
      size={secondaryPaneVisible ? paneSize : 0}
      minSize={100}
      maxSize={-300} /* FIXME: is there a way to avoid pixels here? */
      defaultSize={initialPaneSize}
      allowResize={secondaryPaneVisible}
      onChange={setPaneSize}
      resizerStyle={{ marginRight: '8px' }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <MainVideo />
      </div>
      <div style={{ width: '100%', height: '100%' }}>
        {secondaryPaneVisible ? <SecondaryVideos /> : null }
      </div>
    </SplitPane>
  );
}


type MappedProps = {
  secondaryPaneVisible: boolean;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  return {
    secondaryPaneVisible: !areSecondaryVideosHidden(state),
  };
};


export default connect(mapStateToProps)(PresentationLayout);
