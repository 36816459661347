import React from 'react';

import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { IconMicrophone, IconMicrophoneOff } from '../IconSet';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import messages from './buttonsMessages';

import { State as RoomState } from '../../lib/reducers/room';
import { State as WebsocketState } from '../../lib/reducers/websocket';
import { toggleOwnAudioMute } from '../../lib/actions/room';
import { iconColors as colors } from '../../colors';


function MicButton(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const muteAudio = React.useCallback(
    () => dispatch(toggleOwnAudioMute(!props.isAudioMuted))
    , [dispatch, props.isAudioMuted]
  );

  const label = props.isAudioMuted ? formatMessage(messages.enable) : formatMessage(messages.disable);
  const selected = props.isAudioMuted ? classes.buttonsSelected : null;
  const cls = classNames(classes.buttons, selected);

  return (
    <ToolbarButton
      icon={props.isAudioMuted
        ? <IconMicrophoneOff color={colors.contrast} size={28} />
        : <IconMicrophone size={28} />
      }
      text={label}
      buttonProps={{ className: cls, onClick: muteAudio }}
    />
  );
}


type State = {
  room: RoomState;
  websocket: WebsocketState;
}


type MappedProps = {
  isAudioMuted: boolean;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const user = state.websocket.uid;
  let isAudioMuted = false;
  if (user) {
    isAudioMuted = (state.room.roster[user] || { isAudioMuted: false }).isAudioMuted;
  }
  return ({ isAudioMuted });
};


export default connect(mapStateToProps)(MicButton);
