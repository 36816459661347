import React from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

import { IconSwitchCamera } from '../IconSet';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import { RtcDevices } from '../../lib/api/rtcDevices';
import prepareWebRtcProvider from '../../rtc';
import { getLogger } from '../../lib/logger';
import { saveVideoConfig } from '../../lib/actions/settings';
import LocalStorage from '../../localStorage';
import { State } from '../../lib/reducers';
import { republishStream } from '../../lib/actions/room';
import { VideoRoom } from '../../lib/api/videoroom';


type Props = {} & MappedProps


function getRtc() {
  const logger = getLogger('SwitchCamera');
  const webrtc = prepareWebRtcProvider();
  return new RtcDevices(webrtc, logger);
}

function discoverDevices() {
  const rtc = getRtc();
  return rtc.discoverDevices();
}

function getVideoDevices(devs: MediaDeviceInfo[]): MediaDeviceInfo[] {
  const videoIns: MediaDeviceInfo[] = [];
  devs.forEach((d) => {
    if (d.kind === 'videoinput') {
      videoIns.push(d);
    }
  });

  return videoIns;
}


function SwitchCamera(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [videoDevices, setVideoDevices] = React.useState<MediaDeviceInfo[]>([]);
  const mountedRef = React.useRef<boolean>(true);

  const scanDevices = React.useCallback(
    () => {
      discoverDevices().then(
        (devs: ReturnType<typeof discoverDevices>) => {
          if (mountedRef.current) {
            setVideoDevices(getVideoDevices(devs));
          }
        }
      );
    }
    , [setVideoDevices]
  );

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(
    () => {
      mountedRef.current = true;
      scanDevices();
    }
    , [scanDevices]
  );

  const handleCameraSwitch = () => {
    const prevVideoIn = props.videoDevice;
    const prevIdx = prevVideoIn
      ? videoDevices.findIndex((el) => el.deviceId === prevVideoIn.deviceId)
      : 0;
    const nextIdx = (prevIdx + 1) % videoDevices.length;
    const newVideoIn = videoDevices[nextIdx];
    if (newVideoIn) {
      const streamOptions = {
        acquireVideo: true,
        publishVideo: props.canPublishVideo,
        muted: false,
      };
      dispatch(saveVideoConfig(newVideoIn, new LocalStorage()));
      dispatch(republishStream(streamOptions, getLogger('Republish stream')));
    }
  };

  if (props.hasVideoStream) {
    return (
      <ToolbarButton
        icon={<IconSwitchCamera size={28} />}
        text=''
        buttonProps={{ className: classes.buttons, onClick: handleCameraSwitch }}
      />
    );
  }
  else {
    return null;
  }
}


type MappedProps = {
  videoDevice: State['settings']['videoDevice'];
  hasVideoStream: boolean;
  canPublishVideo: boolean;
}

const mapStateToProps = (state: State): MappedProps => {
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }
  return {
    videoDevice: state.settings.videoDevice,
    hasVideoStream: hasVideoStream,
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
  };
};


export default connect(mapStateToProps)(SwitchCamera);
