import { fetchQuery, IEnvironment } from 'relay-runtime';
import {
  searchUserQueryResponse,
  searchUserQueryVariables,
  SearchFilter,
} from './__generated__/searchUserQuery.graphql';
import { graphql } from 'babel-plugin-relay/macro';


const searchUserQuery = graphql`
query searchUserQuery(
  $first: Int
  $filters: [SearchFilter]
  $advancedFilter: String
) {
  search(first: $first, searchType: USER, filters: $filters, advancedFilter: $advancedFilter) {
    edges {
      node {
        ... on User {
          id,
          email,
          name,
          surname,
          username,
        }
      }
    }
  }
}
`;


type searchUserResponse = searchUserQueryResponse | null

function buildAdvancedFilter(filter: string): string {
  const userFilter = {
    email: filter,
    username: filter,
    name: filter,
    surname: filter
  };

  const advancedFilter = [];

  for (const key in userFilter) {
    advancedFilter.push(`${key} %=% "${filter}"`);
  }

  return advancedFilter.join(" or ");
}

async function searchUsers(environment: IEnvironment, filter: string): Promise<searchUserResponse> {
  const variables: searchUserQueryVariables = {
    first: 5,
    filters: [],
    advancedFilter: buildAdvancedFilter(filter)
  };
  try {
    const data = await fetchQuery(environment, searchUserQuery, variables);
    return data as searchUserResponse;
  }
  catch (_e) {
    return null;
  }
}

async function searchUserById(environment: IEnvironment, filter: string): Promise<searchUserResponse> {
  const filters: SearchFilter = {
    key: 'id',
    match: 'EQUALS',
    value: filter
  };
  const variables: searchUserQueryVariables = {
    first: 1,
    filters: [filters]
  };
  try {
    const data = await fetchQuery(environment, searchUserQuery, variables);
    return data as searchUserResponse;
  }
  catch (_e) {
    return null;
  }
}


export type User = {
  id: string;
  email: string;
  name?: string | null;
  surname?: string | null;
  username: string;
}

type Users = Array<User> | null;


function getUsers(response: searchUserResponse): Users {
  if (response && response.search && response.search.edges) {
    const users = response.search.edges
      .map((node) => node ? node.node : null)
      .map((node) => {
        if (node && node.id && node.email && node.username) {
          const user: User = {
            id: node.id,
            email: node.email,
            name: node.name,
            surname: node.surname,
            username: node.username
          };
          return user;
        } else {
          return null;
        }
      });
    return users ? users.filter((u): u is User => !!u) : null;
  }
  return null;
}


export { searchUsers, searchUserById, getUsers };
