import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { InputLabel, Select, MenuItem, Tooltip } from '@material-ui/core';
import { MeetingPrivacy, meetingPrivacyValues } from '../../lib/api/relay/utils/types';
import { IconHelp } from '../IconSet';


const messages = defineMessages({
  label: { id: 'privacyLabel' },
  info: { id: 'privacyInfo' }
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(1.25)
    },
    select: {
      flexGrow: 1,
    },
    tooltip: {
      whiteSpace: 'pre-line'
    },
    info: {
      padding: theme.spacing(1.5)
    }
  })
);


function MeetingPrivacySelector(props: ExtendedProps) {
  const { setMeetingPrivacy, defaultMeetingPrivacy } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [ value, setValue ] = React.useState<MeetingPrivacy>(defaultMeetingPrivacy);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type: MeetingPrivacy = event.target.value as MeetingPrivacy;
    setValue(type);
    setMeetingPrivacy(type);
  };

  return (
    <React.Fragment>
      <InputLabel>{formatMessage(messages.label)}</InputLabel>
      <div className={classes.root}>
        <Select value={value} onChange={handleChange} className={classes.select}>
          <MenuItem value={meetingPrivacyValues.open.value}>
            {formatMessage(meetingPrivacyValues.open.label)}
          </MenuItem>
          <MenuItem value={meetingPrivacyValues.locked.value}>
            {formatMessage(meetingPrivacyValues.locked.label)}
          </MenuItem>
          <MenuItem value={meetingPrivacyValues.logged.value}>
            {formatMessage(meetingPrivacyValues.logged.label)}
          </MenuItem>
          <MenuItem value={meetingPrivacyValues.lockedLogged.value}>
            {formatMessage(meetingPrivacyValues.lockedLogged.label)}
          </MenuItem>
        </Select>
        <Tooltip title={<span className={classes.tooltip}>{formatMessage(messages.info)}</span>}>
          <div className={classes.info}>
            <IconHelp size={24} />
          </div>
        </Tooltip>
      </div>
    </React.Fragment>
  );
}


type Props = {
  setMeetingPrivacy: (type: MeetingPrivacy) => void;
  defaultMeetingPrivacy: MeetingPrivacy;
}

type ExtendedProps = Props


export default MeetingPrivacySelector;
