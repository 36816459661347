/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Send from '@material-ui/icons/Send';
import Cancel from '@material-ui/icons/Cancel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
/* Components Imports */
import DownloadIcon from '../../style/DownloadIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
import MyRecordingPlayer from './MyRecordingPlayer';
/* Other Imports */
import IntlPropType from '../../intl';
import { newEvent, INFO } from '../../lib/notifications';
import { myRecordingsFragment } from '../../lib/api/relay/myRecordings';
import { deleteRecording } from '../../lib/api/relay/deleteRecording';
import moment from 'moment';
/* Local Style */
import style from './style';

const messages = defineMessages({
  reportPlayRecording: { id: 'reportPlayRecording' },
  reportDeleteRecording: { id: 'reportDeleteRecording' },
  reportDownloadRecording: { id: 'reportDownloadRecording' },
});

class MyRecording extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openVideoPlayer: false,
    };
    this.onCloseVideoPlayer = this.onCloseVideoPlayer.bind(this);
    this.handlePlayButton = this.handlePlayButton.bind(this);
    this.handleDeleteButton = this.handleDeleteButton.bind(this);
  }

  format_date(date) {
    if (date) {
      return moment(date).format("DD-MM-YYYY, HH:mm:ss");
    }
    return '-';
  }

  get_duration(start, end) {
    if (!start || !end) {
      return '-';
    }
    const seconds = moment(end).unix() - moment(start).unix();
    return moment.utc(seconds * 1000).format('HH:mm:ss');
  }

  handlePlayButton() {
    this.setState({ openVideoPlayer: true });
  }

  handleDeleteButton() {
    const { relay, userId, domainId } = this.props;
    const promise = deleteRecording(relay.environment, this.props.data.id, userId, domainId);
    promise.then(
      () => {
        newEvent(INFO, 'deleteRecordingSuccess', 'deleteRecordingSuccess',
          "Delete recording success");
        this.props.onDeleteRecording();
      })
      .catch(
        () => { });
  }

  onCloseVideoPlayer() {
    this.setState({ openVideoPlayer: false });
  }

  getDownloadFileName() {
    let dateText = "";
    const recStart = this.props.data.recStart;
    if (recStart) {
      dateText = "-" + moment(recStart).format("DD-MM-YYYY-HH-mm-ss");
    }
    return this.props.data.slug + dateText + '.mp4';
  }

  getDownloadCmp() {
    const link = React.forwardRef((props, _ref) => (
      <Link target="_blank"
        download={this.getDownloadFileName()}
        to={this.get_file_url()} {...props} />
    ));
    link.displayName = "LinkWithForwardedRef";
    return link;
  }

  get_file_url() {
    return `/getfile/${this.props.data.id}`;
  }

  get_download_icon() {
    let ready = this.props.data.status;
    return (
      <TooltipMultiLineText

        placement="top"
        title={this.props.intl.formatMessage(messages.reportDownloadRecording)}>

        <ListItem
          ref={React.createRef()}
          className={this.props.classes.button}
          button
          disabled={ready !== 'ready' ? true : false}
          component={this.getDownloadCmp()}
        >
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
        </ListItem>
      </TooltipMultiLineText>
    );
  }

  get_play_icon() {
    let ready = this.props.data.status;
    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(messages.reportPlayRecording)}>
        <ListItem
          className={this.props.classes.button}
          button
          disabled={ready !== 'ready' ? true : false}
          onClick={this.handlePlayButton}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
        </ListItem>
      </TooltipMultiLineText>
    );
  }

  get_delete_icon() {
    let ready = this.props.data.status;
    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(messages.reportDeleteRecording)}>
        <ListItem
          className={this.props.classes.button}
          button
          disabled={ready !== 'ready' ? true : false}
          onClick={this.handleDeleteButton}
        >
          <ListItemIcon>
            <Cancel />
          </ListItemIcon>
        </ListItem>
      </TooltipMultiLineText >
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <TableRow>
          <TableCell className={classes.table_cell}>
            {this.props.data.title}
          </TableCell>
          <TableCell className={classes.table_cell}>
            {this.props.data.slug}
          </TableCell>
          <TableCell className={classes.table_cell}>
            {this.format_date(this.props.data.recStart)}
          </TableCell>
          <TableCell className={classes.table_cell}>
            {this.get_duration(this.props.data.recStart, this.props.data.recEnd)}
          </TableCell>
          <TableCell className={classes.table_cell}>
            {this.props.data.notes}
          </TableCell>
          <TableCell className={classes.table_cell}>
            {this.get_play_icon()}
          </TableCell>
          <TableCell className={classes.table_cell}>
            {this.get_download_icon()}
          </TableCell>
          <TableCell className={classes.table_cell}>
            {this.get_delete_icon()}
          </TableCell>
        </TableRow>
        {this.state.openVideoPlayer === true &&
          <MyRecordingPlayer
            onCloseClick={this.onCloseVideoPlayer}
            recordingUrl={this.get_file_url()}
          />
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state.session.userId,
    domainId: state.session.domainId,
  };
}

MyRecording.propTypes = {
  intl: IntlPropType.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  relay: PropTypes.object.isRequired,
  onDeleteRecording: PropTypes.func.isRequired,
  userId: PropTypes.string,
  domainId: PropTypes.string,
};

export default createFragmentContainer(
  withStyles(style)(injectIntl(connect(mapStateToProps)(MyRecording))), { data: myRecordingsFragment });
