import { commitMutation } from 'react-relay';
import { IEnvironment } from 'relay-runtime';
import { graphql } from 'babel-plugin-relay/macro';
import { newEvent, ERROR } from '../../notifications';

import { deleteMeetingMutation, deleteMeetingMutationResponse } from './__generated__/deleteMeetingMutation.graphql';

const mutation = graphql`
mutation deleteMeetingMutation(
  $input: DeleteMeetingInput!
) {
  deleteMeeting(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
    }
  }
}
`;


function maybeNotifyError(response: deleteMeetingMutationResponse | null) {
  if (!response || !response.deleteMeeting || response.deleteMeeting.errors !== null) {
    newEvent(ERROR, 'deleteMeetingError', 'deleteMeetingError',
      "Delete meeting error");
  }
}


function deleteMeeting(environment: IEnvironment, id: string, userId: string, domainId: string) {
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          userId,
          domainId,
          id
        }
      },
    };
    commitMutation<deleteMeetingMutation>(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { deleteMeeting };
