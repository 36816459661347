import React from 'react';
import StyledMenu from '../../StyledMenu';
import KickMenuItem from './KickMenuItem';
import PipMenuItem from './PipMenuItem';
import FullscreenMenuItem from './FullscreenMenuItem';
import ChangeRoleMenuItem from './ChangeRoleMenuItem';
import LowerHandMenuItem from './LowerHandMenuItem';
import MainActions from './MainActions';


export type ShowItems = {
  fs?: boolean;
  pip?: boolean;
  changeRole?: boolean;
  kick?: boolean;
}

type MenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  user: string;
  mini?: boolean;
  uid?: string;
  showItems: ShowItems;
}


function ExtMenu({ anchorEl, onClose, user, mini, uid, showItems }: MenuProps) {
  const { fs, pip, changeRole, kick } = showItems;

  return (
    <StyledMenu
      disablePortal
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      { mini && uid && <MainActions uid={uid} mini={mini} /> }
      { fs && <FullscreenMenuItem onClose={onClose} user={user} /> }
      { pip && <PipMenuItem onClose={onClose} user={user} /> }
      { <LowerHandMenuItem onClose={onClose} uid={user} /> }
      { changeRole && <ChangeRoleMenuItem onClose={onClose} user={user} /> }
      { kick && <KickMenuItem onClose={onClose} user={user} /> }
    </StyledMenu>
  );
}

export default ExtMenu;
