import React from 'react';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';

import LocalStorage from '../../localStorage';
import PaneDesktop from './Pane/PaneDesktop';
import PaneMobile from './Pane/PaneMobile';

import { State } from '../../lib/reducers';
import { isRecorder } from '../../lib/reduxSelectors/session';
import {
  isAudioOnlyLayout,
  isThereAnyVideo,
  isWebinarLayout,
  isLessonLayout } from '../../lib/reduxSelectors/room';
import { amModerator } from '../../lib/reduxSelectors/room';


type Props = {
  handleScreenShare: () => void;
  enableDesktopControl: () => void;
  localStore: LocalStorage;
  url: string;
}

type ExtendedProps = Props & MappedProps


export enum PaneType {
  Roster,
  Chat,
  Info,
}

export type SelectedPane = PaneType | null;


function Meeting(props: ExtendedProps) {
  const [paneOpened, setPaneOpened] = React.useState(false);
  const [selectedPane, setSelectedPane] = React.useState<SelectedPane>(isMobileOnly ? PaneType.Roster : null);

  const disableLayoutAndRoster = (
    !props.isThereAnyVideo
    || (props.isWebinarLayout && !props.amModerator)
    || (props.isLessonLayout && !props.amModerator)
    || props.isAudioOnlyLayout
  );

  const openPane = (
    wantedPane: PaneType,
    isPaneOpened: boolean,
    currentPane: SelectedPane
  ) => {
    if (!isPaneOpened) {
      setPaneOpened(true);
      setSelectedPane(wantedPane);
    }
    else {
      if (currentPane === wantedPane) {
        // user clicked on the icon relative to the currently opened pane:
        // close it
        setPaneOpened(false);
      }
      else {
        // user clicked on another pane: open the new pane
        setSelectedPane(wantedPane);
      }
    }
  };

  if (paneOpened && selectedPane === PaneType.Roster && disableLayoutAndRoster) {
    setSelectedPane(null);
    setPaneOpened(false);
  }

  return (
    <React.Fragment>
      {isMobileOnly
        ? <PaneMobile selectedPane={selectedPane} openPane={openPane} url={props.url} />
        : <PaneDesktop paneOpened={paneOpened} selectedPane={selectedPane} openPane={openPane} url={props.url} />
      }
    </React.Fragment>
  );
}


type MappedProps = {
  isRecorder: boolean;
  isThereAnyVideo: boolean;
  isWebinarLayout: boolean;
  isLessonLayout: boolean;
  isAudioOnlyLayout: boolean;
  amModerator: boolean;
}


const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    isRecorder: isRecorder(state, ownProps.url),
    isThereAnyVideo: isThereAnyVideo(state),
    isWebinarLayout: isWebinarLayout(state),
    isLessonLayout: isLessonLayout(state),
    isAudioOnlyLayout: isAudioOnlyLayout(state),
    amModerator: amModerator(state),
  };
};


export default connect(mapStateToProps)(Meeting);
