import { VideoRoom } from '../../../lib/api/videoroom';
import { State } from '../../../lib/reducers';
import {
  RoomLayout,
  RoomLayoutConfig
} from '../../../lib/redux_types';
import {
  findRoomOwner,
  hasScreen,
  hasStream,
  findSelectedStream,
} from '../../../lib/reduxSelectors/presentationLayout';

import { getStreams } from '../StandardDimensionLayout';


export type MappedProps = {
  hasVideoStream: boolean;
  localVideoStream: State['room']['localvideo_stream'];
  localScreenStream: State['room']['screenStream'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remoteVideoStreams: any; // FIXME
  myUserId: State['websocket']['uid'];
  layout: RoomLayout;
  layoutConfig: RoomLayoutConfig;
  selectedStream: null | string;
  myStreamIsSelected: boolean;
  myScreenIsSelected: boolean;
  roomOwner: null | string;   // uid of room owner (might be null if room owner leaves)
  roomOwnerHasScreen: boolean;
  roomOwnerHasStream: boolean;
}


export type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const myUserId = state.websocket.uid;

  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }

  const {
    selectedStream,
    myStreamIsSelected,
    myScreenIsSelected,
  } = findSelectedStream(state, hasVideoStream);

  const roomOwner = findRoomOwner(state);
  const roomOwnerHasStream = roomOwner ? hasStream(state, roomOwner) : false;
  const roomOwnerHasScreen = roomOwner ? hasScreen(state, roomOwner) : false;

  return {
    myUserId,
    localVideoStream: state.room.localvideo_stream,
    localScreenStream: state.room.screenStream,
    remoteVideoStreams: getStreams(state),
    hasVideoStream,
    layout: state.room.layout,
    layoutConfig: state.room.layoutConfig,
    selectedStream,
    myStreamIsSelected,
    myScreenIsSelected,
    roomOwner,
    roomOwnerHasScreen,
    roomOwnerHasStream,
  };
};


export default mapStateToProps;
