import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  ADD_LOCKED_JOIN_REQUEST,
  SET_LOCKED_JOIN_REQUESTS,
  REMOVE_LOCKED_JOIN_REQUEST,
  CLEAR_LOCKED_JOIN_REQUESTS,
  SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED
} from '../actions/notifications';

const defaultState = {
  notifications: [],
  lockedJoinRequests: [],
  lockedJoinRequestDialogMinimized: false
};

function notifications(state = defaultState, action = {}) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      let new_notifications = [];
      if (action.payload) {
        new_notifications = state.notifications.concat([
          Object.assign({}, action.payload)]);
      } else {
        new_notifications = state.notifications;
      }
      return Object.assign({}, state, {
        notifications: new_notifications
      });
    }
    case REMOVE_NOTIFICATION: {
      const payload = action.payload || {};
      return Object.assign({}, state, {
        notifications: state.notifications.filter(({ ref }) => {
          return payload.ref !== ref;
        }),
      });
    }
    case ADD_LOCKED_JOIN_REQUEST: {
      let newLockedJoinRequests = [];
      if (action.payload && action.payload.request) {
        newLockedJoinRequests = state.lockedJoinRequests.concat([
          Object.assign({}, action.payload.request)]);
      } else {
        newLockedJoinRequests = state.lockedJoinRequests;
      }
      return Object.assign({}, state, {
        lockedJoinRequests: newLockedJoinRequests
      });
    }
    case SET_LOCKED_JOIN_REQUESTS: {
      if (action.payload && action.payload.requests) {
        return Object.assign({}, state, {
          lockedJoinRequests: action.payload.requests
        });
      }
      return state;
    }
    case REMOVE_LOCKED_JOIN_REQUEST: {
      const payload = action.payload || {};
      return Object.assign({}, state, {
        lockedJoinRequests: state.lockedJoinRequests.filter((request) => {
          return payload.reqId !== request.reqId;
        }),
      });
    }
    case CLEAR_LOCKED_JOIN_REQUESTS: {
      return Object.assign({}, state, {
        lockedJoinRequests: [],
      });
    }
    case SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED: {
      return Object.assign({}, state, {
        lockedJoinRequestDialogMinimized: action.payload.minimized,
      });
    }
    default:
      return state;
  }
}

export default notifications;
export { defaultState };
