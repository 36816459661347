import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import List from '@material-ui/core/List';
import { Tab, Typography, Tooltip, Badge } from '@material-ui/core';

import { State } from '../../lib/reducers';
import Input from './ChatInput';
import Messages from './ChatMessages';
import { AggregateChatMessage, makeGetChatMessages } from './utils';
import useStyles from './chatStyles';
import { resetUnreadPublicUserMessages } from '../../lib/actions/room';
import { hideChatUsersAndMessages } from '../../lib/reduxSelectors/roster';


const messages = defineMessages({
  everyone: { id: 'everyone' },
});


function PublicChatTabLabel(props: Props) {
  const { formatMessage } = useIntl();
  const { index, currVal, ...others } = props;

  const dispatch = useDispatch();
  const isSelected = index === currVal;

  const classes = useStyles();
  const name = formatMessage(messages.everyone);

  const unreadMessageCount = useSelector((state: State) => state.room.unreadPublicMessages);
  const isChatOpen = useSelector((state: State) => state.room.isChatOpen);

  React.useEffect(
    () => {
      if (isSelected && isChatOpen) {
        dispatch(resetUnreadPublicUserMessages());
      }
    }
  );

  const getLabel = () => {
    return (
      <Badge
        badgeContent={unreadMessageCount}
        overlap='circle'
        variant='dot'
        color="secondary">
        <Tooltip title={name}>
          <Typography noWrap variant='caption' className={classes.label}>
            {name}
          </Typography>
        </Tooltip>
      </Badge>
    );
  };

  return (
    <Tab className={classes.tab} label={getLabel()} value={index} {...others} />
  );
}


function PublicChat(props: ExtendedProps) {
  const classes = useStyles();
  const { chatMessages, hideEventMessages, currVal, index } = props;

  const filteredMessages = hideEventMessages
    ? chatMessages.filter((m) => m.class !== 'event')
    : chatMessages;

  return (
    <React.Fragment>
      {(index === currVal) &&
        <React.Fragment>
          <List className={classes.messageList}>
            <Messages messages={filteredMessages} />
          </List>
          <Input />
        </React.Fragment>
      }
    </React.Fragment>
  );
}


type Props = {
  index: number;
  currVal: number;
}

type MappedProps = {
  chatMessages: AggregateChatMessage[];
  hideEventMessages: boolean;
}

type ExtendedProps = Props & MappedProps

const makeMapStateToProps = () => {
  const getChatMessages = makeGetChatMessages();
  const mapStateToProps = (state: State): MappedProps => ({
    chatMessages: getChatMessages(state.room.publicMessages),
    hideEventMessages: hideChatUsersAndMessages(state),
  });
  return mapStateToProps;
};

const PublicChatTab = connect(makeMapStateToProps)(PublicChat);


export { PublicChatTabLabel, PublicChatTab };
