import {
  RELAY_REQUEST,
  RELAY_DONE,
  Action,
} from '../actions/relay';

export interface State {
  isFetching: boolean;
  type: string;
  lastRequestId: string | null;
  operation: string ;
  error: Error | null;
}

const defaultState: State = {
  isFetching: false,
  type: "",
  lastRequestId: null,
  operation: "",
  error: null,
};

function relay(state: State = defaultState, action: Action) {
  switch (action.type) {
    case RELAY_REQUEST:
      return Object.assign({}, state, {
        type: RELAY_REQUEST,
        isFetching: true,
        lastRequestId: action.payload.lastRequestId,
        operation: action.payload.operation
      });
    case RELAY_DONE:
      return Object.assign({}, state, {
        type: RELAY_DONE,
        isFetching: false,
        lastRequestId: action.payload.lastRequestId,
        operation: action.payload.operation,
        error: action.payload.error
      });
    default:
      return state;
  }
}

export default relay;
export { defaultState };
