import React from 'react';

import { connect, useDispatch } from 'react-redux';

import { State as WebsocketState } from '../../lib/reducers/websocket';
import { State as RoomState } from '../../lib/reducers/room';
import { toggleOwnAudioMute } from '../../lib/actions/room';


function isTextArea(ev: KeyboardEvent) {
  const target = ev.target as HTMLInputElement;
  const targetType = target.type;
  return (targetType === 'text' || targetType === 'textarea');
}


function ShortcutKeys(props: ExtendedProps) {
  const { isOwnAudioMuted } = props;

  const dispatch = useDispatch();

  // track the muted prop manually to avoid the dependency in the useEffect()
  // below (we need to install and remove listeners only on mount/unmount, not
  // every time the prop changes
  const isMuted = React.useRef(isOwnAudioMuted);

  React.useEffect(() => {
    isMuted.current = isOwnAudioMuted;
  }
  , [isOwnAudioMuted]
  );

  React.useEffect(() => {
    const onKeyUp = (ev: KeyboardEvent) => {
      if (!isTextArea(ev) && ev.key.toLowerCase() === 'm') {
        dispatch(toggleOwnAudioMute(!isMuted.current));
      }
    };
    window.addEventListener('keyup', onKeyUp, false);
    return () => {
      window.removeEventListener('keyup', onKeyUp, false);
    };
  }
  , [dispatch]
  );

  return null;
}


type State = {
  room: RoomState;
  websocket: WebsocketState;
}


type MappedProps = {
  isOwnAudioMuted: boolean;
}


type ExtendedProps = {} & MappedProps;

const mapStateToProps = (state: State): MappedProps => {
  const myUserId = state.websocket.uid;
  let isOwnAudioMuted = false;
  if (myUserId) {
    isOwnAudioMuted = (state.room.roster[myUserId] || { isAudioMuted: false }).isAudioMuted;
  }
  return {
    isOwnAudioMuted: isOwnAudioMuted,
  };
};


export default connect(mapStateToProps)(ShortcutKeys);
