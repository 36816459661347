/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* UI Imports */
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Pip from '@material-ui/icons/PictureInPictureAlt';
import { Typography } from '@material-ui/core';
/* Other Imports */
import prepareWebRtcProvider from '../../rtc';
import { RtcDevices } from '../../lib/api/rtcDevices';
import { getLogger } from '../../lib/logger';
import VideoTextOverlay from '../VideoTextOverlay';
import { togglePip } from '../../lib/actions/room';
import PiP from '../../pip';
import { getInitials } from '../../lib/utils/displayName';
/* Local Style */
import style from './style';
import loading from '../../assets/icons/loading.gif';


class VideoElement extends Component {
  onPipDisabled = () => {
    this.props.dispatch(togglePip(null));
  }

  onPipEnabled = () => {
    this.props.dispatch(togglePip(this.props.user));
  }

  componentDidMount() {
    this.pip = new PiP(this.video);
    if (this.props.src) {
      this.video.srcObject = this.props.src;
    }
    this.video.muted = !!this.props.muted;
    this.pip.addListeners(this.onPipEnabled, this.onPipDisabled);
  }

  componentWillUnmount() {
    if (this.props.pipEnabled && this.props.user === this.props.pipEnabled) {
      this.props.dispatch(togglePip(null));
      this.pip.set(null);
      this.pip.removeListeners(this.onPipEnabled, this.onPipDisabled);
    }
    if (this.video) {
      this.video.src = '';
      this.video.srcObject = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.src && this.props.src !== prevProps.src) {
      this.video.srcObject = this.props.src;
    }
    if (this.props.muted !== prevProps.muted) {
      this.video.muted = !!this.props.muted;
    }
    if (this.props.handleAudioOutputChange) {
      this.setOutputDevice(prevProps.sink, this.props.sink);
    }
    if (this.props.user === this.props.pipEnabled && this.props.pipEnabled !== prevProps.pipEnabled) {
      this.pip.set(this.props.pipEnabled);
    }
    if (this.props.user === prevProps.pipEnabled && this.props.pipEnabled !== prevProps.pipEnabled) {
      this.pip.set(null);
    }
  }

  setOutputDevice(oldDevice, newDevice) {
    const logger = getLogger('VideoElement Set Output');
    const webrtc = prepareWebRtcProvider();
    const rtc = new RtcDevices(webrtc, logger);

    if (newDevice && newDevice !== oldDevice) {
      rtc.setAudioOutputOn(this.video, newDevice).then(() => {
        logger.debug("Changed audio output to ", newDevice);
      }).catch((e) => logger.error(e));
    }
  }

  doNothing() {
    // This is ugly, but to allow hovering on ios, the container element must
    // be clickable, so for now just add an empty click callback
    // cfr. https://developer.apple.com/library/content/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html
  }

  onDoubleClick = () => {
    let user = this.props.user;
    if (user) {
      let stream_type = 'stream';
      if (user.endsWith('_screen')) {
        stream_type = 'screen';
      }
      user = user.replace(/_screen$/, '');

      if (this.props.onDoubleClick) {
        this.props.onDoubleClick(user, stream_type);
      }
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}
        onDoubleClick={this.onDoubleClick}>
        <div className={classes.fullHeight}>
          <video className={classNames({
            [classes.video]: true,
            [classes.mirrored]: this.props.mirrored,
            [classes.rounded]: this.props.rounded,
            [classes.muted]: this.props.isVideoMuted,
            [classes.fullHeight]: this.props.fullHeight,
            [classes.fit]: this.props.fit,
            [classes.talkingIndicator]: this.props.isTalking && !this.props.circled,
            [classes.circled]: this.props.circled,
          })}
          autoPlay
          playsInline
          poster={!this.props.isVideoMuted ? loading : null}
          ref={(ref) => {
            this.video = ref;
            if (this.props.videoRef)
              this.props.videoRef.current = ref;
          }} onClick={this.doNothing}
          />
          <VideoTextOverlay text={this.props.text} />
        </div>
        {this.props.isVideoMuted && this.props.addVideoMutedIconOverlay &&
          <div className={classNames({
            [classes.videoMutedOverlayContainer]: true,
            [classes.rounded]: this.props.rounded,
            [classes.fit]: this.props.fit,
          })}>
            <Typography className={classes.videoMutedInitials}>
              {getInitials(this.props.display)}
            </Typography>
          </div>
        }
        {this.props.pipEnabled && this.props.pipEnabled === this.props.user &&
          <div className={classes.videoPipOverlayContainer}>
            <Pip style={{ height: '50%', width: '50%' }}
              className={classes.videoPipOverlay} />
          </div>
        }
      </div>
    );
  }
}


VideoElement.propTypes = {
  src: PropTypes.object.isRequired,
  mirrored: PropTypes.bool,
  rounded: PropTypes.bool,
  muted: PropTypes.bool,
  handleAudioOutputChange: PropTypes.bool,
  sink: PropTypes.object,
  fullHeight: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  isVideoMuted: PropTypes.bool,
  isTalking: PropTypes.bool,
  text: PropTypes.string,
  user: PropTypes.string,
  onDoubleClick: PropTypes.func,
  addVideoMutedIconOverlay: PropTypes.bool,
  pipEnabled: PropTypes.string,
  onPipEnabled: PropTypes.func,
  onPipDisabled: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  fit: PropTypes.bool,
  display: PropTypes.string,
  circled: PropTypes.bool,
  videoRef: PropTypes.oneOfType([
    PropTypes.func, // for legacy refs
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLVideoElement) })
  ])
};


VideoElement.defaultProps = {
  muted: true,
  rounded: false,
  fullHeight: true,
  handleAudioOutputChange: false,
  addVideoMutedIconOverlay: false,
  pipEnabled: null,
  fit: false,
  circled: false,
};


function getVideoMutedProperty(state, user) {
  if (!user) {
    return false;
  }
  let prop = 'isVideoMuted';
  if (user.endsWith('_screen')) {
    prop = 'isScreenMuted';
  }
  user = user.replace(/_screen$/, '');
  return (state.room.roster[user] || { prop: false })[prop];
}


function detectTalking(state, user) {
  if (!user) {
    return false;
  }
  return (state.room.roster[user] || { is_talking: false }).is_talking;
}


function getDisplayName(state, user) {
  if (!user) {
    return '';
  }
  return (state.room.roster[user] || { display: '' }).display;
}


function mapStateToProps(state, props) {
  return {
    isVideoMuted: getVideoMutedProperty(state, props.user),
    sink: state.settings.audioOutDevice,
    isTalking: detectTalking(state, props.user),
    pipEnabled: state.room.pipEnabled,
    display: getDisplayName(state, props.user),
  };
}

export default withStyles(style)(connect(mapStateToProps, null, null, { forwardRef: true })(
  React.forwardRef((props, ref) => (
    <VideoElement videoRef={ref} {...props} />
  ))
));
