import React from 'react';
import { connect, useDispatch } from 'react-redux';
import FullScreen from '../Fullscreen';
import { toggleFullscreen } from '../../lib/actions/room';
import { State as RoomState } from '../../lib/reducers/room';
import { State as WebsocketState } from '../../lib/reducers/websocket';
import detectedBrowser from '../../detectBrowser';


function Fullscreen(props: ExtendedProps) {
  const { fullscreenUser, user } = props;
  const dispatch = useDispatch();

  const handleChange = (newStatus: boolean, user: string): void => {
    if (user !== fullscreenUser) {
      return;
    }
    if (!newStatus) {
      dispatch(toggleFullscreen(null));
    }
  };

  const os = ((detectedBrowser || { os: '' }).os || '').toLowerCase();
  if (os === 'ios') {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>);
  }
  return (
    <FullScreen
      fullscreen_id=''
      user={user}
      fullscreen_user={fullscreenUser}
      enabled={Boolean(fullscreenUser)}
      onChange={handleChange}>
      {props.children}
    </FullScreen>
  );
}

type OwnProps = {
  user: string;
  children?: React.ReactNode;
}

type MappedProps = {
  fullscreenUser: string | null;
}

type State = {
  room: RoomState;
  websocket: WebsocketState;
}

type ExtendedProps = MappedProps & OwnProps;

const mapStateToProps = (state: State): MappedProps => {
  return {
    fullscreenUser: state.room.fullScreenEnabled,
  };
};

export default connect(mapStateToProps)(Fullscreen);
