import { State } from '../reducers';
import { VideoRoom } from '../api/videoroom';
import { isVideoEnabledForUser } from '../reduxSelectors/videoRoom';
import PiP from '../../pip';
import detectedBrowser from '../../detectBrowser';
import { isAudioOnlyLayout, isThereAnyVideo } from './room';


export function isKickEnabled(userId: string, state: State) {
  const user = state.room.roster[userId] || {};
  const myUserId = state.websocket.uid || '';
  const myUser = state.room.roster[myUserId] || {};
  if (user.role === 'room_owner' || myUserId === userId) {
    return false;
  }
  if (myUser.role === 'room_owner' || myUser.role === 'room_moderator') {
    return true;
  }
  return false;
}

export function isPipEnabled(userId: string, state: State) {
  const myUserId = state.websocket.uid || '';
  const fullscreenUser = state.room.fullScreenEnabled;
  const pipUser = state.room.pipEnabled;
  const hasVideo = isVideoEnabledForUser(userId, state);
  const myUser = state.websocket.uid || '';
  const isOwnVideoMuted = !VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream)
    || (state.room.roster[myUserId] || { isVideoMuted: false }).isVideoMuted;

  const isAudioOnly = isAudioOnlyLayout(state) || !isThereAnyVideo(state);

  if (!PiP.isAvailable()) {
    return false;
  }

  if (!hasVideo) {
    return false;
  }

  if (userId === myUserId) {
    return false;
  }

  if (fullscreenUser && fullscreenUser === userId) {
    // fullscreen enabled
    return false;
  }

  if (pipUser && pipUser !== userId) {
    // pip enabled on other video
    return false;
  }

  if (isOwnVideoMuted && userId === myUser) {
    return false;
  }

  if (isAudioOnly) {
    return false;
  }

  return true;
}

export function canChangeRole(userId: string, state: State) {
  const user = state.room.roster[userId] || {};
  const myUserId = state.websocket.uid || '';
  const myUser = state.room.roster[myUserId] || {};

  if (user.viaPhone || myUserId === userId) {
    return false;
  }

  if (user.role === 'room_owner') {
    return false;
  }

  if (myUser.role === 'room_owner' || myUser.role === 'room_moderator') {
    return true;
  }
  return false;
}

export function canFullscreen(userId: string, state: State) {
  const myUserId = state.websocket.uid || '';
  const fullscreenUser = state.room.fullScreenEnabled;
  const hasVideo = isVideoEnabledForUser(userId, state);
  const myUser = state.websocket.uid || '';
  const pipUser = state.room.pipEnabled;
  const isOwnVideoMuted = !VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream)
    || (state.room.roster[myUserId] || { isVideoMuted: false }).isVideoMuted;

  const isAudioOnly = isAudioOnlyLayout(state) || !isThereAnyVideo(state);

  const os = ((detectedBrowser || { os: '' }).os || '').toLowerCase();
  if (os === 'ios') {
    return false;
  }

  if (!hasVideo) {
    return false;
  }

  if ((userId === myUser) || (userId === `${myUser}_screen`)) {
    // dont let fullscreen for yourself
    return false;
  }

  if (pipUser && pipUser === userId) {
    return false;
  }

  if (fullscreenUser && fullscreenUser !== userId) {
    return false;
  }

  if (isOwnVideoMuted && userId === myUser) {
    return false;
  }

  if (isAudioOnly) {
    return false;
  }

  return true;
}
