import { renewMyPins } from '../api/relay/renewMyPins';
import {
  updateLivestreaming as updateLivestreamingApi,
  updateDefaultRoomLock as updateDefaultRoomLockApi
} from '../api/relay/updateProfile';
import { newEvent, ERROR, INFO } from '../notifications';
import { sessionDetailsQueryRequest } from '../api/relay/sessionDetails';
import { fetchSessionDetails } from './session';
import { getLogger } from '../logger';
import { setUpdating } from './session';

function maybeNotifyRegenPinsError() {
  newEvent(ERROR, 'regeneratePinsGenError', 'regeneratePinsGenError',
    "Error while updating pins.");
}


function notifyUpdateProfileError() {
  newEvent(ERROR, 'updateProfileError', 'updateProfileError',
    "Error while updating profile settings.");
}


function notifyUpdateProfileSuccess() {
  newEvent(INFO, 'updateProfileSuccess', 'updateProfileSuccess',
    "Profile saved successfully!");
}


export function regeneratePins(relay) {
  return (dispatch) => {
    renewMyPins(relay).then((_res) => {
      dispatch(fetchSessionDetails(relay, sessionDetailsQueryRequest));
    }).catch((_err) => {
      maybeNotifyRegenPinsError();
    });
  };
}


export function updateLivestreaming(relay, params) {
  const logger = getLogger('updateLivestreaming');

  return (_dispatch) => {
    updateLivestreamingApi(relay, params).then((_res) => {
      notifyUpdateProfileSuccess();
    }).catch((err) => {
      logger.error("GQL Api Error", err);

      notifyUpdateProfileError();
    });
  };
}

export function updateDefaultRoomLock(relay, params) {
  const logger = getLogger('updateDefaultRoomLock');

  return (dispatch) => {
    dispatch(setUpdating(true));
    updateDefaultRoomLockApi(relay, params).then((_res) => {
      dispatch(fetchSessionDetails(relay, sessionDetailsQueryRequest));
      dispatch(setUpdating(false));
    }).catch((err) => {
      logger.error("GQL Api Error", err);
      dispatch(setUpdating(false));
      notifyUpdateProfileError();
    });
  };
}
