import React from 'react';
import * as moment from 'moment';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './styles';

export type DayNamesPropsType = WithStyles<typeof styles>;
class DayNames extends React.Component<DayNamesPropsType, {}> {

  render() {
    const classes = this.props.classes;
    return (
      <div className={classNames(this.props.classes.row, this.props.classes.daysHeader)}
      >
        <span className={classes.box}>{moment.weekdays(1).charAt(0)}</span>
        <span className={classes.box}>{moment.weekdays(2).charAt(0)}</span>
        <span className={classes.box}>{moment.weekdays(3).charAt(0)}</span>
        <span className={classes.box}>{moment.weekdays(4).charAt(0)}</span>
        <span className={classes.box}>{moment.weekdays(5).charAt(0)}</span>
        <span className={classes.box}>{moment.weekdays(6).charAt(0)}</span>
        <span className={classes.box}>{moment.weekdays(7).charAt(0)}</span>
      </div>
    );
  }
}

export default withStyles(styles)(DayNames);
