import detectedBrowser from './detectBrowser';


function isNotificationSupported(os) {
  if (os === 'ios' || os.startsWith('android') || (typeof Notification === 'undefined')) {
    return false;
  } else {
    return true;
  }
}

function isNotificationEnabled() {
  if (Notification.permission !== "granted") {
    return false;
  } else {
    return true;
  }
}

function checkPermissionNotification() {
  const os = (detectedBrowser.os || '').toLowerCase();
  if (isNotificationSupported(os) && !isNotificationEnabled()) {
    Notification.requestPermission();
  }
}

function sendNotification(title, message) {
  const body = {
    icon: "/favicon.ico",
    body: message
  };
  try {
    const notification = new Notification(title, body);
    notification.onclick = () => {
      window.parent.focus();
      window.focus(); //just in case, older browsers
    };
  }
  catch (_e) {
    return;
  }
}

export { isNotificationSupported, isNotificationEnabled, checkPermissionNotification, sendNotification };
