import { Meeting } from './websocket';
import {
  SET_CAN_JOIN_ROOM,
  SET_WAITING_REASON,
  SET_LOCKED_JOIN_REQUEST_DENIED,
  STORE_SNAPSHOT,
  SET_JOINING_WAITING_ROOM,
  Action,
} from '../actions/waitingRoom';

export interface State {
  isJoining: boolean;
  canJoinRoom: boolean;
  waitingReason: string | null;
  meetingDetails: Meeting | null;
  lockedJoinRequestDenied: boolean;
  video: HTMLVideoElement | null;
  snapshot: string | null;
}

const defaultState: State = {
  isJoining: false,
  canJoinRoom: false,
  waitingReason: null,
  meetingDetails: null,
  lockedJoinRequestDenied: false,
  video: null,
  snapshot: null,
};

function waitingRoom(state = defaultState, action: Action) {
  switch (action.type) {
    case SET_CAN_JOIN_ROOM:
      return Object.assign({}, state, {
        canJoinRoom: action.payload.canJoinRoom,
      });

    case SET_WAITING_REASON:
      return Object.assign({}, state, {
        waitingReason: action.payload.waitingReason,
        meetingDetails: action.payload.meetingDetails,
      });

    case SET_LOCKED_JOIN_REQUEST_DENIED:
      return Object.assign({}, state, {
        lockedJoinRequestDenied: action.payload.denied,
      });

    case STORE_SNAPSHOT:
      return Object.assign({}, state, {
        snapshot: action.payload.snapshot,
      });

    case SET_JOINING_WAITING_ROOM:
      return Object.assign({}, state, {
        isJoining: action.payload.isJoining,
      });

    default:
      return state;
  }
}

export default waitingRoom;
export { defaultState };
