import { commitMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';
import { newEvent, ERROR } from '../../notifications';

import {
  deleteMeetingAsMutation,
  deleteMeetingAsMutationResponse
} from './__generated__/deleteMeetingAsMutation.graphql';

const mutation = graphql`
mutation deleteMeetingAsMutation(
  $input: DeleteMeetingAsInput!
) {
  deleteMeetingAs(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
    }
  }
}
`;

function maybeNotifyError(response: deleteMeetingAsMutationResponse | null) {
  if (!response || !response.deleteMeetingAs || response.deleteMeetingAs.errors !== null) {
    newEvent(ERROR, 'deleteMeetingError', 'deleteMeetingError',
      "Delete meeting error");
  }
}

function deleteMeetingAs(environment: IEnvironment, id: string, userId: string, domainId: string) {
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          userId,
          domainId,
          id
        }
      },
    };
    commitMutation<deleteMeetingAsMutation>(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { deleteMeetingAs };
