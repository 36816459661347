import { Channel } from 'phoenix';

import { traceError as sentryTraceError } from '../sentry';
import { LoggerInterface } from '../logger';
import { Meeting } from '../reducers/websocket';
import { JoinError, EventSub, EventCallback, Transport } from './videoroom';

type WaitingRoomJoinSuccess = {
  channel: Channel;
  reason: string;
  meetingDetails: Meeting | null;
};

type WaitingRoomServerMsg =
  MeetingStartedMsg
  | MeetingEndedMsg
  | RoomUnlockedMsg
  | LockedJoinRequestAcceptedMsg
  | LockedJoinRequestDeniedMsg;

type MeetingStartedMsg = {
  msg: string;
};

type MeetingEndedMsg = {};

type RoomUnlockedMsg = {
  msg: string;
};

type LockedJoinRequestAcceptedMsg = {};
type LockedJoinRequestDeniedMsg = {};

type WaitingRoomMsg = {};

class WaitingRoomApi {
  private transport: Transport;
  private channel: null | Channel;
  private logger: LoggerInterface;
  private eventSubscriber: EventSub;

  constructor(transport: Transport, logger: LoggerInterface) {
    this.transport = transport;
    this.channel = null;
    this.logger = logger;
    this.eventSubscriber = new EventSub();
  }

  subscribe(ev: string, f: EventCallback) {
    return this.eventSubscriber.subscribe(ev, f);
  }

  unsubscribe(ev: string, id: string) {
    return this.eventSubscriber.unsubscribe(ev, id);
  }

  _onEvent(ev: string, payload: WaitingRoomServerMsg) {
    this.eventSubscriber.dispatch(ev, payload);
    return payload;
  }

  joinWaitingRoom(room: string, snapshot: string, _opts: {}): Promise<WaitingRoomJoinSuccess | JoinError> {
    this.logger.info("joining video waiting room: " + room);
    this.channel = null;
    const payload = { snapshot: snapshot };
    const channel = this.transport.channel(room, payload);
    if (!channel) {
      // this should not be possible, but make the compiler happy
      const res: JoinError = { code: 5004, type: 'error', reason: 'disconnected' };
      return Promise.reject(res);
    }

    const p: Promise<WaitingRoomJoinSuccess | JoinError> = new Promise((resolve, reject) => {
      channel.onMessage = (ev, payload, _ref) => this._onEvent(ev, payload);
      channel.join()
        .receive("ok", (payload) => {
          payload.channel = channel;
          this.channel = channel;
          resolve(payload);
        })
        .receive("error", ({ reason, payload, code }) => {
          channel.leave();
          reject({ type: "error", reason: reason, payload: payload, errorCode: code });
        })
        .receive("timeout", () => {
          channel.leave();
          reject({ code: 5004, type: "timeout", reason: "timeout" });
        });
    });
    return p;
  }

  _sendMessage(method: string, payload: WaitingRoomMsg) {
    if (!this.channel) {
      return Promise.reject({ code: 5004, type: 'error', reason: 'disconnected' });
    }
    return Promise.resolve(this.channel.push(method, payload));
  }

  _pushMessage(method: string, payload: WaitingRoomMsg) {
    const p = new Promise((resolve, reject) => {
      if (!this.channel) {
        reject({ code: 5004, type: 'error', reason: 'disconnected' });
        return;
      }
      this.channel.push(method, payload)
        .receive("ok", (res) => {
          resolve(res);
        })
        .receive("error", (err) => {
          sentryTraceError('ws push message error', { error: err });
          reject(err);
        })
        .receive("timeout", () => {
          sentryTraceError('ws push timeout');
          reject({ type: "timeout", code: 5004, reason: null });
        });
    });
    return p;
  }
}

export default WaitingRoomApi;
