const style = {
  textOverlayContainer: {
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'rgba(255,255,255, 0.9)',
    height: '100%',
    width: '100%',
    opacity: 0,
    top: 0,
    left: 0,
    display: 'table',
    justifyContent: 'center',
    position: 'absolute',
    padding: 0,
    zIndex: 30,
    transition: 'opacity .5s',
    '&:hover': {
      opacity: 1,
    }
  },
  textOverlay: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
};

export default style;
