import React from 'react';
import { State } from '../../../lib/reducers';
import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { IconModerator, IconModeratorOff } from '../../IconSet';
import { WithAuthorization } from '../../../authorization';

import { changeRole } from '../../../lib/actions/room';
import { canChangeRole } from '../../../lib/reduxSelectors/user';
import { defineMessages } from 'react-intl';


const messages = defineMessages({
  demoteMessage: { id: 'demoteRoleIconButtonTooltip' },
  promoteMessage: { id: 'promoteRoleIconButtonTooltip' }
});



function ChangeRoleItem(props: ExtendedProps) {
  const { isRequestingRoleChange, changeRoleEnabled, userRole, user, onClose, iconSize = 20 } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const presentatorRoles = ['room_owner', 'room_moderator'];


  const onChangeRole = React.useCallback(
    () => {
      if (user) {
        const who = user.replace(/_screen$/, '');
        const newRole = (userRole === 'room_user') ? 'room_moderator' : 'room_user';
        dispatch(changeRole(who, newRole));
      }
      onClose();
    }
    , [dispatch, user, onClose, userRole]
  );

  const getIcon = (): React.ReactElement => {
    if (presentatorRoles.includes(props.userRole)) {
      return <IconModeratorOff size={iconSize} />;
    }
    else {
      return <IconModerator size={iconSize} />;
    }

  };

  if (!changeRoleEnabled) {
    return null;
  }

  return (
    <MenuItem disabled={isRequestingRoleChange} onClick={onChangeRole} >
      <ListItemIcon>
        {getIcon()}
      </ListItemIcon>
      <ListItemText primary={formatMessage(presentatorRoles.includes(userRole)
        ? messages.demoteMessage
        : messages.promoteMessage)} />
    </MenuItem>

  );

}

type Props = {
  user: string;
  iconSize?: number;
  onClose: () => void;
}

type MappedProps = {
  changeRoleEnabled: boolean;
  userRole: string;
  isRequestingRoleChange: boolean;
}

type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, props: Props): MappedProps => {
  const userId = (props.user || "").replace(/_screen$/, '');
  const user = state.room.roster[userId] || {};
  return {
    changeRoleEnabled: canChangeRole(userId, state),
    userRole: user.role,
    isRequestingRoleChange: user.isRequestingRoleChange,
  };

};

const roles = ['room_owner', 'room_moderator'];

export default WithAuthorization(roles, connect(mapStateToProps)(ChangeRoleItem));
