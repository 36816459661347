import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { State } from '../../../lib/reducers';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { IconMiniplayer, IconExpand } from '../../IconSet';
import { togglePip } from '../../../lib/actions/room';
import { defineMessages } from 'react-intl';
import { isPipEnabled } from '../../../lib/reduxSelectors/user';


const messages = defineMessages({
  enablePip: { id: 'enablePipButton' },
  disablePip: { id: 'disablePipButton' }
});


function PipMenuItem(props: ExtendedProps) {
  const { user, pipUser,  pipEnabled, onClose, iconSize = 20 } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onPipAction = React.useCallback(
    () => {
      if (pipUser) {
        dispatch(togglePip(null));
      }
      else {
        dispatch(togglePip(user));
      }
      onClose();
    }
    , [dispatch, user, onClose, pipUser]
  );

  const getIcon = (): React.ReactElement => {
    if (pipUser) {
      return <IconExpand size={iconSize} />;
    }
    else {
      return <IconMiniplayer size={iconSize} />;
    }

  };

  if (!pipEnabled) {
    return null;
  }

  return (
    <MenuItem onClick={onPipAction} >
      <ListItemIcon>
        {getIcon()}
      </ListItemIcon>
      <ListItemText primary={formatMessage(!pipUser
        ? messages.enablePip
        : messages.disablePip)} />
    </MenuItem>

  );
}

type Props = {
  user: string;
  iconSize?: number;
  onClose: () => void;
}

type MappedProps = {
  pipUser: string | null;
  pipEnabled: boolean;
}

type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    pipEnabled: isPipEnabled(ownProps.user, state),
    pipUser: state.room.pipEnabled,
  };
};


export default connect(mapStateToProps)(PipMenuItem);
