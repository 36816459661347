import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { IconMore } from '../IconSet';
import { State as RoomState } from '../../lib/reducers/room';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { WithAuthorization } from '../../authorization';
import StyledMenu from '../StyledMenu';
import RecordButton from './RecordButton';
import StreamingButton from './StreamingButton';
import EndMeetingButton from './EndMeetingButton';
import { isMobileOnly } from 'react-device-detect';


type MappedProps = {
  recordingEnabled: boolean;
  livestreamingEnabled: boolean;
}

type State = {
  room: RoomState;
}

const messages = defineMessages({
  roomActionsMenuTooltip: { id: 'roomActionsMenuTooltip' },
});

const mapStateToProps = (state: State): MappedProps => ({
  recordingEnabled: state.room.recordingEnabled,
  livestreamingEnabled: state.room.livestreamingEnabled,
});

type Props = {
} & MappedProps

function ActionRoomMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { formatMessage } = useIntl();

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const closeMenu = React.useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const recEnabled = props.recordingEnabled;
  const streamingEnabled = props.livestreamingEnabled;
  const msg = formatMessage(messages.roomActionsMenuTooltip);

  const getActions = () => {
    return (
      <React.Fragment>
        {recEnabled && <RecordButton closeMenu={closeMenu} />}
        {streamingEnabled && <StreamingButton closeMenu={closeMenu} />}
        <EndMeetingButton closeMenu={closeMenu} />
      </React.Fragment>
    );
  };

  const getMenu = () => {
    return (
      <React.Fragment>
        <Tooltip placement="top" title={msg}>
          <IconButton onClick={handleMenu} size='small'>
            <IconMore size={24} />
          </IconButton>
        </Tooltip>
        <StyledMenu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={closeMenu}
        >
          {getActions()}
        </StyledMenu>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      { isMobileOnly ? getActions() : getMenu() }
    </React.Fragment>
  );

}

const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, connect(mapStateToProps)(ActionRoomMenu));
