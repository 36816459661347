import React from 'react';
import { IconButton, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

function ResponsiveButton(props: Props) {
  const { children, cName, disabled, mini, onClick, size, text } = props;

  const getMiniLayout = () => {
    return (
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <React.Fragment>
            {children}
          </React.Fragment>
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    );
  };

  const getRegularLayout = () => {
    return (
      <IconButton className={cName} disabled={disabled} onClick={onClick} size={size}>
        {children}
      </IconButton>
    );
  };

  return (
    <React.Fragment>
      { mini ? ( disabled ? null : getMiniLayout() ) : getRegularLayout() }
    </React.Fragment>
  );
}

type Props = {
  cName?: string;
  disabled?: boolean;
  mini?: boolean;
  onClick: () => void;
  size: 'small' | 'medium';
  text: string;
} & React.PropsWithChildren<{}>

export default ResponsiveButton;
