import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    input: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  })
);

export default useStyles;
