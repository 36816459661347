import React from 'react';

import { connect, useDispatch } from 'react-redux';

import sizeMe, { SizeMeProps } from 'react-sizeme';

import VideoElement from '../VideoElement';
import VideoToolbar from './VideoToolbar';
import Fullscreen from './Fullscreen';
import usePublishersWatcher from './usePublishersWatcher';
import usePublishersCount from './usePublishersCount';

import { getLogger } from '../../lib/logger';
import { subscribeToVideo, toggleAllVideoMute, changeVideoQuality } from '../../lib/actions/room';
import { VideoQualityOptions } from '../../lib/redux_types';
import { State } from '../../lib/reducers';
import { VideoRoom } from '../../lib/api/videoroom';
import { getStreams } from './StandardDimensionLayout';
import gridElements from '../../lib/grid';


function renderParticipants(
  remoteVideoStreams: ExtendedProps['remoteVideoStreams'],
  // FIXME
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  participantsGrid: any
) {
  const keys = Object.keys(remoteVideoStreams);
  const participants = keys.flatMap((uid, idx) => {
    const video = remoteVideoStreams[uid as keyof ExtendedProps['remoteVideoStreams']];
    if (video['stream']) {
      return [
        <div key={idx} style={participantsGrid[idx]}>
          <Fullscreen user={uid}>
            <VideoElement
              user={uid}
              mirrored={false}
              addVideoMutedIconOverlay={true}
              src={video['stream']}
            />
            <VideoToolbar uid={uid} />
          </Fullscreen>
        </div>
      ];
    }
    else {
      return [];
    }
  });
  return participants;
}


function getGrid(props: ExtendedProps) {
  const { size } = props;
  if (size.width && size.width > 0 && size.height && size.height > 0) {
    let nrOfStreams = Object.keys(props.remoteVideoStreams).length;
    const isNotRecorder = true;  // FIXME

    if (props.hasVideoStream && isNotRecorder) {
      nrOfStreams = nrOfStreams + 1;
    }
    if (props.localScreenStream) {
      nrOfStreams = nrOfStreams + 1;
    }

    let participantsGrid = gridElements(size.width, size.height, nrOfStreams);
    let streamGrid = {};
    let screenGrid = {};

    if (props.hasVideoStream && isNotRecorder) {
      [streamGrid, participantsGrid] = [participantsGrid[0] || streamGrid, participantsGrid.slice(1)];
    }

    if (props.localScreenStream) {
      [screenGrid, participantsGrid] = [participantsGrid[0] || screenGrid, participantsGrid.slice(1)];
    }

    return [streamGrid, screenGrid, participantsGrid];
  }
  else {
    return [{}, {}, []];
  }
}


export function GridView(props: ExtendedProps) {
  const {
    remoteVideoStreams,
    hasVideoStream,
    myUserId,
    localScreenStream,
    localVideoStream
  } = props;

  const [streamGrid, screenGrid, participantsGrid] = getGrid(props);

  if (!myUserId) {
    return null;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {hasVideoStream &&
        <div style={streamGrid}>
          <VideoElement
            user={myUserId}
            mirrored={true}
            addVideoMutedIconOverlay={true}
            src={localVideoStream}
          />
          <VideoToolbar uid={myUserId} />
        </div>
      }
      {localScreenStream ?
        <div style={screenGrid}>
          <VideoElement user={`${myUserId}_screen`} src={localScreenStream} />
          <VideoToolbar uid={`${myUserId}_screen`} />
        </div>
        : null
      }
      {renderParticipants(remoteVideoStreams, participantsGrid)}
    </div>
  );
}


function GridLayout(props: ExtendedProps) {
  const dispatch = useDispatch();

  const lowQualityStreamThresh = 5;

  React.useEffect(() => {
    dispatch(toggleAllVideoMute(false));
  }, [dispatch]);

  const newPublishers = usePublishersWatcher();

  newPublishers.forEach((u) => {
    if (u.stream) {
      dispatch(subscribeToVideo(u.uid));
    }
    if (u.screen) {
      dispatch(subscribeToVideo(`${u.uid}_screen`));
    }
  });

  const [ publCount ] = usePublishersCount();

  if (publCount > lowQualityStreamThresh) {
    const constraints: VideoQualityOptions = { streamQuality: '180p', frameRate: 5 };
    dispatch(changeVideoQuality(constraints, getLogger('gridLayout')));
  }
  else if (publCount <= lowQualityStreamThresh) {
    const constraints: VideoQualityOptions = { streamQuality: props.configuredVideoQuality };
    if (props.roomOptions.frame_rate) {
      constraints.frameRate = props.roomOptions.frame_rate;
    }
    dispatch(changeVideoQuality(constraints, getLogger('gridLayout')));
  }

  return <GridView {...props} />;
}


type MappedProps = {
  hasVideoStream: boolean;
  localVideoStream: State['room']['localvideo_stream'];
  localScreenStream: State['room']['screenStream'];
  remoteVideoStreams: ReturnType<typeof getStreams>;
  myUserId: State['websocket']['uid'];
  configuredVideoQuality: undefined | VideoQualityOptions['streamQuality'];
  roomOptions: State['appconfig']['room_options'];
}


type ExtendedProps = {} & MappedProps & SizeMeProps;


const mapStateToProps = (state: State): MappedProps => {
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }
  return {
    myUserId: state.websocket.uid,
    localVideoStream: state.room.localvideo_stream,
    localScreenStream: state.room.screenStream,
    remoteVideoStreams: getStreams(state),
    hasVideoStream,
    configuredVideoQuality: state.settings.videoQuality ? state.settings.videoQuality.value : undefined,
    roomOptions: state.appconfig.room_options,
  };
};


export default connect(mapStateToProps)(sizeMe({ monitorHeight: true })(GridLayout));
