import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { getOptions } from './lib/utils/themeDefaults';


export default function createHolocomTheme(remoteThemeOptions?: ThemeOptions): Theme {

  const themeOptions = getOptions(remoteThemeOptions);

  let theme = createMuiTheme(themeOptions);
  // now apply overrides. We do this in two separate steps to be able to
  // reference theme variables inside the override definitions
  // (e.g. "color: theme.palette.text.secondary")
  theme = createMuiTheme({
    ...themeOptions,
    overrides: {
      ...getInputOverrides(theme),
      ...getSelectOverrides(theme),
      ...getButtonOverrides(theme),
      ...getDialogOverrides(theme),
    }
  });
  theme = responsiveFontSizes(theme);
  return theme;
}


function getSelectOverrides(theme: Theme): Overrides{
  return {
    MuiMenu: {
      paper: {
        background: theme.palette.secondary.light,
      }
    }
  };
}


function getInputOverrides(theme: Theme): Overrides{
  const filledBackground = 'rgba(0, 0, 0, 0.09)';
  const light = theme.palette.type === 'light';
  const placeholderVisible = {
    opacity: light ? 0.42 : 0.5,
  };
  return {
    MuiInputLabel: {
      root: {
        fontSize: '0.75rem',
        marginLeft: '0.75em;',
        '&$focused': {
          color: theme.palette.text.secondary
        },
        '&$error': {
          color: theme.palette.text.secondary
        }
      },
      animated: {
        transition: 'none'
      },
      shrink: {
        transform: 'none'
      },
      formControl: {
        transform: 'none'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '0px'
        },
        '&:after': {
          borderBottom: '0px'
        },
        '&$error:after': {
          borderBottom: '0px'
        },
        '&:hover:not($disabled):before': {
          borderBottom: '0px'
        }
      },
      inputMultiline: {
        marginTop: '0.5em',
        marginBottom: '0.5em',
      }
    },
    MuiInputBase: {
      input: {
        marginLeft: 'calc(0.75em - 4px);',  // same as InputLabel, but remove border
        marginRight: 'calc(0.75em - 4px);', // ditto

        // copied directly from InputBase component
        'label[data-shrink=false] + $formControl &': {
          '&::-webkit-input-placeholder': placeholderVisible,
          '&::-moz-placeholder': placeholderVisible, // Firefox 19+
          '&:-ms-input-placeholder': placeholderVisible, // IE 11
          '&::-ms-input-placeholder': placeholderVisible, // Edge
          '&:focus::-webkit-input-placeholder': placeholderVisible,
          '&:focus::-moz-placeholder': placeholderVisible, // Firefox 19+
          '&:focus:-ms-input-placeholder': placeholderVisible, // IE 11
          '&:focus::-ms-input-placeholder': placeholderVisible, // Edge
        },
      },
      root: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 4,
        padding: '1px',
        '&$focused': {
          padding: '0px',
          borderRadius: 4,
          border: 'solid',
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
          backgroundColor: '#ffffff',
        },
        '&$error': {
          padding: '0px',
          borderRadius: 4,
          border: 'solid',
          borderWidth: '1px',
          borderColor: theme.palette.error.main,
        },
        '&$disabled': {
          backgroundColor: filledBackground,
        }
      },
      multiline: {
        padding: '-2px 0 -1px',
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: '0.75em',
      }
    }
  };
}

function getButtonOverrides(theme: Theme) {
  return {
    MuiDialogActions: {
      root: {
        padding: theme.spacing(3)
      }
    },
    MuiButton: {
      root: {
        [theme.breakpoints.up('md')]: {
          minWidth: '120px',
        },
      }
    }
  };
}

function getDialogOverrides(theme: Theme) {
  return {
    MuiDialog: {
      paperWidthSm: {
        [theme.breakpoints.up('sm')]: {
          minWidth: '400px'
        },
      }
    }
  };
}
