import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { IconButton, ListItem, ListItemText, Tabs } from '@material-ui/core';

import { State } from '../../lib/reducers';
import { PublicChatTabLabel, PublicChatTab } from './PublicChatTab';
import { PrivateChatTabLabel, PrivateChatTab } from './PrivateChatTab';
import { IconUserAdd } from '../IconSet';
import SelectDestUserDialog from './SelectDestUserDialog';
import { canSendPrivateMessage, getPrivateMessagesKeys } from './utils';
import { LastMessage } from '../../lib/redux_types';
import { usePrevious } from '../../lib/utils/hooks';


const messages = defineMessages({
  chat: { id: 'chat' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
    },
    tabsHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
    }
  })
);


function ChatPanel(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { canSendPrivateMessage } = props;

  const [value, setValue] = React.useState(0);

  const [userSelectionOpen, setUserSelectionOpen] = React.useState<boolean>(false);

  const previousLastMessageTo = usePrevious(props.lastMessage.to, null);

  const showUserSelection = (): void => {
    setUserSelectionOpen(true);
  };

  const closeUserSelection = (): void => {
    setUserSelectionOpen(false);
  };

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const privateTabsLabel = () => {
    const tabs = props.privateMessages.map((key, idx) => {
      const tabIndex = idx + 1;

      /**
       * checks if there is a new private message and the user is not the receiver and that chat is not open
       * if the constraints are met that chat is set open
       */
      if (props.lastMessage.to === key && value !== tabIndex && previousLastMessageTo !== props.lastMessage.to) {
        setValue(tabIndex);
      }
      return (
        [
          <PrivateChatTabLabel index={tabIndex} currVal={value} user={key} key={key} />
        ]
      );
    });

    return tabs;
  };


  const privateTabs = () => {
    const tabs = props.privateMessages.map((key, idx) => {
      return (
        [
          <PrivateChatTab index={idx + 1} currVal={value} user={key} key={idx} />
        ]
      );
    });

    return tabs;
  };

  const getChatHeader = () => {
    return (
      <ListItem>
        <ListItemText primary={formatMessage(messages.chat)} />
      </ListItem>
    );
  };

  const getAddButton = () => {
    return (
      <React.Fragment>
        { canSendPrivateMessage &&
          <IconButton onClick={showUserSelection}>
            <IconUserAdd size={22} />
          </IconButton>
        }
      </React.Fragment>
    );
  };

  const getAllTabs = () => {
    return (
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <PublicChatTabLabel index={0} currVal={value} />
        { privateTabsLabel() }
      </Tabs>
    );
  };

  const getHeader = () => {
    return (
      <React.Fragment>
        <div className={classes.tabsHeader}>
          { getChatHeader() }
          { getAddButton() }
        </div>
        { getAllTabs() }
      </React.Fragment>
    );
  };

  const getMobileHeader = () => {
    return (
      <React.Fragment>
        <div className={classes.tabsHeader}>
          { getAllTabs() }
          { getAddButton() }
        </div>
      </React.Fragment>
    );
  };



  return (
    <div className={classes.panel}>
      { isMobileOnly ? getMobileHeader() : getHeader() }
      <PublicChatTab index={0} currVal={value} />
      { privateTabs() }
      { userSelectionOpen && <SelectDestUserDialog onClose={closeUserSelection} /> }
    </div>
  );
}


type Props = {}

type MappedProps = {
  privateMessages: string[];
  canSendPrivateMessage: boolean;
  lastMessage: LastMessage;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State): MappedProps => ({
  privateMessages: getPrivateMessagesKeys(state.room.privateMessages),
  canSendPrivateMessage: canSendPrivateMessage(state.room.roster),
  lastMessage: state.room.lastMessage,
});


export default connect(mapStateToProps)(ChatPanel);
