/* Libraries Imports */
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';
/* Components Imports */
import ClipboardIcon from '../../style/ClipboardIcon';
import IconButton from '@material-ui/core/IconButton';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other Imports */
import { State as RoomState } from '../../lib/reducers/room';
import { linkify } from '../../lib/utils';
import { ExtendedMeeting } from '.';
/* Local Style */
import useStyles from './style';


const messages = defineMessages({
  roomPublishStreamUrl: { id: 'roomPublishStreamUrl' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


type MappedProps = {
  isStreaming: boolean;
}

type State = {
  room: RoomState;
}

type Props = {
  roomDetails: ExtendedMeeting;
} & MappedProps

const mapStateToProps = (state: State): MappedProps => {
  return {
    isStreaming: state.room.isStreaming || false,
  };
};


function PublishStreamUrl(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [linkCopied, setLinkCopied] = React.useState<boolean>(false);

  let tooltipTimeoutHandler: NodeJS.Timeout | null = null;

  React.useEffect(() => {
    return () => {
      if (tooltipTimeoutHandler) {
        clearTimeout(tooltipTimeoutHandler);
      }
    };
  });

  const onLinkCopied = (): void => {
    setLinkCopied(true);
  };

  const handleClipboardTooltipClose = () => {
    // ugly, but avoid re-rendering the normal tooltip text just before closing it
    tooltipTimeoutHandler = setTimeout(() => {
      setLinkCopied(false);
      tooltipTimeoutHandler = null;
    },
    500);
  };

  const getPublishStreamUrl = (room: ExtendedMeeting, isStreaming: boolean) => {
    const rawUrl = room.publish_stream_url;
    let cboardTtipText = formatMessage(messages.copyToClipboard);
    if (linkCopied) {
      cboardTtipText = formatMessage(messages.copiedToClipboard);
    }
    if (rawUrl && isStreaming) {
      const url = linkify(rawUrl, '_blank', 25);
      return (
        <div>
          <div className={classes.title}>
            {formatMessage(messages.roomPublishStreamUrl)}
          </div>
          <div className={classes.webAccessContainer}>
            <div className={classNames(classes.content, classes.webAccessContainer)}>
              {url}
            </div>
            <div className={classes.iconContainer}>
              <IconButton>
                <TooltipMultiLineText
                  placement="top"
                  title={cboardTtipText}
                  onClose={handleClipboardTooltipClose}
                >
                  <CopyToClipboard
                    onCopy={onLinkCopied}
                    text={rawUrl}>
                    <ClipboardIcon />
                  </CopyToClipboard>
                </TooltipMultiLineText>
              </IconButton>
            </div>
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  };

  return (
    <div>
      {getPublishStreamUrl(props.roomDetails, props.isStreaming)}
    </div>
  );

}


export default connect(mapStateToProps)(PublishStreamUrl);
  