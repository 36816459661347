const style = () => ({
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto'
  },
  overlay: {
    top: 0,
    left: 0,
    zIndex: 1301,
    position: 'absolute',
    background: '#000000',
    height: '100%',
    width: '100%',
    opacity: 0.5
  }
});

export default style;
