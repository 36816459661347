import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { IconAcknowledgeState } from '../../IconSet';

import { lowerRaisedHand } from '../../../lib/actions/room';

import { mapStateToProps, MappedProps } from './mapState';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { WithAuthorization } from '../../../authorization';


const messages = defineMessages({
  clearState: { id: 'clearStateIconButtonTooltip' },
});


function RaisedHandButton(props: ExtendedProps) {
  const {
    mySelf,
    amModerator,
    iconSize = 20,
    isScreen,
    uid,
    onClose,
    hasRaisedHand
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const lowerHand = React.useCallback(
    () => {
      if (mySelf) {
        return;
      }
      else {
        dispatch(lowerRaisedHand(uid));
      }
      onClose();
    }
    , [dispatch, uid, mySelf, onClose]
  );

  const disabled = (!amModerator && !mySelf) || isScreen;

  if (disabled || !hasRaisedHand) {
    return null;
  }

  return (
    <MenuItem onClick={lowerHand} >
      <ListItemIcon>
        <IconAcknowledgeState size={iconSize} />
      </ListItemIcon>
      <ListItemText primary={formatMessage(messages.clearState)} />
    </MenuItem>
  );
}


type Props = {
  uid: string;
  iconSize?: number;
  onClose: () => void;
}


type ExtendedProps = Props & MappedProps


const roles = ['room_owner', 'room_moderator'];

export default WithAuthorization(roles, connect(mapStateToProps)(RaisedHandButton));
