import React  from 'react';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { linkify } from '../../lib/utils';
import CopyToClipboard from '../CopyToClipboard';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { State } from '../../lib/reducers';
import { getPin } from '../../lib/reduxSelectors/roomInfo';
import { isMyPersonalRoom } from '../../lib/reduxSelectors/room';
import { inMeeting } from '../../lib/reduxSelectors/videoRoom';
import CopyLinkButton from '../InviteToMeetingDialog/CopyLinkButton';


const messages = defineMessages({
  roomPstnAccess: { id: 'roomInfoPstnAccess' },
  roomPstnNotAvailable: { id: 'roomInfoPstnNotAvailable' },
  roomPstnNumber: { id: 'roomInfoPstnNumber' },
  roomInfoAccessTitle: { id: 'roomInfoAccessTitle' },
  roomInfoPersonalAccessTitle: { id: 'roomInfoPersonalAccessTitle' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5),
    },
    userInfoEntryTitle: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1em',
    },
    userInfoEntryContent: {
      color: 'rgba(0, 0, 0, 0.90)',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },
    linkContent: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    userInfoLinkContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  })
);


function RoomInfo(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { roomTitle, roomUrl, pin, pstnNumber, isMyPersonalRoom, minified } = props;

  const pinAndPstnAvailable = pin && pstnNumber;

  const getNoNumbers = () => {
    return (
      <Typography className={classes.userInfoEntryContent}>
        {formatMessage(messages.roomPstnNotAvailable)}
      </Typography>
    );
  };

  const getNumbers = () => {
    return (
      <Typography className={classes.userInfoEntryContent} >
        {formatMessage(messages.roomPstnNumber, { number: pstnNumber, pin: pin })}
      </Typography>
    );
  };

  const getTextToCopy = (): string => {
    const title = formatMessage(isMyPersonalRoom ? messages.roomInfoPersonalAccessTitle : messages.roomInfoAccessTitle);
    let roomInfo = `${title}: ${roomUrl}\n\n`;
    if (pinAndPstnAvailable) {
      const numbers = formatMessage(messages.roomPstnNumber, { number: pstnNumber, pin: pin });
      roomInfo += `${formatMessage(messages.roomPstnAccess)}: ${numbers}\n\n`;
    }

    return roomInfo;
  };

  const getExploded = () => {
    const roomUrlLink = linkify(roomUrl + roomUrl, '_blank', 45);
    return (
      <div className={classes.root}>
        <div className={classes.userInfoLinkContainer}>
          <Typography className={classes.userInfoEntryTitle}>
            {formatMessage(roomTitle)}
          </Typography>
          <Typography className={classNames(classes.userInfoEntryContent, classes.linkContent)}>
            {roomUrlLink}
          </Typography>
          <Typography className={classes.userInfoEntryTitle}>
            {formatMessage(messages.roomPstnAccess)}
          </Typography>
          { pinAndPstnAvailable ? getNumbers() : getNoNumbers() }
        </div>
        <CopyToClipboard rawUrl={getTextToCopy()}/>
      </div>
    );
  };

  const getMinified = () => {
    return (
      <CopyLinkButton rawUrl={getTextToCopy()} details />
    );
  };

  return minified ? getMinified() : getExploded();
}


type Props = {
  roomUrl: string;
  roomTitle: { id: string };
  minified?: boolean;
}

type MappedProps = {
  pstnNumber: State['session']['pstnNumber'];
  pin: string | null;
  isMyPersonalRoom: boolean;
}

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State) => ({
  pstnNumber: state.session.pstnNumber,
  pin: getPin(state),
  isMyPersonalRoom: (!inMeeting(state) || isMyPersonalRoom(state))
});


export default connect(mapStateToProps)(RoomInfo);

