import { Meeting } from '../reducers/websocket';
import { ThunkAction } from 'redux-thunk';
import { State } from '../redux_types';

export const SET_CAN_JOIN_ROOM = 'SET_CAN_JOIN_ROOM';
export const SET_WAITING_REASON = 'SET_WAITING_REASON';
export const SET_LOCKED_JOIN_REQUEST_DENIED = 'SET_LOCKED_JOIN_REQUEST_DENIED';
export const STORE_SNAPSHOT = 'STORE_SNAPSHOT';
export const SET_JOINING_WAITING_ROOM = 'SET_JOINING_WAITING_ROOM';

interface SetCanJoinRoomAction {
  type: typeof SET_CAN_JOIN_ROOM;
  payload: {
    canJoinRoom: boolean;
  };
}

interface SetWaitingReasonAction {
  type: typeof SET_WAITING_REASON;
  payload: {
    waitingReason: string | null;
    meetingDetails: Meeting | null;
  };
}

interface SetLockedJoinRequestDeniedAction {
  type: typeof SET_LOCKED_JOIN_REQUEST_DENIED;
  payload: {
    denied: boolean;
  };
}

interface StoreSnapshotAction {
  type: typeof STORE_SNAPSHOT;
  payload: {
    snapshot: string | null;
  };
}

interface SetJoiningWaitingRoomAction {
  type: typeof SET_JOINING_WAITING_ROOM;
  payload: {
    isJoining: boolean;
  };
}

export type Action =
  SetCanJoinRoomAction
  | SetWaitingReasonAction
  | SetLockedJoinRequestDeniedAction
  | StoreSnapshotAction
  | SetJoiningWaitingRoomAction;

export function setCanJoinRoom(canJoin: boolean): SetCanJoinRoomAction {
  return {
    type: SET_CAN_JOIN_ROOM,
    payload: {
      canJoinRoom: canJoin
    }
  };
}

export function setWaitingReason(reason: string | null, meetingDetails: Meeting | null): SetWaitingReasonAction {
  return {
    type: SET_WAITING_REASON,
    payload: {
      waitingReason: reason,
      meetingDetails: meetingDetails
    }
  };
}

export function setLockedJoinRequestDenied(denied: boolean): SetLockedJoinRequestDeniedAction {
  return {
    type: SET_LOCKED_JOIN_REQUEST_DENIED,
    payload: {
      denied: denied
    }
  };
}

export function storeSnapshot(snapshot: string | null): StoreSnapshotAction {
  return {
    type: STORE_SNAPSHOT,
    payload: {
      snapshot: snapshot
    }
  };
}

export function setJoiningWaitingRoom(isJoining: boolean): SetJoiningWaitingRoomAction {
  return {
    type: SET_JOINING_WAITING_ROOM,
    payload: {
      isJoining: isJoining
    }
  };
}

type WaitingRoomThunkAction = ThunkAction<void, State, null, Action>

export function tearDownWaitingRoom(): WaitingRoomThunkAction {
  return (dispatch, _getState) => {
    dispatch(setLockedJoinRequestDenied(false));
    dispatch(setWaitingReason(null, null));
  };
}
