import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { DialogContent, DialogActions, Button, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ClosableDialog from '../ClosableDialog';
import Input from './ChatInput';
import { getRoster, hideChatUsersAndMessages } from '../../lib/reduxSelectors/roster';
import { State } from '../../lib/reducers';
import lodash from 'lodash';
import { hasModeratorRole } from '../../lib/reduxSelectors/room';


const messages = defineMessages({
  dialogTitle: { id: 'newPrivateChat' },
  selectUser: { id: 'selectUser' },
  noOtherUsers: { id: 'noOtherUsers' },
  close: { id: 'close' },
  userInvalid: { id: 'userInvalid' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    user: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  })
);


function ItemUser(props: { name: string; username: string }) {
  const classes = useStyles();
  return (
    <div className={classes.user}>
      <Typography variant={"body2"}>{props.name}</Typography>
      <Typography color="textSecondary" variant={"caption"}>{props.username}</Typography>
    </div>
  );
}


type ValueType = string | string[] | null;


function SelectDestUserDialog(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const { myself, roster, canChatWithNonModerators } = props;

  const [ user, setUser ] = React.useState<ValueType>(null);
  const [ userId, setUserId ] = React.useState<string | undefined>(undefined);

  const getUsername = (username: string): string => {
    const guest = 'guest';
    return username.includes(guest) ? guest : username;
  };

  const others = Object.entries(roster)
    .filter(([k, v]) => k !== myself && !v.viaPhone)
    .filter(([_k, v]) => canChatWithNonModerators || hasModeratorRole(v.role))
    .map(([_k, v]) => [v.display, v.username, getUsername(v.username)]);

  const onChange = React.useCallback(
    (_event: ChangeEvent<{}>, value: ValueType) => {
      setUser(value);
      const findUserId = (value: ValueType) => {
        const user = others.find((u) => u === value);
        if (user) {
          setUserId(user[1]);
        }
      };
      if (value) {
        findUserId(value);
      }
    }, [others]
  );

  const disabled = user === null || !others.some(e => lodash.isEqual(e, user));
  const error = disabled && Boolean(user);

  const getOptionLabel = React.useCallback((option) => option[0], []);

  const getOptionSelected = React.useCallback((option, value) => option[0] === value[0], []);

  const renderOption = React.useCallback(
    (option) => {
      return (
        <ItemUser name={option[0]} username={option[2]} />
      );
    }, []
  );

  const renderInput = React.useCallback(
    (params) => {
      return (
        <TextField
          {...params}
          placeholder={formatMessage(messages.selectUser)}
          fullWidth
          inputProps={{ ...params.inputProps, autoComplete: 'off' }}
          error={error}
          label={error ? formatMessage(messages.userInvalid) : null}
        />
      );
    }, [formatMessage, error]
  );

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={props.onClose}
      fullWidth={false}
      title={formatMessage(messages.dialogTitle)}
    >
      <DialogContent>
        <Autocomplete
          id='user-selection'
          style={{ padding: '1ex' }}
          options={others}
          autoComplete
          autoHighlight
          size='small'
          onChange={onChange}
          noOptionsText={formatMessage(messages.noOtherUsers)}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          renderOption={renderOption}
          renderInput={renderInput}
        />
        <Input to={userId} disableFocus disabled={disabled} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="contained" color="primary">
          {formatMessage(messages.close)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


type Props = {
  onClose: () => void;
}

type MappedProps = {
  myself: string | null;
  roster: ReturnType<typeof getRoster>;
  canChatWithNonModerators: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State): MappedProps => ({
  myself: state.websocket.uid,
  roster: getRoster(state),
  canChatWithNonModerators: !hideChatUsersAndMessages(state)
});


export default connect(mapStateToProps)(SelectDestUserDialog);
