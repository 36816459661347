import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import Button from '@material-ui/core/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import TooltipMultiLineText from '../TooltipMultiLineText';
import { IconDuplicate } from '../IconSet';

const messages = defineMessages({
  copyToClipboard: { id: 'copyToClipboard' },
  copyLink: { id: 'copyLink' },
  copyDetails: { id: 'copyDetails' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});

type Props = {
  rawUrl: string;
  details?: boolean;
}

type ExtendedProps = Props & { intl: IntlShape };

function CopyLinkButton(props: ExtendedProps) {
  const { intl: { formatMessage }, rawUrl, details } = props;
  const [linkCopied, setLinkCopied] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_timeoutHandler, setTimeoutHandler] = React.useState<null | ReturnType<typeof setTimeout>>(null);

  const handleClipboardTooltipClose = () => {
    const tmHandler = setTimeout(() => {
      setLinkCopied(false);
      setTimeoutHandler(null);
    },
    500);
    setTimeoutHandler(tmHandler);
  };


  const onLinkCopied = (): void => {
    setLinkCopied(true);
  };

  let cboardTtipText = formatMessage(messages.copyToClipboard);
  if (linkCopied) {
    cboardTtipText = formatMessage(messages.copiedToClipboard);
  }

  const text = formatMessage(details ? messages.copyDetails : messages.copyLink);

  return (
    <TooltipMultiLineText
      placement="top"
      title={cboardTtipText}
      onClose={handleClipboardTooltipClose}
    >

      <CopyToClipboard
        onCopy={onLinkCopied}
        text={rawUrl}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={
            <IconDuplicate size={24} />
          }
        >
          {text}
        </Button>
      </CopyToClipboard>
    </TooltipMultiLineText>
  );
}
export default (injectIntl(CopyLinkButton));
