import { State } from '../reducers';

import getRouteFor, { Route } from '../utils/router';


export function isRecorder(state: State, url?: string) {
  // assume this is a recorder if we have a url matching a recorded room
  if (url) {
    const meetingBasePath = getRouteFor(Route.RecordedMeeting, { slug: '' });
    if (url.startsWith(meetingBasePath)) {
      return true;
    }
  }

  // otherwise check user or role
  return (state.session.isRecorder || state.session.roles.includes('recorder'));
}
