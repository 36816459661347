import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import getRouteFor, { Route } from '../../../lib/utils/router';

import { isMobileOnly } from 'react-device-detect';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { IconHome, IconHomeSelected } from '../../IconSet';


const messages = defineMessages({
  gotoHome: { id: 'gotoHomepage' },
});


function Home(props: RouteComponentProps) {
  const { formatMessage } = useIntl();

  const homePath = getRouteFor(Route.Home);

  const gotoHome = React.useCallback(
    () => {
      if (props.location.pathname !== homePath) {
        props.history.push(homePath);
      }
    },
    [props.history, props.location, homePath]
  );

  const pathName = props.location.pathname;
  const getIcon = () => {
    return (
      <React.Fragment>
        {pathName === Route.Home
          ? <IconHomeSelected size={32} />
          : <IconHome size={32} />}
      </React.Fragment>
    );
  };

  const getMobileLayout = () => {
    return (
      <MenuItem onClick={gotoHome}>
        <ListItemIcon>
          {getIcon()}
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.gotoHome)} />
      </MenuItem>
    );
  };

  const getLayout = () => {
    return (
      <Tooltip title={formatMessage(messages.gotoHome)}>
        <IconButton onClick={gotoHome}>
          {getIcon()}
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <React.Fragment>
      { isMobileOnly ? getMobileLayout() : getLayout()}
    </React.Fragment>
  );
}


export default withRouter(Home);
