import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
/* Local Style */
import style from './style';

class VideoTextOverlay extends Component {

  showText(text, classes) {
    if (!text) {
      return null;
    }
    return (
      <div className={classes.textOverlayContainer}>
        <div className={classes.textOverlay}>
          {text}
        </div>
      </div>
    );
  }

  render() {
    const classes = this.props.classes;
    return (
      this.showText(this.props.text, classes)
    );
  }
}


VideoTextOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string
};

export default withStyles(style)(VideoTextOverlay);
