import React  from 'react';
import { connect } from 'react-redux';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { IconInfo, IconInfoSelected } from '../IconSet';
import { ChatIcon } from '../Chat';
import LockRoom from '../LockRoom';
import { PaneType, SelectedPane } from '.';
import { amModerator } from '../../lib/reduxSelectors/room';
import { disableRoster } from '../../lib/reduxSelectors/roster';
import { State } from '../../lib/reducers';
import RosterIcon from '../Roster/RosterIcon';
import LockedJoinRequestsButton from '../LockedJoinRequestsButton';


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    tabModerator: {
      minWidth: '25%',
      maxWidth: '25%'
    },
    tabModeratorPlus: {
      minWidth: '20%',
      maxWidth: '20%'
    },
    tab: {
      minWidth: '33%',
      maxWidth: '33%'
    },
  })
);


type Props = {
  selectedPane: SelectedPane;
  openPane: (type: PaneType, open: boolean, pane: SelectedPane) => void;
}

type ExtendedProps = Props & MappedProps

const rosterIdx = 0;
const chatIdx = 1;
const infoIdx = 2;
// const layoutIdx = 3;
const lockIdx = 4;
const joinReqIdx = 5;


function MeetingMobileBar(props: ExtendedProps) {
  const classes = useStyles();
  const {
    selectedPane,
    openPane,
    amModerator,
    disableRoster,
    lockedJoinRequests
  } = props;

  const [value, setValue] = React.useState(rosterIdx);
  const [forceChat, setForceChat] = React.useState(false);

  const onRosterClick = React.useCallback(
    () => {
      if (selectedPane !== PaneType.Roster) {
        openPane(PaneType.Roster, true, selectedPane);
      }
    }, [selectedPane, openPane]
  );

  const onChatClick = React.useCallback(
    () => {
      if (selectedPane !== PaneType.Chat) {
        openPane(PaneType.Chat, true, selectedPane);
      }
    }, [selectedPane, openPane]
  );

  const onInfoClick = React.useCallback(
    () => {
      if (selectedPane !== PaneType.Info) {
        openPane(PaneType.Info, true, selectedPane);
      }
    }, [selectedPane, openPane]
  );

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    if ([rosterIdx, chatIdx, infoIdx].includes(newValue)) {
      setValue(newValue);
    }
  };

  if (disableRoster && selectedPane === PaneType.Roster && value === rosterIdx) {
    onChatClick();
    setValue(chatIdx);
    setForceChat(true);
  } else if (!disableRoster && forceChat) {
    onRosterClick();
    setValue(rosterIdx);
    setForceChat(false);
  }

  const getRosterIcon = () => {
    return (
      <RosterIcon
        rosterPanelOpen={selectedPane === PaneType.Roster}
      />
    );
  };

  const getChatIcon = () => {
    return (
      <ChatIcon chatPanelOpen={selectedPane === PaneType.Chat} />
    );
  };

  const getInfoIcon = () => {
    return (
      <React.Fragment>
        {selectedPane === PaneType.Info
          ? <IconInfoSelected size={28} />
          : <IconInfo size={28} />
        }
      </React.Fragment>
    );
  };

  const showJoinRequests = amModerator && lockedJoinRequests.length > 0;

  const cls = amModerator
    ? (showJoinRequests ? classes.tabModeratorPlus : classes.tabModerator)
    : classes.tab;

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab
        value={rosterIdx}
        className={cls}
        disabled={disableRoster}
        icon={getRosterIcon()}
        onClick={onRosterClick} />
      <Tab
        value={chatIdx}
        className={cls}
        icon={getChatIcon()}
        onClick={onChatClick}/>
      <Tab
        value={infoIdx}
        className={cls}
        icon={getInfoIcon()}
        onClick={onInfoClick} />
      {amModerator &&
        <Tab
          value={lockIdx}
          className={cls}
          icon={<LockRoom />} />
      }
      {showJoinRequests &&
        <Tab
          value={joinReqIdx}
          className={cls}
          icon={<LockedJoinRequestsButton />} />
      }
    </Tabs>
  );
}


type MappedProps = {
  amModerator: boolean;
  disableRoster: boolean;
  lockedJoinRequests: State['notifications']['lockedJoinRequests'];
}

const mapStateToProps = (state: State): MappedProps => {
  return {
    amModerator: amModerator(state),
    disableRoster: disableRoster(state),
    lockedJoinRequests: state.notifications.lockedJoinRequests,
  };
};


export default connect(mapStateToProps)(MeetingMobileBar);
