enum NotificationLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}


const getNotificationLevel = (level: string): string => { 
  switch (level) {
    case NotificationLevel.INFO:
      return NotificationLevel.INFO.toLowerCase();
    case NotificationLevel.WARNING:
      return NotificationLevel.WARNING.toLowerCase();
    case NotificationLevel.ERROR:
      return NotificationLevel.ERROR.toLowerCase();
    default:
      return NotificationLevel.INFO.toLowerCase();
  }
};


export { getNotificationLevel, NotificationLevel };
